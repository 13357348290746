import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'

type SBLOCData = {
  starting_amount: number
  asset_rate_of_return: number
  years_to_grow: number
  borrowed_amount: number
  annualized_interest_rate: number
  re_invested_amount: number
  re_invest_rate_of_return: number
  cash_flow_that_year: number
  cash_flow_frequency: number
}

// SBLOC context props
type SBLOCContextProps = {
  SBLOCObj: SBLOCData
  setSBLOCObj: Dispatch<SetStateAction<SBLOCData>>
  parentUserId: number
  setParentUserId: Dispatch<SetStateAction<number>>
  personaData: any
  setPersonaData: Dispatch<SetStateAction<any>>
}

// default values
const initSBLOCContextPropsState = {
  SBLOCObj: {
    annualized_interest_rate: 8,
    asset_rate_of_return: 9,
    borrowed_amount: 2000000,
    re_invest_rate_of_return: 10,
    re_invested_amount: 1000000,
    starting_amount: 4000000,
    years_to_grow: 15,
    cash_flow_that_year: 0,
    cash_flow_frequency: 0,
  },
  setSBLOCObj: () => {},
  parentUserId: 0,
  setParentUserId: () => {},
  personaData: {},
  setPersonaData: () => {},
}

const SBLOCContext = createContext<SBLOCContextProps>(initSBLOCContextPropsState)

const SBLOCProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const [SBLOCObj, setSBLOCObj] = useState(initSBLOCContextPropsState.SBLOCObj)
  const [parentUserId, setParentUserId] = useState(0)
  const [personaData, setPersonaData] = useState<any>([])
  // This useMemo ensures that the context value only changes when any of the context properties change
  const contextValue = useMemo(
    () => ({
      SBLOCObj,
      setSBLOCObj,
      parentUserId,
      setParentUserId,
      personaData,
      setPersonaData,
    }),
    [SBLOCObj, parentUserId, personaData]
  )
  return <SBLOCContext.Provider value={contextValue}>{children}</SBLOCContext.Provider>
})

const useSBLOCContext = () => {
  const context = useContext(SBLOCContext)
  if (!context) {
    throw new Error('useSBLOCContext must be used within a SBLOCProvider')
  }
  return context
}

export {SBLOCProvider, useSBLOCContext}
