import {FormEvent, Fragment, useRef, useState} from 'react'
import {useLocation, useParams, useSearchParams} from 'react-router-dom'
import {motion, useTransform, useScroll} from 'framer-motion'
import {HERO_SECTION, ABOUT_PAGE, STAYTUNED_SECTION} from '../../../constants/AppConstants'
import useScreenWidth from '../hooks/useScreenWidth'
import {useReceiverFormContext} from '../../context/ReceiverContext'
import {NavbarComponent} from '../../../../_metronic/layout/components/header/user/NavbarComponent'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import InterestListButton from '../../pages/about/components/InterestListButton'
type HeroSectionProps = {
  handleClickStayTuned: () => void
  handleClickProjections: () => void
  refProp?: React.RefObject<HTMLDivElement>
}
const HeroSection: React.FC<HeroSectionProps> = ({
  handleClickStayTuned,
  handleClickProjections,
  refProp,
}) => {
  const screenWidth = useScreenWidth()
  const {ABOUT_HERO_SECTION} = ABOUT_PAGE
  const currentHostname = window.location.hostname
  const pathname = window.location.pathname
 const aboutRegex = /^\/about(?:\/|$)/

 if (aboutRegex.test(pathname)) {
   window.history.pushState({}, '', '/about')
 }

  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })
  const {advisor_url} = useParams()
  const {receiverFormDataObj} = useReceiverFormContext()
  const backgroundImageY = useTransform(scrollYProgress, [0.45, 1], ['0%', '-50%'])
  const bottomCloudX = useTransform(scrollYProgress, [0.5, 0.8], ['0%', '100%'])
  const hillsY = useTransform(scrollYProgress, [0.5, 0.8], ['0%', '-40%'])
  const mountainsY = useTransform(scrollYProgress, [0.5, 0.7], ['0%', '-100%'])
  const midCloudX = useTransform(scrollYProgress, [0.5, 0.7], ['0%', '-100%'])

  const location = useLocation()

  // Check if "arq" is in the URL
  const pathsToCheck = ['arq', 'advisor-demo1', 'advisor-demo2', 'waystone']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))
  const isWaystoneInURL = location.pathname.includes('waystone')

  return (
    <>
      <motion.section
        className='content-container hero-background-container'
        ref={targetRef}
        id='hero-section'
      >
        <motion.img
          src={HERO_SECTION.IMAGES.HERO_IMAGE}
          alt='mountains'
          className='d-none d-md-flex mt-6'
          style={{y: backgroundImageY}}
        />
        <motion.div className='d-flex d-md-none position-relative hero-mob-image-container'>
          <motion.img src={HERO_SECTION.IMAGES.TOP_CLOUD} className='hero-mob-top-cloud' />
          <motion.img
            src={HERO_SECTION.IMAGES.BOTTOM_CLOUD}
            style={{x: bottomCloudX}}
            className='hero-mob-bottom-cloud'
          />

          <motion.img
            src={HERO_SECTION.IMAGES.MID_CLOUD}
            className='hero-mob-mid-cloud'
            style={{x: midCloudX}}
          />
          <motion.img src={HERO_SECTION.IMAGES.MOUNTAINS} className='hero-mob-mountains' />
          <motion.img
            src={HERO_SECTION.IMAGES.HILLS}
            style={{y: hillsY}}
            className='hero-mob-hills'
          />
        </motion.div>
        <motion.div
          className={`hero-container px-7 px-md-15 z-index-1 ${screenWidth > 786 ? '' : 'mt-25vh'}`}
        >
          <div className='d-flex justify-content-center mb10 mt-n10'>
            <img className='logo-image' src={HERO_SECTION.IMAGES.LOGO_IMAGE} alt='logo' />
          </div>
          <h1 className={`mb-md-6 mt-md-16 mt-6 mb-4 text-center w-80 m-auto`}>
            {isArqInURL && !isWaystoneInURL
              ? HERO_SECTION.ARQ_HEADER
              : isWaystoneInURL
              ? 'See how you can grow with Waystone'
              : pathname === '/about'
              ? ABOUT_HERO_SECTION.HEADING
              : HERO_SECTION.GROWTH}
          </h1>
          {/* {pathname === '/about' && screenWidth > 768 && (
            <Fragment>
              <p className='my-4 text-center'>{ABOUT_HERO_SECTION.SUB_HEADING}</p>
              <p
                className='text-center fw-bold pink-text cursor-pointer'
                onClick={handleClickStayTuned}
              >
                {ABOUT_HERO_SECTION.JOIN}
              </p>
            </Fragment>
          )} */}
          {!isArqInURL && pathname !== '/about' ? (
            <>
              {/* mobile view text */}
              <p className='d-block d-md-none my-4 text-center'>
                {currentHostname === 'vividli.ai'
                  ? HERO_SECTION.VIVIDLI_CALCULATOR
                  : HERO_SECTION.CALCULATOR_MOBILE}{' '}
              </p>
              {/* <p
                className='d-block d-md-none text-center fw-bold pink-text cursor-pointer'
                onClick={handleClickStayTuned}
              >
                {HERO_SECTION.JOIN_WAITLIST}
              </p> */}
            </>
          ) : (
            <>
              {!advisor_url && (
                <p className='mt-2 text-center'>
                  {isArqInURL && !isWaystoneInURL
                    ? HERO_SECTION.ARQ_SUB_HEADING
                    : isWaystoneInURL
                    ? 'Hey! See how Waystone compares. Hit Meet or Reply with any questions. - Pete, Waystone Advisor'
                    : pathname === '/about'
                    ? ABOUT_HERO_SECTION.SUB_HEADING
                    : ''}
                </p>
              )}{' '}
              <p className='mt-4 text-center mb-0'>{receiverFormDataObj?.greetings}</p>
            </>
          )}
          {pathname === '/about' && (
            <div>
              <div className='d-flex justify-content-center align-items-center'>
                <img
                  className='w-75'
                  src={toAbsoluteUrl('/media/home/hero-gif.gif')}
                  alt='Descriptive Text for GIF'
                />
              </div>
              <InterestListButton />
            </div>
          )}

          <div className='d-flex flex-column justify-content-center align-items-center mt-12'>
            <h2>{pathname === '/about' ? ABOUT_HERO_SECTION.LEARN_MORE : HERO_SECTION.EXPLORE}</h2>
            <img
              alt='explore-arrow'
              className='explore-arrow-img mt-2'
              src={HERO_SECTION.IMAGES.EXPLORE_ARROW}
              onClick={handleClickProjections}
            />
          </div>
        </motion.div>
      </motion.section>
    </>
  )
}

export default HeroSection
