import React from 'react'
import {TooltipProps} from 'recharts'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {toUSD} from '../../../helper/charts.helper'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'

const ProjectionChartTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  const {previousData, isProjectionsInputChanged} = useReceiverFormContext()
  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload
  const gain = data.futureValue - data.contributions - data.initialDeposit
  const absoluteGain = Math.abs(gain)

  //find object for the current bar
  let previousObject = previousData.find((item) => {
    return item.label === data.label
  })
  //finding values for the previous bars
  const previousGain =
    previousObject?.futureValue - previousObject?.contributions - previousObject?.initialDeposit
  const previousAbsoluteGain = Math.abs(previousGain)

  const renderGain = (
    label: string,
    value: number,
    proportionValue: number,
    originalValue: number,
    isLegacy: boolean,
    management_fee: number,
    colorName: string
  ) => {
    const formattedValue = toUSD(value)
    const formattedProportionValue = proportionValue ? proportionValue.toFixed(2) : (0.0).toFixed(2)
    const formattedOriginalValue = toUSD(originalValue ? originalValue : 0)

    return (
      <p className={`paragraph-x-small mb-0 ${colorName}`}>
        {label}: <span className='label-bold'>{formattedValue}</span>
        {!isLegacy && originalValue !== 0 && (
          <span className='label-bold'>
            {' ('}
            {formattedProportionValue}% of {formattedOriginalValue}
            {') '}
          </span>
        )}
        {isLegacy && originalValue !== 0 && (
          <span className='label-bold'>
            {' ('}
            {formattedProportionValue}% of {management_fee}% of {formattedOriginalValue}
            {') '}
          </span>
        )}
      </p>
    )
  }
  return (
    <div className='custom-tooltip'>
      <p className='paragraph-small label-main'>{data.label}</p>

      {data.isLoss && data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        <p className='paragraph-x-small mb-0 label-loss'>
          Loss: <span className='label-bold'>{toUSD(absoluteGain)}</span>
        </p>
      ) : data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        renderGain('Gain', absoluteGain, 0, 0, false, 0, 'label-gain')
      ) : (
        <>
          {data.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP && (
            <>
              {data.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT &&
                renderGain(
                  'Gain (Appreciation)',
                  data.ARQ_appreciation,
                  data.originalARQValues?.proportionValue,
                  data.originalARQValues?.appreciation,
                  data.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
                  data.originalARQValues.management_fee,
                  'label-gain'
                )}

              {renderGain(
                'Gain (Rental Income)',
                data.ARQ_rental_income,
                data.originalARQValues.proportionValue,
                data.originalARQValues.rental,
                data.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
                data.originalARQValues.management_fee,
                'label-gain'
              )}
            </>
          )}

          {data.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT &&
            renderGain(
              'Gain (Flip Profit)',
              data.ARQ_Flip_Gain,
              data.originalARQValues?.proportionValue,
              data.originalARQValues?.fixAndFlip,
              data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT,
              data.originalARQValues?.management_fee,
              'label-gain'
            )}
        </>
      )}

      {data.type === TYPES_OF_ROR.TYPE_SIMPLE && (
        <p className='paragraph-x-small mb-0 label-contributions'>
          Contributions: <span className='label-bold'>{toUSD(data.contributions)}</span>
        </p>
      )}

      <p className='paragraph-x-small mb-0 label-initial-deposit'>
        Starting amount: <span className='label-bold'>{toUSD(data.initialDeposit)}</span>
      </p>
      {/* FOR PREVIOUS VALUES */}

      {isProjectionsInputChanged && previousObject ? (
        <>
          <p className='paragraph-small gray-text mt-5'>Previous:</p>
          {previousObject?.isLoss && previousObject?.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
            <p className='paragraph-x-small mb-0 gray-text'>
              Loss: <span className='label-bold'>{toUSD(previousAbsoluteGain)}</span>
            </p>
          ) : previousObject?.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
            renderGain('Gain', previousAbsoluteGain, 0, 0, false, 0, 'gray-text')
          ) : (
            <>
              {previousObject?.type !== TYPES_OF_ROR.TYPE_ARQ_FLIP && (
                <>
                  {previousObject?.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT &&
                    renderGain(
                      'Gain (Appreciation)',
                      previousObject?.ARQ_appreciation,
                      previousObject?.originalARQValues.proportionValue,
                      previousObject?.originalARQValues.appreciation,
                      previousObject?.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
                      previousObject?.originalARQValues.management_fee,
                      'gray-text'
                    )}

                  {renderGain(
                    'Gain (Rental Income)',
                    previousObject?.ARQ_rental_income,
                    previousObject?.originalARQValues.proportionValue,
                    previousObject?.originalARQValues.rental,
                    previousObject?.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
                    previousObject?.originalARQValues.management_fee,
                    'gray-text'
                  )}
                </>
              )}

              {previousObject?.type !== TYPES_OF_ROR.TYPE_BEFORE_EXIT &&
                renderGain(
                  'Gain (Flip Profit)',
                  previousObject?.ARQ_Flip_Gain,
                  previousObject?.originalARQValues.proportionValue,
                  previousObject?.originalARQValues.fixAndFlip,
                  previousObject?.type === TYPES_OF_ROR.TYPE_AFTER_EXIT,
                  previousObject?.originalARQValues.management_fee,
                  'gray-text'
                )}
            </>
          )}

          {previousObject?.type === TYPES_OF_ROR.TYPE_SIMPLE && (
            <p className='paragraph-x-small mb-0 gray-text'>
              Contributions:{' '}
              <span className='label-bold'>{toUSD(previousObject?.contributions)}</span>
            </p>
          )}

          <p className='paragraph-x-small mb-0 gray-text'>
            Starting amount:{' '}
            <span className='label-bold'>{toUSD(previousObject?.initialDeposit)}</span>
          </p>
        </>
      ) : null}
    </div>
  )
}

export default ProjectionChartTooltip
