import {useState, useRef, memo} from 'react'
import {motion, useTransform, useScroll} from 'framer-motion'
import {useLocation, useParams, useNavigate} from 'react-router-dom'
import useScreenWidth from '../../hooks/useScreenWidth'
import {
  CUSTOM_BAR,
  INPUT_BOX_TOOLTIPS,
  TYPES_OF_ROR,
  sectionIds,
} from '../../../../constants/AppConstants'
import CustomOverlayTooltip from './components/CustomOverlayTooltip'
import ChartVariants from '../../ABTest/ChartVariants'
import {useProjectionsState} from '../../utils/commonUtils'
import {
  assets,
  contributionAndDividendsFrequency,
  defaultAsset,
} from './components/ProjectionsConfig'
import {InvestmentChartProps, InvestmentTableModel} from '../../helper/calculator/_model'
import CustomBarModal from '../../../../../_metronic/layout/components/modal/CustomBarModal'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import {useAdvisorFormContext} from '../../../context/AdvisorContext'
import {Button} from 'react-bootstrap'
import FeedbackSection from '../feedbackSection/FeedbackSection'

type ProjectionsSectionProps = {
  refProp: React.RefObject<HTMLDivElement>
  onTableDataChange: (newData: InvestmentTableModel[]) => void
  chartData: InvestmentChartProps[]
}

const ProjectionsSection: React.FC<ProjectionsSectionProps> = ({refProp, chartData}) => {
  // destructuring useReceiverFormContext hook
  const {setReceiverFormDataObj, receiverFormDataObj, isSticky, setIsSticky, projectionsSentence} =
    useReceiverFormContext()
  const {sentence, handleReplyButton, selectedRate} = useProjectionsState()
  // extracting useparams
  const {advisor_url} = useParams()
  const location = useLocation()

  // useNavigation hook
  const navigate = useNavigate()

  // Check if "arq" is in the URL
  const pathsToCheck = ['arq', 'advisor-demo1', 'advisor-demo2', 'waystone']
  const isArqInURL = pathsToCheck.some((path) => location.pathname.includes(path))

  // screen width custom hook
  const screenWidth = useScreenWidth()

  // parallax animation stuff
  const targetRef = useRef(null)
  const {scrollYProgress} = useScroll({
    target: targetRef,
    offset: ['start end', 'end start'],
  })

  const opacityOfFirstContainer = useTransform(scrollYProgress, [0, 0.2, 0.4], [0, 0, 1])
  const backgroundImageY = useTransform(scrollYProgress, [0, 0.5], ['-40%', '0%'])

  const {isFormUploading} = useAdvisorFormContext()

  // state hooks
  const [showModal, setShowModal] = useState(false)

  return (
    <>
      <motion.section ref={targetRef} className='projections-container'>
        <motion.div
          style={screenWidth < 767 ? {opacity: opacityOfFirstContainer, y: backgroundImageY} : {}}
          // ref={refProp}
        >
          {/* duplicating "It could be worth $XXX" paragraph */}
          <p className='d-block d-md-none text-center px-4 mb-0 py-4 py-lg-0 worth-mobile-text'>
            It could be worth <span className='pink-text fw-600'>{sentence?.futureValue}</span>
            <CustomOverlayTooltip
              content={INPUT_BOX_TOOLTIPS.END_OF_SENTENCE_FV}
              className='tooltip-lg-logo'
            />
          </p>
          <div className='mt-1 mt-md-4 row mt-1 mt-md-4 mb-8'>
            <ChartVariants
              data={chartData}
              handleCustomBar={() => setShowModal(true)}
              handleReply={handleReplyButton}
            />
          </div>
          {/* chart calculations details */}
          {selectedRate.type !== TYPES_OF_ROR.TYPE_SIMPLE ? (
            <p className='text-center px-4 mb-0 glassmorphism-container py-4 py-lg-0'>
              What might{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.startingAmount}</span> look
              like, in{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.numberOfYears}</span> years,
              if you put that for your future self, in a{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.rateOfReturn}</span>,
              starting today? It could be worth{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.futureValue}</span>.
              <CustomOverlayTooltip
                content={INPUT_BOX_TOOLTIPS.END_OF_SENTENCE_FV}
                className='tooltip-lg-logo'
              />
            </p>
          ) : (
            <p className='text-center px-4 mb-0 glassmorphism-container py-4 py-lg-0'>
              What might{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.startingAmount}</span> look
              like, in{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.numberOfYears}</span> years,
              if you put that and{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.contributions}</span>{' '}
              {projectionsSentence?.contributionFrequency}, for your future self, in a{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.rateOfReturn}</span>,
              starting today? It could be worth{' '}
              <span className='pink-text fw-600'>{projectionsSentence?.futureValue}</span>.
              <CustomOverlayTooltip
                content={INPUT_BOX_TOOLTIPS.END_OF_SENTENCE_FV}
                className='tooltip-lg-logo'
              />
            </p>
          )}
          {/* last bar is only for clickable for home pages */}
          {!advisor_url ? (
            <CustomBarModal
              show={showModal}
              onHide={() => setShowModal(false)}
              title={CUSTOM_BAR.TITLE}
            />
          ) : (
            ''
          )}

          {/* meet and reply buttons */}
          <div className='p-3 px-5 me-lg-10 d-flex justify-content-end align-items-center'>
            {advisor_url ? (
              <>
                <a href='https://calendly.com/aniqtanwir/30min' target='_blank' rel='noreferrer'>
                  <Button className='custom-secondary-button me-2'>MEET</Button>
                </a>
                <Button
                  className='custom-secondary-button mx-2'
                  onClick={() => navigate(`/contact/${advisor_url}`)}
                >
                  REPLY
                </Button>
              </>
            ) : null}

            {/* if url contains arq than show reply button */}
            {!advisor_url && isArqInURL ? (
              <>
                <a
                  href='https://calendly.com/aniqtanwir/30min'
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => handleReplyButton(true)}
                >
                  <Button className='custom-secondary-button me-2'>MEET</Button>
                </a>
                <Button
                  className='custom-secondary-button mx-2'
                  disabled={isFormUploading}
                  onClick={() => handleReplyButton(false)}
                >
                  {!isFormUploading && <span className='indicator-label'>REPLY</span>}
                  {isFormUploading && (
                    <span className='indicator-progress' style={{display: 'block'}}>
                      Please wait...
                      <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                    </span>
                  )}
                </Button>
              </>
            ) : null}
          </div>
        </motion.div>

        {/* feedback section */}
        <FeedbackSection sectionName='projections section' />
      </motion.section>
    </>
  )
}

export default memo(ProjectionsSection)
