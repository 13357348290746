import {toPng} from 'html-to-image'
import {toast} from 'react-toastify'

// download charts
export const downloadCharts = () => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const chartContainer = document.getElementById('charts-container')

  if (chartContainer) {
    toPng(chartContainer, {cacheBust: false})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'my-arq-performance-monthyear.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        notifyError('An error occurred.')
      })
  }
}
