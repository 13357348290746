import React from 'react'
import {Slider} from 'rsuite'
import {KTSVG} from '../../../_metronic/helpers'

interface CustomRangeSliderProps {
  value: number
  minValue?: number
  maxValue: number
  isMoney?: boolean
  onRangeChange: (value: number) => void
  isWaystoneSlider?: boolean
}
interface ThumbProps {
  isWayStoneSlider?: boolean // Assuming you intended to pass this as a prop
}

export const Thumb: React.FC<ThumbProps> = ({isWayStoneSlider = false}) => {
  return (
    <svg width='24' height='21' viewBox='0 0 24 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M21.24 18.1L12 2.89998L2.75999 18.1H21.24Z'
        fill={!isWayStoneSlider ? '#367045' : '#B86746'} // Replace 'red' and 'blue' with actual colors based on your conditions
        stroke={!isWayStoneSlider ? '#367045' : '#B86746'} // Replace 'green' and 'orange' with actual stroke colors
        strokeWidth='3.91667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <line x1='15' y1='17' x2='15' y2='8' stroke='white' strokeWidth='2' />
      <line x1='9' y1='17' x2='9' y2='8' stroke='white' strokeWidth='2' />
    </svg>
  )
}

const ProjectionSlider: React.FC<CustomRangeSliderProps> = ({
  value,
  minValue = 0,
  maxValue,
  isMoney = true,
  onRangeChange,
  isWaystoneSlider = false,
}) => {
  // Calculate the step dynamically based on the range
  const step = !isMoney ? (maxValue - minValue) / 20 : (maxValue - minValue) / 10 // You can adjust the divisor as needed

  const incrementValue = () => {
    if (value + step <= maxValue) {
      let newValue

      if (isMoney) {
        // For money values, round up to the nearest multiple of 10
        // newValue = Math.floor((value + step) / step) * step
        newValue = value + step
      } else {
        newValue = value
        newValue += 1
      }
      onRangeChange(newValue)
    }
  }

  const decrementValue = () => {
    if (value > maxValue) {
      return
    } else {
      if (value - step >= minValue) {
        let newValue

        if (isMoney) {
          // For money values, round down to the nearest multiple of 10
          newValue = value - step
        } else {
          // For non-money values, find the nearest multiple of 10
          newValue = value
          newValue -= 1
        }
        onRangeChange(newValue > minValue ? newValue : minValue)
      } else if (!isMoney && value - step < minValue) {
        onRangeChange(minValue)
      }
    }
  }

  const handleInputChange = (newValue: number) => {
    onRangeChange(newValue)
  }

  return (
    <div className='row justify-content-center mb-2'>
      <div className='row justify-content-between d-flex align-items-center'>
        <div className='col-1 fw-600 cursor-pointer p-0 m-0' onClick={decrementValue}>
          <KTSVG path='/media/icons/duotune/general/minus.svg' />
        </div>

        <div className='col-10 p-0 px-3 m-0'>
          <Slider
            min={minValue}
            max={maxValue}
            value={value}
            step={step}
            tooltip={false}
            className={`${!isWaystoneSlider ? 'custom-slider' : 'waystone-custom-slider'}`}
            onChange={handleInputChange}
            progress
            handleStyle={{
              borderRadius: 2,
              color: '#fff',
              fontSize: 12,
              width: 32,
              height: 22,
            }}
            handleTitle={<Thumb isWayStoneSlider={isWaystoneSlider} />}
            graduated // Display step indicators
          />
        </div>

        <div className='col-1 fw-600 cursor-pointer p-0 m-0' onClick={incrementValue}>
          <KTSVG path='/media/icons/duotune/general/plus.svg' />
        </div>
      </div>
    </div>
  )
}

export default ProjectionSlider
