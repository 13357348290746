import {calculateFutureValue} from './CommonCalculatorFunctions'
import {ComparePerformanceAssetsCompoundingGrowth} from './_model'

// calculate custom ROI
export const calculateCustomROI = (futureValue: number, initialDeposit: number) => {
  return ((futureValue - initialDeposit) / initialDeposit) * 100
}

// generate chart data
export const generateComparePerformanceChartData = (
  initialDeposit: number,
  customInvestmentFV: number,
  numberOfYears: number,
  compoundingGrowth: ComparePerformanceAssetsCompoundingGrowth[],
  contributions: number,
  contributionFrequency: number
) => {
  const chartData = compoundingGrowth.map((rate, _index) => {
    //   future value
    const futureValue = calculateFutureValue(
      initialDeposit,
      rate.value,
      contributionFrequency,
      numberOfYears,
      contributions
    )

    //   return values
    return {
      id: rate.id,
      value: rate.value,
      customROR: rate.value,
      label: rate.label,
      customLabel: rate.customLabel,
      labelColor: rate.color,
      futureValue:
        _index === compoundingGrowth.length - 1
          ? customInvestmentFV.toFixed(2)
          : parseFloat(futureValue.toFixed(2)),
      contributions: contributions * contributionFrequency * numberOfYears,
      initialDeposit: initialDeposit,
      isLoss:
        _index === compoundingGrowth.length - 1
          ? customInvestmentFV < initialDeposit
          : rate.value < 0,
      numberOfYears: numberOfYears,
      type: rate.type,
      startDate: rate.pastData.startDate,
      startValue: rate.pastData.startValue,
      oldDate: rate.pastData.endDate,
      oldValue: rate.pastData.endValue,
    }
  })

  return chartData
}
