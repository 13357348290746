import {useRef, useState} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {SBLOCObjModel, SBLOCDataModel} from '../helper/SBLOC/_model'
import {
  calculateFutureValue,
  calculatePriorBalanceAndBalanceEOY,
  calculateSBLOCFutureValue,
  calculateSBLOCLossValue,
  calculateFutureValueForSBLOCTopLabel,
  calculateSBLOCInterest,
  calculateSBLOCSummulativeBorrowedAmount,
  calculateSBLOCGainForCurrentPeriod,
  calculateSBLOCFutureValueforBar,
  calculateSBLOCLossValueForBar,
  calculateFutureValueForSBLOCTopLabelForEachBar,
  calculateSBLOCSummulativeBorrowedAmountForEachBar,
  calculateSBLOCInterestForBar,
  calculateSBLOCGainForCurrentPeriodForBar,
  calculateSBLOCLossValueForBarAfterDraw,
  calculateSBLOCCummultaiveReturns,
  calculateSBLOCCummultaiveReturnsForEachBar,
  calculateDepositForSBLOC,
  calculateDepositForSBLOCForChart,
  subtractArrays,
  calculateDepositsThisYear,
} from '../helper/calculator/CommonCalculatorFunctions'
import {contributionAndDividendsFrequency} from '../homeComponents/projections/components/ProjectionsConfig'
import TagManager from 'react-gtm-module'
import axios from 'axios'
import {useSBLOCContext} from '../../context/SBLOCContext'
import {fetchSharedSBlocDataArray} from '../advisor/core/_request'

export function useSBLOCProjections() {
  const {SBLOCObj, setSBLOCObj} = useSBLOCContext()
  // Define the validation schema using Yup
  const SBLOCSchema = Yup.object().shape({
    starting_amount: Yup.number()
      .min(0, 'Please enter an initial amount greater than or equal to $0')
      .max(10000000, 'Please enter an initial amount less than or equal to $10,000,000')
      .typeError('Please enter an initial amount greater than or equal to $0')
      .required('Please enter the starting amount'),

    asset_rate_of_return: Yup.number()
      .min(0, 'Please enter asset rate of return value greater than or equal to $0')
      .max(100, 'Please enter asset rate of return value less than or equal to 100%')
      .typeError('Please enter asset rate of return value amount greater than or equal to 0%')
      .required('Please enter the asset rate of return value'),

    numberOfYears: Yup.number()
      .min(1, 'Please enter a number of years greater than or equal to 1')
      .max(100, 'Please enter a number of years less than or equal to 100')
      .typeError('Please enter number of years greater than or equal to 1')
      .required('Please enter the number of years to grow'),

    borrowed_amount: Yup.number()
      .min(0, 'Please enter borrowed amount greater than or equal to $0')
      .max(
        Yup.ref('starting_amount'),
        'Borrowed amount must be less than or equal to the starting amount'
      )
      .typeError('Please enter borrowed amount greater than or equal to $0')
      .required('Please enter the borrowed amount'),

    annualized_interest_rate: Yup.number()
      .min(0, 'Please enter annualized interest rate greater than or equal to $0')
      .max(100, 'Please enter annualized interest rate less than or equal to 100%')
      .typeError('Please enter annualized interest rate greater than or equal to $0'),

    re_invested_amount: Yup.number()
      .min(0, 'Please enter re-invested amount greater than or equal to $0')
      .max(
        Yup.ref('borrowed_amount'),
        'Re-invested amount must be less than or equal to the borrowed amount'
      )
      .typeError('Please enter re-invested amount greater than or equal to $0'),

    re_invest_rate_of_return: Yup.number()
      .min(0, 'Please enter re-invest rate-of-return greater than or equal to 0%')
      .max(100, 'Please enter a re-invest rate-of-return greater than or equal to 100%')
      .typeError('Please enter re-invest rate-of-return greater than or equal to $0'),
    cash_flow_that_year: Yup.number()
      .min(0, 'Please enter cash flow amount greater than or equal to $0')
      .max(
        Yup.ref('re_invested_amount'),
        'Cash flow amount must be less than or equal to the re_invested_amount'
      )
      .typeError('Please enter cash flow amount greater than or equal to $0'),
    cash_flow_frequency: Yup.number(),
  })
  const isSblocPage = window.location.pathname.slice(1) === 'sblocPage'
  const [data, setData] = useState([])
  function parseCurrentUrlParameters(): SBLOCObjModel {
    const currentUrl = window.location.href // Get the current URL
    const urlParts = currentUrl.split('?') // Split the URL into base and query string parts
    const params: SBLOCObjModel = {
      annualized_interest_rate: SBLOCObj.annualized_interest_rate,
      asset_rate_of_return: SBLOCObj.asset_rate_of_return,
      borrowed_amount: SBLOCObj.borrowed_amount,
      re_invest_rate_of_return: SBLOCObj.re_invest_rate_of_return,
      re_invested_amount: SBLOCObj.re_invested_amount,
      starting_amount: SBLOCObj.starting_amount,
      years_to_grow: SBLOCObj.years_to_grow,
      cash_flow_frequency: SBLOCObj.cash_flow_frequency,
      cash_flow_that_year: SBLOCObj.cash_flow_that_year,
    } // Initialize with default values

    if (urlParts.length > 1) {
      const queryString = urlParts[1] // Extract the query string part
      const paramPairs = queryString.split('&') // Split the query string into parameter pairs
      paramPairs.forEach((pair) => {
        const [key, value] = pair.split('=') // Split each pair into key and value
        if (key && value && key in params) {
          params[key as keyof SBLOCObjModel] = parseFloat(decodeURIComponent(value))
          // formik.setFieldValue('key', value) // Parse value as a float and update params object
        }
      })
    }
    // if (urlParts.length > 1) {
    //   const slug = urlParts[1].split('=')[1]
    //   fetchSharedSBlocDataArray(slug).then((data) => {
    //     console.log(setData(data.data)) // Now data should be the actual value, not a Promise
    //   })
    // }

    return params
  }
  // Define the initial form values
  const initialValues = !isSblocPage
    ? {
        starting_amount: 100000,
        asset_rate_of_return: 10,
        years_to_grow: 11,
        borrowed_amount: 50000,
        annualized_interest_rate: 8,
        re_invested_amount: 50000,
        re_invest_rate_of_return: 5,
        cash_flow_that_year: 0,
        cash_flow_frequency: 1,
      }
    : parseCurrentUrlParameters()

  const isFirstRender = useRef(true)

  const sendAnalytics = (values: SBLOCObjModel) => {
    // Create the dataLayer object
    TagManager.dataLayer({
      dataLayer: {
        event: 'sbloc_form',
        gtm: {
          elementId: 'sbloc_form',
        },
        asset_rate_of_return: values.asset_rate_of_return,
        years_to_grow: values.years_to_grow,
        borrowed_amount: values.borrowed_amount,
        annualized_interest_rate: values.annualized_interest_rate,
        re_invested_amount: values.re_invested_amount,
        re_invest_rate_of_return: values.re_invest_rate_of_return,
        starting_amount: values.starting_amount,
      },
    })
  }
  const sendToSheets = async (dataObj: SBLOCObjModel) => {
    const encodedStartingAmount = encodeURIComponent(dataObj.starting_amount)
    const encodedAssetRateOfReturn = encodeURIComponent(dataObj.asset_rate_of_return)
    const encodedYearsToGrow = encodeURIComponent(dataObj.years_to_grow)
    const encodedBorrowedAmount = encodeURIComponent(dataObj.borrowed_amount)
    const encodedAnnualizedInterestRate = encodeURIComponent(dataObj.annualized_interest_rate)
    const encodedReInvestedAmount = encodeURIComponent(dataObj.re_invested_amount)
    const encodedReInvestRateOfReturn = encodeURIComponent(dataObj.re_invest_rate_of_return)
    const isSBLOCAnalytics = encodeURIComponent(true)

    const scriptUrl = `https://script.google.com/macros/s/AKfycbzmu5l10vTepJaWngkpNy-9RK5Gru9awUFC_wGT-jgiE-uDzZPpXLSr3VJAHsEhWPrD/exec?startingAmount=${encodedStartingAmount}&asset_rate_of_return=${encodedAssetRateOfReturn}&years_to_grow=${encodedYearsToGrow}&borrowed_amount=${encodedBorrowedAmount}&annualized_interest_rate=${encodedAnnualizedInterestRate}&re_invested_amount=${encodedReInvestedAmount}&re_invest_rate_of_return=${encodedReInvestRateOfReturn}&isSBLOCAnalytics=${isSBLOCAnalytics}`

    try {
      const response = await axios.get(scriptUrl, {
        withCredentials: true,
      })

      if (response.status === 200) {
        console.log('Response added in sheet.')
        return
      } else {
        console.error('Error sending message:', response.statusText)
      }
    } catch (error) {
      console.error('Error sending message:', error)
    }
  }

  //function to generate the data of the sbloc chart
  const contributions = 0
  const frequencyValue = 1 // contributuon frequency we want to serach on
  const contributionFrequencyObject = contributionAndDividendsFrequency.find(
    (item) => item.value === frequencyValue
  )
  const generateData = (dataObj: SBLOCObjModel) => {
    dataObj.starting_amount = dataObj.starting_amount ? dataObj.starting_amount : 0
    dataObj.borrowed_amount = dataObj.borrowed_amount ? dataObj.borrowed_amount : 0
    dataObj.re_invested_amount = dataObj.re_invested_amount ? dataObj.re_invested_amount : 0
    dataObj.re_invest_rate_of_return = dataObj.re_invest_rate_of_return
      ? dataObj.re_invest_rate_of_return
      : 0
    dataObj.years_to_grow = dataObj.years_to_grow ? dataObj.years_to_grow : 0
    setTimeout(() => {
      setSBLOCObj((prevState) => ({
        ...prevState,
        annualized_interest_rate: dataObj.annualized_interest_rate,
        asset_rate_of_return: dataObj.asset_rate_of_return,
        borrowed_amount: dataObj.borrowed_amount,
        re_invest_rate_of_return: dataObj.re_invest_rate_of_return || 0,
        re_invested_amount: dataObj.re_invested_amount,
        starting_amount: dataObj.starting_amount,
        years_to_grow: dataObj.years_to_grow,
        cash_flow_that_year: dataObj.cash_flow_that_year,
        cash_flow_frequency: dataObj.cash_flow_frequency,
      }))
    })

    let date = new Date()
    const barsDataArrayToShowOnChart: SBLOCDataModel[] = []
    // gains on starting amounts
    let startingAmountGainsArray = calculateSBLOCFutureValue(
      dataObj.starting_amount,
      dataObj.asset_rate_of_return,
      dataObj.years_to_grow
    )
    // prior balance and balance eoy
    let priorBalanceArray = calculatePriorBalanceAndBalanceEOY(
      dataObj.borrowed_amount,
      dataObj.annualized_interest_rate,
      dataObj.years_to_grow
    )
    //loss by borrowed amount
    let borrowedAmountLossArray = calculateSBLOCLossValue(
      dataObj.borrowed_amount,
      dataObj.annualized_interest_rate,
      dataObj.years_to_grow,
      dataObj.borrowed_amount
    )
    // gain on re-invested amount
    let reinvestedAmountGainsArray = calculateSBLOCFutureValue(
      dataObj.re_invested_amount,
      dataObj.re_invest_rate_of_return,
      dataObj.years_to_grow,
      // sending the true argument that it specifically calculate the values for reinvested formula
      true
    )
    // cummultaive interest on borrowed amount
    let cumulativeInterestBorrowedArray = calculateSBLOCSummulativeBorrowedAmount(
      dataObj.borrowed_amount,
      dataObj.annualized_interest_rate ? dataObj.annualized_interest_rate : 0,
      frequencyValue,
      dataObj.years_to_grow, //number of years
      contributions
    )
    // interest for each year
    let interestThisPeriodArray = calculateSBLOCInterest(cumulativeInterestBorrowedArray)
    // gain on starting amount for each year
    let assetGainsPerPeriodArray = calculateSBLOCGainForCurrentPeriod(
      dataObj.starting_amount,
      dataObj.asset_rate_of_return,
      frequencyValue,
      dataObj.years_to_grow,
      contributions
    )
    // gain on re-invested amount in each year
    let reinvestmentGainsPerPeriodArray = calculateSBLOCGainForCurrentPeriod(
      dataObj.re_invested_amount,
      dataObj.re_invest_rate_of_return,
      frequencyValue,
      dataObj.years_to_grow,
      contributions
    )

    let cummulativeReturnsArray = calculateSBLOCCummultaiveReturns(
      dataObj.starting_amount,
      dataObj.asset_rate_of_return,
      dataObj.years_to_grow
    )
    let cummultaiveReInvestmentArray = calculateSBLOCCummultaiveReturns(
      dataObj.re_invested_amount,
      dataObj.re_invest_rate_of_return,
      dataObj.years_to_grow
    )
    // top labels value to show in chart

    let depositArray = calculateDepositForSBLOCForChart(
      dataObj.starting_amount,
      dataObj.years_to_grow
    )
    let cummulativeReturnwithoutDeposit = subtractArrays(cummulativeReturnsArray, depositArray)
    let labelValues = calculateFutureValueForSBLOCTopLabel(
      depositArray,
      cummulativeReturnwithoutDeposit,
      cummultaiveReInvestmentArray,
      priorBalanceArray,
      dataObj.years_to_grow
    )
    for (let i = 0; i < dataObj.years_to_grow && i <= 100; i++) {
      //spread for this year

      let spread_this_year = assetGainsPerPeriodArray[i] - interestThisPeriodArray[i]

      //spread with re-invested amount this year
      let spread_with_reInvest_this_year =
        assetGainsPerPeriodArray[i] +
        reinvestmentGainsPerPeriodArray[i] -
        interestThisPeriodArray[i]

      //return for this year
      let return_this_year = (spread_with_reInvest_this_year / dataObj.starting_amount) * 100

      //year
      let year = date.getFullYear() + (i + 1)

      //total return
      // let return_total =
      //   ((startingAmountGainsArray[i] +
      //     reinvestedAmountGainsArray[i] -
      //     cumulativeInterestBorrowedArray[i]) /
      //     dataObj.starting_amount) *
      //   100
      let return_total = ((labelValues[i] - depositArray[i]) / depositArray[i]) * 100
      //approx average rate of return per year
      let approx_average_return_per_year = return_total / (i + 1)

      // console.log('depositArray: ', depositArray)
      const depositsThisYear = calculateDepositsThisYear(
        depositArray[i],
        i === 0 ? depositArray[0] : depositArray[i - 1],
        i
      )
      let reInvestedAsset =
        i === 0
          ? dataObj.re_invested_amount
          : 0 + barsDataArrayToShowOnChart[i - 1].reInvested_asset
      let reInvestGain = cummultaiveReInvestmentArray[i] - reInvestedAsset
      let reInvestGainThisYear =
        reInvestGain - (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].reInvestGain)
      let totalInterest = priorBalanceArray[i].BalanceEOY - dataObj.borrowed_amount
      let interestThisYear =
        totalInterest - (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].total_interest)

      barsDataArrayToShowOnChart.push({
        draw: i === 0 ? dataObj.borrowed_amount : 0,
        starting_amount: i === 0 ? dataObj.starting_amount : 0,
        borrowed_amount: dataObj.borrowed_amount,
        year: year,

        //values for the height of the bars of chart
        gainOnStartingAmount: startingAmountGainsArray[i],
        gainOnReInvestedAmount: reinvestedAmountGainsArray[i],
        lossByBorrowwedAmount: borrowedAmountLossArray[i],
        topLabelValue: labelValues[i],

        //rollovers
        //this year
        spread_this_year: spread_this_year,
        spread_with_reInvest_this_year: spread_with_reInvest_this_year,
        return_this_year: return_this_year,
        interest_this_period: interestThisPeriodArray[i],
        gain_this_period: assetGainsPerPeriodArray[i],
        reInvest_gain_this_period: reinvestmentGainsPerPeriodArray[i],

        // revert to original if not fixed
        //totals to date
        // total_value:
        //   dataObj.starting_amount +
        //   startingAmountGainsArray[i] +
        //   reinvestedAmountGainsArray[i] -
        //   (dataObj.borrowed_amount + cumulativeInterestBorrowedArray[i]),
        total_value: labelValues[i],
        spread_total: startingAmountGainsArray[i] - cumulativeInterestBorrowedArray[i],
        spread_with_reInvest_total:
          startingAmountGainsArray[i] +
          reinvestedAmountGainsArray[i] -
          cumulativeInterestBorrowedArray[i],
        total_interest: totalInterest,
        //   total_interest: cumulativeInterestBorrowedArray[i],
        total_reInvested_gains: reinvestedAmountGainsArray[i],
        total_gains: startingAmountGainsArray[i],
        return_total: return_total,
        approx_average_return_per_year: approx_average_return_per_year,
        futureValue: labelValues[i],
        growthForLineGraph:
          depositArray[i] + cummulativeReturnwithoutDeposit[i] + cummultaiveReInvestmentArray[i],
        lossByBorrowedAmountForLineChart:
          dataObj.starting_amount + Math.abs(borrowedAmountLossArray[i]),
        reInvested_asset: reInvestedAsset,
        reInvestedAmount: i === 0 ? dataObj.re_invested_amount : 0,
        ROR: dataObj.asset_rate_of_return,
        APR: dataObj.annualized_interest_rate,
        reInvested_ror: dataObj.re_invest_rate_of_return,
        years_to_grow: dataObj.years_to_grow,
        BalanceEOY: priorBalanceArray[i].BalanceEOY,
        prior_balance: priorBalanceArray[i].prior_balance,
        total_cash: dataObj.cash_flow_that_year * dataObj.cash_flow_frequency * (i + 1), // 1*1 is because i is tarting from zero
        cash_that_year: dataObj.cash_flow_that_year,
        cash_flow_that_year: dataObj.cash_flow_that_year * dataObj.cash_flow_frequency,
        cash_flow_frequency: dataObj.cash_flow_frequency,
        lossByBorrowedAmountAfterAddingDraw: -1 * priorBalanceArray[i].BalanceEOY,
        cummulativeReturns: cummulativeReturnsArray[i],
        cummulativeReInvestment: cummultaiveReInvestmentArray[i],
        cummulativeReturnsWithoutStartingAmount: cummulativeReturnwithoutDeposit[i],
        deposit: depositArray[i],
        depositsThisYear,
        gainThisYear:
          cummulativeReturnsArray[i] - (i === 0 ? depositArray[0] : cummulativeReturnsArray[i - 1]),
        reInvestGain: reInvestGain,
        reInvestGainThisYear: reInvestGainThisYear,
        borrowedAmountThisYear: i === 0 ? dataObj.borrowed_amount : 0,
        interestThisYear: interestThisYear,
      })
    }
    sendAnalytics(dataObj)
    // sendToSheets(dataObj)
    return barsDataArrayToShowOnChart
  }
  const generateDataforEachBar = (dataArray: any) => {
    let startingAmountGains = 0
    let borrowedAmountLoss = 0
    let lossByBorrowedAmountAfterAddingDraw = 0
    let reinvestedAmountGains = 0
    let cummulativeReturn = 0
    let cummulativeReInvestment = 0
    let interestThisPeriod = {
      interest: 0,
      previousValue: 0,
    }
    let reinvestmentGainsPerPeriod = {
      gain: 0,
      previousValue: 0,
    }
    let assetGainsPerPeriod = {
      gain: 0,
      previousValue: 0,
    }
    let date = new Date()
    let barsDataArrayToShowOnChart: SBLOCDataModel[] = []

    for (let i = 0; i < dataArray.length; i++) {
      let prior_balance = i === 0 ? 0 : dataArray[i - 1].BalanceEOY
      let balanceEOY = (1 + dataArray[i].APR / 100) * (dataArray[i].draw + prior_balance)

      // Store BalanceEOY back to dataArray if needed for later reference
      dataArray[i].BalanceEOY = balanceEOY
      startingAmountGains = calculateSBLOCFutureValueforBar(
        i === 0 ? dataArray[i].starting_amount : dataArray[i - 1].starting_amount, // doing i-1 because it calculates according to the previous starting amount
        dataArray[i].ROR,
        i,
        startingAmountGains
      )
      // Borrowed Amount Loss
      borrowedAmountLoss = calculateSBLOCLossValueForBar(
        dataArray[i].borrowed_amount,
        dataArray[i].APR,
        i === 0 ? dataArray[i].borrowed_amount : borrowedAmountLoss
      )
      // loss by borrowed amount after adding draw
      lossByBorrowedAmountAfterAddingDraw = calculateSBLOCLossValueForBarAfterDraw(
        dataArray[i].borrowed_amount,
        dataArray[i].draw,
        dataArray[i].APR / 100,
        i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].lossByBorrowedAmountAfterAddingDraw,
        i
      )
      // Re Invested Amount Gain
      reinvestedAmountGains = calculateSBLOCFutureValueforBar(
        dataArray[i].reInvested_asset,
        dataArray[i].reInvested_ror,
        i,
        reinvestedAmountGains,
        // sending the true argument that it specifically calculate the values for reinvested formula
        true
      )
      let cumulativeInterestBorrowedArray = calculateSBLOCSummulativeBorrowedAmountForEachBar(
        dataArray[i].borrowed_amount,
        dataArray[i].APR,
        1,
        i + 1,
        0
      )
      interestThisPeriod = {
        ...interestThisPeriod,
        ...calculateSBLOCInterestForBar(
          cumulativeInterestBorrowedArray,
          interestThisPeriod.previousValue
        ),
      }
      assetGainsPerPeriod = {
        ...assetGainsPerPeriod,
        ...calculateSBLOCGainForCurrentPeriodForBar(
          dataArray[i].starting_amount,
          dataArray[i].ROR,
          1,
          i + 1,
          0,
          assetGainsPerPeriod.previousValue
        ),
      }
      reinvestmentGainsPerPeriod = {
        ...reinvestmentGainsPerPeriod,
        ...calculateSBLOCGainForCurrentPeriodForBar(
          dataArray[i].reInvested_asset,
          dataArray[i].reInvested_ror,
          1,
          i + 1,
          0,
          reinvestmentGainsPerPeriod.previousValue
        ),
      }
      cummulativeReturn = calculateSBLOCCummultaiveReturnsForEachBar(
        dataArray[i].starting_amount,
        dataArray[i].ROR,
        i,
        i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].cummulativeReturns
      )
      cummulativeReInvestment = calculateSBLOCCummultaiveReturnsForEachBar(
        dataArray[i].reInvestedAmount,
        dataArray[i].reInvested_ror,
        i,
        i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].cummulativeReInvestment
      )

      let deposit = calculateDepositForSBLOC(
        dataArray[i].starting_amount,
        i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].deposit,
        i
      )
      let depositThisYear = deposit - (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].deposit)
      let labelValues = calculateFutureValueForSBLOCTopLabelForEachBar(
        deposit,
        cummulativeReturn - deposit,
        cummulativeReInvestment,
        balanceEOY
      )
      let spread_this_year = assetGainsPerPeriod.gain - interestThisPeriod.interest

      //spread with re-invested amount this year
      let spread_with_reInvest_this_year =
        assetGainsPerPeriod.gain + reinvestmentGainsPerPeriod.gain - interestThisPeriod.interest

      //return for this year
      let return_this_year = (spread_with_reInvest_this_year / dataArray[i].starting_amount) * 100

      //year
      let year = date.getFullYear() + (i + 1)

      //total return
      // let return_total =
      //   ((startingAmountGains + reinvestedAmountGains - cumulativeInterestBorrowedArray) /
      //     dataArray[i].starting_amount) *
      //   100
      let return_total = ((labelValues - deposit) / deposit) * 100
      //approx average rate of return per year
      let approx_average_return_per_year = return_total / (i + 1)

      let gainThisYear =
        cummulativeReturn -
        (i === 0 ? dataArray[0].deposit : barsDataArrayToShowOnChart[i - 1]?.cummulativeReturns)

      let reInvestedAsset =
        i === 0
          ? dataArray[0].reInvestedAmount
          : dataArray[i].reInvestedAmount + barsDataArrayToShowOnChart[i - 1].reInvested_asset
      // re Invested Gain
      let reInvestGain = cummulativeReInvestment - reInvestedAsset
      // re Invested Gain This Year
      let reInvestGainThisYear =
        reInvestGain - (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].reInvestGain)

      let totalInterest =
        balanceEOY -
        (dataArray[i].draw + (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].borrowed_amount))

      let interestThisYear =
        totalInterest - (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].total_interest)
      // pushing data into the array
      barsDataArrayToShowOnChart.push({
        draw: dataArray[i].draw,
        starting_amount: dataArray[i].starting_amount,
        borrowed_amount:
          dataArray[i].draw + (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].borrowed_amount),
        year: year,

        //values for the height of the bars of chart
        gainOnStartingAmount: startingAmountGains,
        gainOnReInvestedAmount: reinvestedAmountGains,
        lossByBorrowwedAmount: -1 * (borrowedAmountLoss - (i === 0 ? 0 : dataArray[i].draw)),
        topLabelValue: labelValues,

        //rollovers
        //this year
        spread_this_year: spread_this_year,
        spread_with_reInvest_this_year: spread_with_reInvest_this_year,
        return_this_year: return_this_year,
        interest_this_period: interestThisPeriod.interest,
        gain_this_period: assetGainsPerPeriod.gain,
        reInvest_gain_this_period: reinvestmentGainsPerPeriod.gain,

        //totals to date
        // total_value:
        //   dataArray[i].starting_amount +
        //   startingAmountGains +
        //   reinvestedAmountGains -
        //   (dataArray[i].borrowed_amount + cumulativeInterestBorrowedArray),
        total_value: labelValues,
        spread_total: startingAmountGains - cumulativeInterestBorrowedArray,
        spread_with_reInvest_total:
          startingAmountGains + reinvestedAmountGains - cumulativeInterestBorrowedArray,
        total_interest: totalInterest,
        // total_interest: cumulativeInterestBorrowedArray,
        total_reInvested_gains: reinvestedAmountGains,
        total_gains: startingAmountGains,
        return_total: return_total,
        approx_average_return_per_year: approx_average_return_per_year,
        futureValue: labelValues,
        growthForLineGraph: cummulativeReturn + cummulativeReInvestment,
        lossByBorrowedAmountForLineChart:
          dataArray[i].starting_amount + Math.abs(borrowedAmountLoss),
        reInvested_asset: reInvestedAsset,
        reInvestedAmount: dataArray[i].reInvestedAmount,
        ROR: dataArray[i].ROR,
        APR: dataArray[i].APR,
        reInvested_ror: dataArray[i].reInvested_ror,
        years_to_grow: dataArray[i].years_to_grow,
        BalanceEOY: balanceEOY,
        prior_balance: i === 0 ? 0 : dataArray[i - 1].BalanceEOY,
        cash_that_year: dataArray[i].cash_that_year,
        cash_flow_that_year: dataArray[i].cash_that_year * dataArray[i].cash_flow_frequency,
        total_cash:
          dataArray[i].cash_that_year * dataArray[i].cash_flow_frequency +
          (i === 0 ? 0 : barsDataArrayToShowOnChart[i - 1].total_cash),
        cash_flow_frequency: dataArray[i].cash_flow_frequency,
        lossByBorrowedAmountAfterAddingDraw: -1 * balanceEOY,
        cummulativeReturns: cummulativeReturn,
        cummulativeReInvestment: cummulativeReInvestment,
        cummulativeReturnsWithoutStartingAmount: cummulativeReturn - deposit,
        deposit: deposit,
        depositsThisYear: depositThisYear,
        gainThisYear: gainThisYear,
        reInvestGain: reInvestGain,
        reInvestGainThisYear: reInvestGainThisYear,
        borrowedAmountThisYear: dataArray[i].draw,
        interestThisYear: interestThisYear,
      })
    }
    return barsDataArrayToShowOnChart
  }
  const formik = useFormik<SBLOCObjModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: SBLOCSchema,
    onSubmit: () => {},
  })

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  return {
    isFirstRender,
    handleKeyPress,
    formik,
    initialValues,
    generateData,
    generateDataforEachBar,
  }
}
