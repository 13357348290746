import React from 'react'
import {TooltipProps} from 'recharts'
import moment from 'moment'
import {TYPES_OF_ROR} from '../../../../../constants/AppConstants'
import {toUSD, toUSDWithoutRoundOff} from '../../../helper/charts.helper'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import {usePastPerformanceContext} from '../../../../context/PastPerformanceContext'

const MyPerformanceTooltip: React.FC<TooltipProps<number, string>> = ({active, payload}) => {
  const {previousData, isProjectionsInputChanged} = useReceiverFormContext()
  const {
    calculatedPastInvestments,
    myPerformanceDataArray,
    includeAppreciationToggleForLTD,
    includeAppreciationToggleForProjections,
  } = usePastPerformanceContext()

  if (!active || !payload || payload.length === 0 || payload[0]?.payload?.label === 'Custom') {
    return null
  }

  const data = payload[0].payload

  const formatDateHelper = (dateToformat: string) => {
    return moment(dateToformat).format('MM-DD-YYYY')
  }

  // filter calculated past assets
  const pastDateValue = calculatedPastInvestments.filter(
    (value) => value?.investment_name === data?.label
  )

  // creating past assets data
  const pastAssetData = {
    endDate: formatDateHelper(pastDateValue[0]?.pastData?.endDate),
    oldDate: formatDateHelper(pastDateValue[0]?.pastData?.oldDate),
    oldValue: pastDateValue[0]?.pastData?.oldValue.toFixed(2),
    endValue: pastDateValue[0]?.pastData?.currentValue.toFixed(2),
  }

  const gain = data.futureValue - data.contributions - data.initialDeposit
  const absoluteGain = Math.abs(gain)
  if (data.dataKey) {
    return (
      <div className='custom-tooltip'>
        {/* tooltip label */}
        <p className='qsp-label-font paragraph-small label-main'>{data?.dataKey}</p>

        <p className={'qsp-label-font paragraph-x-small mb-0 label-black'}>{data?.timeFrame}</p>
        <br />
        <p className={'qsp-label-font paragraph-x-small mb-0 label-gain label-bold'}>
          Annualized ROR: {(data?.ROR).toFixed(2)}%
        </p>
        <p className='qsp-label-font paragraph-x-small mb-0 label-gain label-bold'>
          Rental Income : {toUSDWithoutRoundOff(data.dataValue)}
        </p>
        <p className='qsp-label-font paragraph-x-small mb-0 label-initial-deposit'>
          Cash on Cash : <span className='label-bold'>{(data?.annualized_ROR).toFixed(2)}%</span>
        </p>
      </div>
    )
  }
  //find object for the current bar
  let previousObject = previousData.find((item) => {
    return item.label === data.label
  })
  //finding values for the previous bars
  const previousGain =
    previousObject?.futureValue - previousObject?.contributions - previousObject?.initialDeposit
  const previousAbsoluteGain = Math.abs(previousGain)

  const renderGain = (
    label: string,
    value: number,
    proportionValue: number,
    originalValue: number,
    isLegacy: boolean,
    management_fee: number,
    colorName: string
  ) => {
    const formattedValue = toUSDWithoutRoundOff(value)
    const formattedProportionValue = proportionValue ? proportionValue.toFixed(2) : (0.0).toFixed(2)
    const formattedOriginalValue = toUSD(originalValue ? originalValue : 0)
    return (
      <p className={`qsp-label-font paragraph-x-small mb-0 label-bold ${colorName}`}>
        {label}: <span className='label-bold'>{formattedValue}</span>
        {!isLegacy && originalValue !== 0 && (
          <span className='label-bold'>
            {' ('}
            {formattedProportionValue}% of {formattedOriginalValue}
            {') '}
          </span>
        )}
        {isLegacy && originalValue !== 0 && (
          <span className='label-bold'>
            {' ('}
            {(TYPES_OF_ROR.TYPE_AFTER_EXIT && !myPerformanceDataArray.customer_actual) ||
            label !== 'Gain (Equity)'
              ? `${formattedProportionValue}% of `
              : null}
            {management_fee}% of {formattedOriginalValue}
            {') '}
          </span>
        )}
      </p>
    )
  }

  const renderGainAppreciation = (key: number) => {
    switch (key) {
      case 2:
        return includeAppreciationToggleForLTD ? (
          <p className={'qsp-label-font paragraph-x-small mb-0'}>
            {renderGain(
              'Gain (Appreciation)',
              data?.ARQ_appreciation,
              data?.originalARQValues.proportionValue,
              data?.originalARQValues.appreciation,
              data?.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
              data?.originalARQValues.management_fee,
              'label-gain'
            )}
          </p>
        ) : null
      case 3:
        return includeAppreciationToggleForProjections ? (
          <p className={'qsp-label-font paragraph-x-small mb-0'}>
            {renderGain(
              'Gain (Appreciation)',
              data?.ARQ_appreciation,
              data?.originalARQValues.proportionValue,
              data?.originalARQValues.appreciation,
              data?.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
              data?.originalARQValues.management_fee,
              'label-gain'
            )}
          </p>
        ) : null
      default:
        return (
          <p className={'qsp-label-font paragraph-x-small mb-0'}>
            {renderGain(
              'Gain (Appreciation)',
              data?.ARQ_appreciation,
              data?.originalARQValues.proportionValue,
              data?.originalARQValues.appreciation,
              data?.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
              data?.originalARQValues.management_fee,
              'label-gain'
            )}
          </p>
        )
    }
  }

  return (
    <div className='custom-tooltip'>
      {/* tooltip label */}
      <p className='paragraph-small label-main'>
        {data.label}
        {data.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT
          ? ' (Rental Income Only)'
          : data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
          ? ' (Includes Equity)'
          : null}
      </p>

      {/* tooltip data specific to my performance */}
      {data.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT || data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT ? (
        <>
          {/* returns */}
          <p className={'qsp-label-font paragraph-x-small mb-0 label-black'}>
            Returns:{' '}
            {data.chartKey === -1 // -1 means YTD(Year-to-date) chart
              ? toUSDWithoutRoundOff(data?.YTDRentalIncome)
              : toUSDWithoutRoundOff(data?.rentalIncome)}
            {data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT ? ', plus equity' : null}
          </p>

          {/* total ROR */}
          <p className={'qsp-label-font paragraph-x-small mb-0 label-black'}>
            Total ROR: {data?.totalROR?.toFixed(2)}%
          </p>

          {/* annual ROR */}
          {/* <p className={'paragraph-x-small mb-0 label-black'}>
            Annualized ROR: {data?.annualROR?.toFixed(2)}%
          </p> */}

          <br />

          {/* Time frame*/}
          <p className={'qsp-label-font paragraph-x-small mb-0 label-black'}>{data?.timeFrame}</p>

          <br />
          {/* target ROR = original ROR */}
          <p className={'qsp-label-font paragraph-x-small mb-0 label-brown'}>
            Target ROR: {data?.original_ROR}%
          </p>

          {/* expected ROR */}
          <p className={'qsp-label-font paragraph-x-small mb-0 label-brown'}>
            Expected ROR: {data?.expected_ROR}%
          </p>

          <br />
          <p className={'qsp-label-font paragraph-x-small mb-0 label-gain label-bold'}>
            Cash on Cash: {data?.cashOnCash?.toFixed(2)}%
          </p>
          {/* <p className={'paragraph-x-small mb-0 label-gain'}>
                  Cash on Cash (annualized): {data?.cashOnCashAnnualized?.toFixed(2)}%
                </p> */}
          <br />
          {/* gain rental income with time interval */}
          <p className={'qsp-label-font paragraph-x-small mb-0'}>
            {renderGain(
              `Gain (Rental Income${
                data.chartKey === 0
                  ? ' this Quarter'
                  : data.chartKey === -1 // -1 means YTD(Year-to-date) chart
                  ? ' YTD'
                  : data.chartKey === 2
                  ? ' LTD'
                  : ''
              })`,
              data.ARQ_rental_income,
              data.originalARQValues.proportionValue,
              data.originalARQValues.rental,
              data.type !== TYPES_OF_ROR.TYPE_ARQ_RENTAL,
              data.originalARQValues.management_fee,
              'label-gain'
            )}
          </p>
          {/* {data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT && !myPerformanceDataArray.customer_actual ? (
            <> */}
          {/* paid out on */}
          {data.chartKey === 0 && data.paidOutOn !== '' ? (
            <p className={'qsp-label-font paragraph-x-small mb-0 label-gain label-bold'}>
              Paid-out-on: {data.paidOutOn}
            </p>
          ) : null}
          {data.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT ? <br /> : null}

          {/* gain equity */}
          {data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT ? (
            <p className={'qsp-label-font paragraph-x-small mb-0'}>
              {renderGain(
                'Gain (Equity)',
                data?.ARQ_Flip_Gain,
                data?.originalARQValues.proportionValue,
                data?.originalARQValues.fixAndFlip,
                data?.type === TYPES_OF_ROR.TYPE_AFTER_EXIT,
                data?.originalARQValues.management_fee,
                'label-re-invest'
              )}
            </p>
          ) : null}

          {/* gain appreciation */}
          {data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
            ? renderGainAppreciation(data.chartKey)
            : null}
        </>
      ) : null}

      {/* general tooltip data */}
      {data.isLoss && data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        <p className='qsp-label-font paragraph-x-small mb-0 label-loss'>
          Loss: <span className='label-bold qsp-label-font'>{toUSD(absoluteGain)}</span>
        </p>
      ) : data.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
        renderGain('Gain', absoluteGain, 0, 0, false, 0, 'label-gain')
      ) : null}

      {/* contributions */}
      {data.type === TYPES_OF_ROR.TYPE_SIMPLE && (
        <p className='qsp-label-font paragraph-x-small mb-0 label-contributions'>
          Contributions: <span className='label-bold'>{toUSD(data.contributions)}</span>
        </p>
      )}

      {/* starting amount or your investment */}
      <br />
      <p className='qsp-label-font paragraph-x-small mb-0 label-initial-deposit'>
        {data.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT || data.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
          ? 'Your Investment'
          : 'Starting amount'}
        : <span className='label-bold'>{toUSD(data?.calculatedInvestment)}</span>
      </p>

      {/* past date values */}
      {data.chartKey === 2 ? (
        calculatedPastInvestments.some(
          (investment) => investment.investment_name === data?.label
        ) ? (
          <>
            <br />
            <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
              Old Date : <span className='label-bold'>{pastAssetData.oldDate}</span>
            </p>
            <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
              Old Value : <span className='label-bold'>{pastAssetData.oldValue}</span>
            </p>
            <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
              End Date : <span className='label-bold'>{pastAssetData.endDate}</span>
            </p>
            <p className='qsp-label-font paragraph-x-small mb-0 label-black'>
              End Value : <span className='label-bold'>{pastAssetData.endValue}</span>
            </p>
          </>
        ) : null
      ) : null}

      {/* FOR PREVIOUS VALUES */}
      {isProjectionsInputChanged ? (
        <>
          <p className='qsp-label-font paragraph-small gray-text mt-5'>Previous:</p>
          {previousObject?.isLoss && previousObject?.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
            <p className='qsp-label-font paragraph-x-small mb-0 gray-text'>
              Loss: <span className='label-bold'>{toUSD(previousAbsoluteGain)}</span>
            </p>
          ) : previousObject.type === TYPES_OF_ROR.TYPE_SIMPLE ? (
            renderGain('Gain', previousAbsoluteGain, 0, 0, false, 0, 'gray-text')
          ) : null}

          {previousObject?.type === TYPES_OF_ROR.TYPE_SIMPLE && (
            <p className='qsp-label-font paragraph-x-small mb-0 gray-text'>
              Contributions:{' '}
              <span className='label-bold'>{toUSD(previousObject?.contributions)}</span>
            </p>
          )}

          <p className='qsp-label-font paragraph-x-small mb-0 gray-text'>
            Starting amount:{' '}
            <span className='label-bold'>{toUSD(previousObject?.initialDeposit)}</span>
          </p>
        </>
      ) : null}
    </div>
  )
}

export default MyPerformanceTooltip
