import React from 'react'
import ProjectionsChart from '../homeComponents/projections/components/ProjectionsChart'
import {InvestmentChartDataProps} from '../helper/calculator/_model'
import {Experiment, Variant, emitter} from '@marvelapp/react-ab-test'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import useScreenWidth from '../hooks/useScreenWidth'
import {ChartExperiment} from './_ABTestConfig'
import ExpirationTimer from '../../../core/ExpirationTimer'

emitter.defineVariants(
  ChartExperiment.name,
  [ChartExperiment.variantA, ChartExperiment.variantB],
  [ChartExperiment.probabilityA, ChartExperiment.probabilityB]
)
const ChartVariants: React.FC<InvestmentChartDataProps> = (props) => {
  const screenWidth = useScreenWidth()
  const {data, handleCustomBar, handleReply} = props
  const _chartHeight = screenWidth > 767 ? 300 : screenWidth > 567 ? 250 : 200

  const isProduction = process.env.NODE_ENV === 'production'

  return (
    <div>
      {isProduction ? (
        <>
          <Experiment name={ChartExperiment.name}>
            <Variant name={ChartExperiment.variantA}>
              <ProjectionsChart
                data={data}
                handleCustomBar={handleCustomBar}
                handleReply={handleReply}
              />
            </Variant>
            <Variant name={ChartExperiment.variantB}>
              <div id='tree-chart'>
                <img
                  alt='tree'
                  style={{height: _chartHeight, width: '100%'}}
                  src={toAbsoluteUrl('/media/home/placeholder.png')}
                ></img>
              </div>
            </Variant>
          </Experiment>
          <ExpirationTimer />
        </>
      ) : (
        // Render only the ProjectionsChart in non-production environments
        <ProjectionsChart data={data} handleCustomBar={handleCustomBar} handleReply={handleReply} />
      )}
    </div>
  )
}
export default ChartVariants
