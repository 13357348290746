import moment from 'moment'
import {useEffect, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'

import {DataRow} from '../core/_model'
import DataTableSearch from './DataTableSearch'
import {toUSD} from '../../../../user/modules/helper/charts.helper'
import {useAuth} from '../../../../modules/auth'
import {Link, Router} from 'react-router-dom'
import {useReceiverFormContext} from '../../../../user/context/ReceiverContext'
import {useNavigate} from 'react-router-dom'
import {
  assets,
  defaultAsset,
} from '../../../../user/modules/homeComponents/projections/components/ProjectionsConfig'
import {useProjectionsState} from '../../../../user/modules/utils/commonUtils'
interface Props {
  suggestedFeaturesList: DataRow[]
}

const DataTableComponent: React.FC<Props> = ({suggestedFeaturesList}) => {
  const [search, setSearch] = useState('')
  const [filteredData, setFilteredData] = useState<DataRow[]>(suggestedFeaturesList)
  useEffect(() => {
    const result = suggestedFeaturesList.filter((data) => {
      return data?.name?.toLowerCase()?.includes(search?.toLowerCase())
    })
    setFilteredData(result)
  }, [search])

  // data table columns
  const columns: TableColumn<DataRow>[] = [
    {
      name: 'Sr.#',
      id: 'id',
      cell: (row, index) => (index = index + 1),
    },
    {
      name: 'Name',
      id: 'starting_amount',
      selector: (row) => row.name,
    },
    {
      name: 'Description',
      id: 'contributionss',
      selector: (row) => row.description,
    },
    {
      name: 'Created At',
      id: 'createdAt',
      selector: (row) => moment(row.createdAt).format('YYYY-MM-DD hh:mm A'),
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={filteredData}
      pagination
      fixedHeader
      highlightOnHover
      subHeader
      subHeaderComponent={<DataTableSearch value={search} setSearch={setSearch} />}
    />
  )
}

export default DataTableComponent
