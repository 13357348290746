import React, {useState, ChangeEvent, FocusEvent, KeyboardEvent, useEffect, useRef} from 'react'
import CustomInputField from '../../../../../components/customInputField/CustomInputField'

interface EditableCellProps {
  value: string
  rowIndex: number
  keyId: string
  onChange: (rowIndex: number, keyId: string, newValue: string) => void
  dragging: boolean // Added to indicate if drag-to-select is active
  onToggleSelect: (rowIndex: number, isShift: boolean) => void
  isEditable: boolean
  isNegativeAllowed?: boolean // Use the new prop
  selectedRows?: boolean
}

const EditableCell: React.FC<EditableCellProps> = ({
  value,
  rowIndex,
  keyId,
  onChange,
  dragging,
  onToggleSelect,
  isEditable,
  isNegativeAllowed = false,
  selectedRows = false,
}) => {
  const [editing, setEditing] = useState(false)
  const [cellValue, setCellValue] = useState<any>(value)
  const inputRef = useRef<HTMLInputElement>(null)
  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setEditing(false)
    if (cellValue.trim() !== '') {
      onChange(rowIndex, keyId, cellValue)
    } else {
      onChange(rowIndex, keyId, value) // Reset to original value if input is empty
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let input = e.target.value
    // Allow only digits, negative sign, and decimal points
    const regex = isNegativeAllowed ? /^[0-9.-]*$/ : /^[0-9.]*$/
    if (regex.test(input)) {
      setCellValue(input)
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    onToggleSelect(rowIndex, false)
  }

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setEditing(false)
      onChange(rowIndex, keyId, cellValue.trim() !== '' ? cellValue : value)
    }
  }

  const activateEdit = (e: React.MouseEvent<HTMLDivElement>) => {
    setEditing(true)

    let initialValue = e.currentTarget.innerText

    // Remove any characters that are not digits, minus sign, or decimal point
    initialValue = initialValue.replace(/[^\d.-]/g, '')

    // Remove everything after the decimal point, if it exists
    // initialValue = initialValue.replace(/\.\d*$/, '')

    if (isNegativeAllowed) {
      // Ensure only the first minus sign is kept if negative numbers are allowed
      if (initialValue.startsWith('-')) {
        // Remove all other minus signs except the first
        initialValue = '-' + initialValue.slice(1).replace(/-/g, '')
      }
    } else {
      // Remove all minus signs if negative numbers are not allowed
      initialValue = initialValue.replace(/-/g, '')
    }

    setCellValue(initialValue)
  }

  const handleMouseEnter = () => {
    if (dragging) {
      onToggleSelect(rowIndex, false)
    }
  }

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (!isEditable) {
      onToggleSelect(rowIndex, e.shiftKey)
    }
    if (isEditable && !selectedRows) {
      onToggleSelect(rowIndex, e.shiftKey)
    }
  }
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus()
      inputRef.current.setSelectionRange(0, inputRef.current.value.length) // Select the input value
    }
  }, [editing])
  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseDown={handleMouseDown}
      onClick={activateEdit}
      style={{width: '100%', height: '100%'}}
      className='d-flex justify-content-center align-items-center text-align-center'
    >
      {isEditable ? (
        editing ? (
          <CustomInputField
            id='table-input'
            decimalsLimit={2}
            maxLength={11}
            value={cellValue}
            // onChange={handleChange}
            onValueChange={(value: string | undefined) => {
              // Parse float to handle decimal values correctly and ensure it is a number.
              const parsedValue = isNaN(parseFloat(value!)) ? '' : parseFloat(value!).toFixed(2)
              setCellValue(parsedValue)
            }}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
            onClick={handleClick}
            style={{width: '50%'}}
            ref={inputRef} // Add the ref here
          />
        ) : (
          <span>{value !== '-1' ? value : '-'}</span> // Display '-' or the value based on condition
        )
      ) : (
        <span>{value !== '-1' ? value : '-'}</span> // This will display when isEditable is false
      )}
    </div>
  )
}

export default EditableCell
