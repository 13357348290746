import axios from 'axios'
import {CustomInvestments} from '../components/_model'
const POST_ADVISOR_FORM_URL = 'advisors-form/add-advise'
const FETCH_ADVISOR_FORM_URL = 'advisors-form/get-advise'
const FETCH_PAST_INVESTMENTS = 'investments/get-all-investments'
const FETCH_LOGOS = '/advisors-form/get-logos'
const UPDATE_ADVISOR_FORM_URL = 'advisors-form/update-advise'
const UPLOAD_IMAGE = 'advisors-form/upload-image'
const POST_SUGGESTED_FEATURE = 'suggested-feature/create'
const POST_SHARED_SBLOC = 'shared-sbloc/create'
const FETCH_SHARED_SBLOC = 'shared-sbloc/get-data-from-slug'

// fetching advisor form data
export function uploadImageToServer(image: FormData) {
  return axios.post(UPLOAD_IMAGE, image)
}

// fetching advisor form data
export function fetchAdvisorFormData(URL: string) {
  return axios.get(FETCH_ADVISOR_FORM_URL, {
    params: {advise_url: URL},
  })
}

// fetching advisor form data
export function fetchPastInvestments() {
  return axios.get(FETCH_PAST_INVESTMENTS)
}

// fetching logos
export function fetchLogos() {
  return axios.get(FETCH_LOGOS)
}

// storing advisor form data
export function submitAdvisorForm(
  starting_amount: number,
  contributions: number,
  contributions_frequency: number,
  rate_of_return: number,
  customFields: CustomInvestments[],
  years_to_grow: number,
  greetings: string,
  logo_url: string,
  custom_email: string,
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  notes: string | null,
  management_fee: number,
  redirectURLRoute: string | null,
  selected_bars: object | null
) {
  return axios.post(POST_ADVISOR_FORM_URL, {
    starting_amount: starting_amount,
    contributions: contributions,
    contributions_frequency: contributions_frequency,
    rate_of_return: rate_of_return,
    custom_investment_values: customFields,
    years_to_grow: years_to_grow,
    greeting_message: greetings,
    logo: logo_url,
    custom_email: custom_email,
    ARQ_Flip_Gain: ARQ_Flip_Gain,
    ARQ_rental_income: ARQ_rental_income,
    ARQ_rental_contribution: ARQ_rental_contribution,
    ARQ_appreciation: ARQ_appreciation,
    ARQ_property_worth: ARQ_property_worth,
    notes: notes,
    management_fee: management_fee,
    redirectURLRoute: redirectURLRoute,
    selected_bars: selected_bars,
  })
}

// updating advisor form data
export function updateAdvisorForm(
  starting_amount: number,
  contributions: number,
  contributions_frequency: number,
  rate_of_return: number,
  customFields: CustomInvestments[],
  years_to_grow: number,
  greetings: string,
  logo_url: string,
  custom_email: string,
  ARQ_Flip_Gain: number,
  ARQ_rental_income: number,
  ARQ_rental_contribution: number,
  ARQ_appreciation: number,
  ARQ_property_worth: number,
  URL: string | null | undefined,
  notes: string | null,
  management_fee: number,
  redirectURLRoute: string | null,
  selected_bars: object | null
) {
  return axios.put(UPDATE_ADVISOR_FORM_URL, {
    starting_amount: starting_amount,
    contributions: contributions,
    contributions_frequency: contributions_frequency,
    rate_of_return: rate_of_return,
    custom_investment_values: customFields,
    years_to_grow: years_to_grow,
    greeting_message: greetings,
    logo: logo_url,
    custom_email: custom_email,
    ARQ_Flip_Gain: ARQ_Flip_Gain,
    ARQ_rental_income: ARQ_rental_income,
    ARQ_rental_contribution: ARQ_rental_contribution,
    ARQ_appreciation: ARQ_appreciation,
    ARQ_property_worth: ARQ_property_worth,
    advise_url: URL,
    notes: notes,
    management_fee,
    redirectURLRoute: redirectURLRoute,
    selected_bars: selected_bars
  })
}

// adding suggested features
export function addSuggestedFeature(feature_id: number, name: string, description: string) {
  return axios.post(POST_SUGGESTED_FEATURE, {
    feature_id: feature_id,
    name: name,
    description: description,
  })
}

export function addSharedSbloc(dataArray: any, slug: any) {
  return axios.post(POST_SHARED_SBLOC, {dataArray: dataArray, slug: slug})
}

export function fetchSharedSBlocDataArray(slug: any) {
  return axios.get(FETCH_SHARED_SBLOC, {
    params: {slug: slug},
  })
}

function updateSBLOCActions(id: number) {
  return fetch(`${process.env.REACT_APP_WAYSTONE_URL}/users/update-sbloc-actions/${id}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json', // Specify the content type in the header
    },
  })
    .then((response) => response.json()) // Parsing the JSON response
    .catch((error) => console.error('Error:', error)) // Handling errors
}

export const handleSBLOCActions = (parentUserId: number) => {
  setTimeout(() => {
    if (parentUserId) updateSBLOCActions(parentUserId)
  }, 3000)
}
