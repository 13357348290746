import {useState, useEffect} from 'react'

import {
  InvestmentChartProps,
  PastPerformanceCompoundingGrowth,
} from '../../../helper/calculator/_model'

import {usePastPerformanceContext} from '../../../../context/PastPerformanceContext'
import {fetchPastInvestments} from '../../../advisor/core/_request'

export function usePastPerformanceState() {
  const {setPastInvestments} = usePastPerformanceContext()

  // past performance state hooks
  const [pastChartData, setPastChartData] = useState<InvestmentChartProps[]>([])
  const [pastChartDataOldValues, setPastChartDataOldValues] = useState<InvestmentChartProps[]>([])
  const [pastPerformanceAssetsOldState, setPastPerformanceAssetsOldState] = useState<
    PastPerformanceCompoundingGrowth[]
  >([])

  // for past performance API
  useEffect(() => {
    handleFetchPastInvestments()
  }, [])

  // fetch past investments from API
  const handleFetchPastInvestments = async () => {
    try {
      const response = await fetchPastInvestments()
      setPastInvestments(response.data)
    } catch (error: any) {
      console.log('An error occurred during the request.')
    }
  }

  return {
    pastChartData,
    setPastChartData,
    pastChartDataOldValues,
    setPastChartDataOldValues,
    pastPerformanceAssetsOldState,
    setPastPerformanceAssetsOldState,
  }
}
