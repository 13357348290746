import React, {useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import clipboardCopy from 'clipboard-copy'
import {Button} from 'react-bootstrap'
import {InvestmentTableModel} from '../../../helper/calculator/_model' // Import the defined interface
import {toUSDWithoutRoundOff} from '../../../helper/charts.helper'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {projectionsColors} from './ProjectionsConfig'
import {PROJECTIONS_TABLE, TYPES_OF_ROR, sectionIds} from '../../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import FeedbackSection from '../../feedbackSection/FeedbackSection'
import {useLocation} from 'react-router-dom'

const ProjectionsTable: React.FC<{tableData: InvestmentTableModel[]}> = ({tableData}) => {
  const screenWidth = useScreenWidth()
  const location = useLocation()
  const isWaystoneInURL = location.pathname.includes('waystone')
  const {receiverFormDataObj} = useReceiverFormContext()
  const [copyMessage, setCopyMessage] = useState<string | null>(null)

  const isMobileScreen = screenWidth < 576
  const showPagination = !tableData[0]?.isMonthly ? tableData.length > 10 : false

  const columns: TableColumn<InvestmentTableModel>[] = [
    {
      name: !tableData[0]?.isMonthly ? PROJECTIONS_TABLE.TIME_YEAR : PROJECTIONS_TABLE.TIME_MONTH,
      id: 'year',
      selector: (row) => row.yearOrMonth,
    },
    {
      name: PROJECTIONS_TABLE.INITIAL_DEPOSIT,
      id: 'initial-deposit',
      selector: (row) =>
        row.initialDeposit !== -1 ? toUSDWithoutRoundOff(row.initialDeposit) : '-',
      omit: isMobileScreen,
      style: {
        background: projectionsColors.initialDeposit,
        color: projectionsColors.white,
      },
    },
    {
      name: PROJECTIONS_TABLE.CONTRIBUTIONS,
      id: 'contributions',
      selector: (row) =>
        row.contributionsThisPeriod !== null && toUSDWithoutRoundOff(row.contributionsThisPeriod),
      omit: isMobileScreen,
      style: {
        background: projectionsColors.contributions,
        color: projectionsColors.white,
      },
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_CONTRB,
      id: 'total-contributions',
      selector: (row) =>
        row.cumulativeContributions !== null && toUSDWithoutRoundOff(row.cumulativeContributions),
      omit: isMobileScreen,
      style: {
        background: projectionsColors.contributions,
        color: projectionsColors.white,
      },
    },
    {
      name: PROJECTIONS_TABLE.GAINS,
      id: 'gains',
      selector: (row) => row.gainThisPeriod !== null && toUSDWithoutRoundOff(row.gainThisPeriod),
      style: {
        background: projectionsColors.gain,
      },
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_GAINS,
      id: 'total-gains',
      selector: (row) => row.cumulativeGains !== null && toUSDWithoutRoundOff(row.cumulativeGains),
      omit: isMobileScreen,
      style: {
        background: projectionsColors.gain,
      },
    },
    {
      name: PROJECTIONS_TABLE.TOTAL,
      id: 'estimated-total',
      selector: (row) =>
        row.isLoss
          ? '-' + toUSDWithoutRoundOff(row.futureValue)
          : toUSDWithoutRoundOff(row.futureValue),
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_ROI,
      id: 'roi',
      selector: (row) => row.ROR !== null && row.ROR,
    },
  ]

  const flipTypeColumns: TableColumn<InvestmentTableModel>[] = [
    {
      name: !tableData[0]?.isMonthly ? PROJECTIONS_TABLE.TIME_YEAR : PROJECTIONS_TABLE.TIME_MONTH,
      id: 'year',
      selector: (row) => row.yearOrMonth,
    },
    {
      name: PROJECTIONS_TABLE.INITIAL_DEPOSIT,
      id: 'initial-deposit',
      selector: (row) =>
        row.initialDeposit !== -1 ? toUSDWithoutRoundOff(row.initialDeposit) : '-',
      style: {
        background: projectionsColors.initialDeposit,
        color: projectionsColors.white,
      },
    },
    {
      name: PROJECTIONS_TABLE.PROFIT_PHASE_1,
      id: 'flip-gain',
      selector: (row) => row.flipGain !== null && toUSDWithoutRoundOff(row.flipGain),
      style: {
        background: projectionsColors.gain,
      },
    },
    {
      name: PROJECTIONS_TABLE.FUTURE_VALUE,
      id: 'estimated-total',
      selector: (row) => toUSDWithoutRoundOff(row.futureValue),
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_ROI,
      id: 'roi',
      selector: (row) => row.ROR !== null && row.ROR,
    },
    {
      name: PROJECTIONS_TABLE.CUSTOM_ROR,
      id: 'custom-ror',
      selector: (row) => row.customROR !== null && row.customROR,
    },
  ]

  const rentalTypeColumns: TableColumn<InvestmentTableModel>[] = [
    {
      name: !tableData[0]?.isMonthly ? PROJECTIONS_TABLE.TIME_YEAR : PROJECTIONS_TABLE.TIME_MONTH,
      id: 'year',
      selector: (row) => row.yearOrMonth,
    },
    {
      name: PROJECTIONS_TABLE.INITIAL_DEPOSIT,
      id: 'initial-deposit',
      selector: (row) =>
        row.initialDeposit !== -1 ? toUSDWithoutRoundOff(row.initialDeposit) : '-',
      style: {
        background: projectionsColors.initialDeposit,
        color: projectionsColors.white,
      },
    },
    {
      name: PROJECTIONS_TABLE.RENTAL_INCOME,
      id: 'rental-income',
      selector: (row) => row.rentalIncome !== null && toUSDWithoutRoundOff(row.rentalIncome),
      style: {
        background: projectionsColors.gain,
      },
    },
    {
      name: PROJECTIONS_TABLE.PROFIT_PHASE_1,
      id: 'flip-gain',
      selector: (row) =>
        row.flipGain !== null && row.flipGain !== -1 ? toUSDWithoutRoundOff(row.flipGain) : '-',
      //omit: receiverFormDataObj.selected_rate_of_return_obj.type === TYPES_OF_ROR.TYPE_AFTER_EXIT,
      style: {
        background: projectionsColors.gain,
      },
    },
    {
      name: PROJECTIONS_TABLE.FUTURE_VALUE,
      id: 'estimated-total',
      selector: (row) => toUSDWithoutRoundOff(row.futureValue),
    },
    {
      name: PROJECTIONS_TABLE.CASH_ON_CASH,
      id: 'cash-on-cash',
      omit: receiverFormDataObj.selected_rate_of_return_obj.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL,
      selector: (row) => row.cashOnCash !== null && row.cashOnCash + '%',
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_CASH_ON_CASH,
      id: 'total-cash-on-cash',
      selector: (row) => row.totalCashOnCash !== null && row.totalCashOnCash + '%',
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_ROI,
      id: 'roi',
      selector: (row) => row.ROR !== null && row.ROR,
    },
    {
      name: PROJECTIONS_TABLE.CUSTOM_ROR,
      id: 'custom-ror',
      selector: (row) => row.customROR !== null && row.customROR,
    },
  ]

  const beforeExitTypeColumns: TableColumn<InvestmentTableModel>[] = [
    {
      name: !tableData[0]?.isMonthly ? PROJECTIONS_TABLE.TIME_YEAR : PROJECTIONS_TABLE.TIME_MONTH,
      id: 'year',
      selector: (row) => row.yearOrMonth,
    },
    {
      name: PROJECTIONS_TABLE.INITIAL_DEPOSIT,
      id: 'initial-deposit',
      selector: (row) =>
        row.initialDeposit !== -1 ? toUSDWithoutRoundOff(row.initialDeposit) : '-',
      style: {
        background: projectionsColors.initialDeposit,
        color: projectionsColors.white,
      },
    },
    {
      name: PROJECTIONS_TABLE.RENTAL_INCOME,
      id: 'rental-income',
      selector: (row) => row.rentalIncome !== null && toUSDWithoutRoundOff(row.rentalIncome),
      style: {
        background: projectionsColors.gain,
      },
    },
    {
      name: PROJECTIONS_TABLE.FUTURE_VALUE,
      id: 'estimated-total',
      selector: (row) => toUSDWithoutRoundOff(row.futureValue),
    },
    {
      name: PROJECTIONS_TABLE.CASH_ON_CASH,
      id: 'cash-on-cash',
      selector: (row) => row.cashOnCash !== null && row.cashOnCash + '%',
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_CASH_ON_CASH,
      id: 'total-cash-on-cash',
      selector: (row) => row.totalCashOnCash !== null && row.totalCashOnCash + '%',
    },
    {
      name: PROJECTIONS_TABLE.TOTAL_ROI,
      id: 'roi',
      selector: (row) => row.ROR !== null && row.ROR,
    },
    {
      name: PROJECTIONS_TABLE.CUSTOM_ROR,
      id: 'custom-ror',
      selector: (row) => row.customROR !== null && row.customROR,
    },
  ]
  let selectedColumns: TableColumn<InvestmentTableModel>[] = columns // Default to columns

  if (receiverFormDataObj.selected_rate_of_return_obj.type === TYPES_OF_ROR.TYPE_ARQ_FLIP) {
    selectedColumns = flipTypeColumns
  } else if (
    receiverFormDataObj.selected_rate_of_return_obj.type === TYPES_OF_ROR.TYPE_BEFORE_EXIT
  ) {
    selectedColumns = beforeExitTypeColumns
  } else if (
    receiverFormDataObj.selected_rate_of_return_obj.type === TYPES_OF_ROR.TYPE_ARQ_RENTAL ||
    receiverFormDataObj.selected_rate_of_return_obj.type === TYPES_OF_ROR.TYPE_AFTER_EXIT
  ) {
    selectedColumns = rentalTypeColumns
  }
  const copyTableData = () => {
    const tableText = [selectedColumns.map((column) => column.name).join('\t')]

    tableData.forEach((row) => {
      const rowData = selectedColumns.map((column) => {
        const value = column.selector ? column.selector(row) : ''
        return value !== undefined ? value : ''
      })
      tableText.push(rowData.join('\t'))
    })

    const tableTextString = tableText.join('\n')
    clipboardCopy(tableTextString)
      .then(() => {
        setCopyMessage('Table data copied to clipboard.')
        setTimeout(() => {
          setCopyMessage('')
        }, 3000)
      })
      .catch((error) => {
        setCopyMessage('Copy failed. Please try again.')
        setTimeout(() => {
          setCopyMessage('')
        }, 500)
      })
  }

  return (
    <div id={sectionIds[2]}>
      <p className='text-center pt-0 pb-2'>
        For what a <span className='pink-text fw-600'>{tableData[0]?.selectedRate}</span> account
        could look like:
      </p>
      <DataTable
        columns={selectedColumns}
        data={tableData}
        pagination={showPagination}
        paginationPerPage={10}
      />
      <div className='d-flex justify-content-end mt-10 mb-7'>
        {copyMessage ? (
          <div>{copyMessage}</div>
        ) : (
          <Button className='custom-secondary-button' onClick={copyTableData}>
            COPY TABLE
          </Button>
        )}
      </div>

      {/* feedback section */}
      <FeedbackSection sectionName='projections table' />
    </div>
  )
}

export default ProjectionsTable
