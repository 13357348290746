// formula for calculating property worth
export const calculatePropertyWorthPercentages = (
  value: number,
  ARQ_property_worth: number,
  startingAmount: number
): number => {
  const propertyWorthEquity = startingAmount !== 0 ? startingAmount / ARQ_property_worth : 0

  return value * propertyWorthEquity
}

// calculate equity
const calculateEquity = (
  ARQ_Flip_Gain: number,
  ARQ_property_worth: number,
  startingAmount: number,
  rollover: boolean,
  new_starting_amount: number
) => {
  return rollover
    ? startingAmount !== 0
      ? (startingAmount + ARQ_Flip_Gain) / ARQ_property_worth
      : 0
    : new_starting_amount / ARQ_property_worth
}

// calculate ARQ appreciation with equity
export const calculateARQAppreciationWithEquity = (
  ARQ_Flip_Gain: number,
  ARQ_property_worth: number,
  startingAmount: number,
  rollover: boolean,
  new_starting_amount: number,
  ARQ_Property_End_Value: number,
  ARQ_Property_Worth: number
) => {
  const equity = calculateEquity(
    ARQ_Flip_Gain,
    ARQ_property_worth,
    startingAmount,
    rollover,
    new_starting_amount
  )
  return (ARQ_Property_End_Value - ARQ_Property_Worth) * equity
}

// calculate ARQ appreciation
export const calculateARQAppreciation = (
  ARQ_Property_End_Value: number,
  ARQ_Property_Worth: number
) => {
  return ARQ_Property_End_Value - ARQ_Property_Worth
}

export const calculateCashOnCash = (
  totalRental: number,
  flipGain: number,
  appreciation: number,
  startingAmount: number
) => {
  return (totalRental + flipGain + appreciation) / startingAmount
}

// formula for calculating property worth
export const calculatePropertyWorthPercentages_New = (
  ARQ_Flip_Gain: number,
  ARQ_property_worth: number,
  startingAmount: number,
  rollover: boolean,
  new_starting_amount: number
): number => {
  const propertyWorthEquity = calculateEquity(
    ARQ_Flip_Gain,
    ARQ_property_worth,
    startingAmount,
    rollover,
    new_starting_amount
  )

  return propertyWorthEquity
}

// formula for calculating management fee
export const calculateAfterManagementFeePercentages = (
  value: number,
  management_fee_percentage: number
): number => {
  const management_fee = management_fee_percentage / 100
  return value - value * management_fee
}

// calculate future value
export const calculateFutureValue = (
  startingAmount: number,
  compoundingGrowth: number,
  contributionFrequency: number,
  numberOfYears: number,
  contributions: number
): number => {
  if (compoundingGrowth === 0) {
    return startingAmount
  }
  const n = contributionFrequency
  const t = numberOfYears
  const i = compoundingGrowth / 100 / n // Convert rate of returns to decimal
  const compoundFactor = Math.pow(1 + i, n * t)
  const principalComponent = startingAmount * compoundFactor
  const contributionComponent = contributions * ((compoundFactor - 1) / i)
  const futureValue = principalComponent + contributionComponent
  return futureValue ? futureValue : 0 // Rounded to 2 decimal places
}
// You can use this function to calculate the light pink and dark pink stack values
export const calculateSBLOCFutureValue = (
  startingAmount: any,
  rateOfReturn: any,
  numberOfYears: any,
  calculatingForReInvestedAsset: boolean = false
) => {
  let futureValues = []
  let previousValue = 0
  rateOfReturn = rateOfReturn / 100
  //rateOfReturn is rateOfReturn/100
  if (calculatingForReInvestedAsset) {
    for (let year = 0; year < numberOfYears; year++) {
      let valueForYear =
        year === 0 && calculatingForReInvestedAsset
          ? startingAmount * rateOfReturn + startingAmount
          : rateOfReturn * previousValue + previousValue
      futureValues[year] = valueForYear
      previousValue = valueForYear // Update previousValue for the next year's calculation
    }
    return futureValues
  } else {
    for (let year = 0; year < numberOfYears; year++) {
      let valueForYear = startingAmount * rateOfReturn + previousValue * (rateOfReturn + 1)
      futureValues[year] = valueForYear
      previousValue = valueForYear
    }
    return futureValues
  }
}
export const calculatePriorBalanceAndBalanceEOY = (
  startingAmount: number,
  rateOfReturn: number,
  numberOfYears: number
) => {
  rateOfReturn = rateOfReturn / 100 // Convert percentage to decimal
  let array: any = []
  let prior_balance = 0

  for (let year = 0; year < numberOfYears; year++) {
    let balanceEOY =
      (year === 0 ? startingAmount : array[year - 1].BalanceEOY) +
      rateOfReturn *
        (year === 0 ? startingAmount : 0 + (year === 0 ? 0 : array[year - 1].BalanceEOY))
    prior_balance = year === 0 ? 0 : array[year - 1].BalanceEOY
    array.push({
      BalanceEOY: balanceEOY,
      prior_balance: prior_balance,
    })
  }
  return array
}

// You can use this function to calculate the red stack values
export const calculateSBLOCLossValue = (
  borrowedAmount: any,
  annualizedRateOfReturn: any,
  numberOfYears: any,
  draw: number
) => {
  let lossValues: any = []
  let previousValue = borrowedAmount
  annualizedRateOfReturn = annualizedRateOfReturn / 100
  // Note: annualizedRateOfReturn is expected to be a decimal (e.g., 0.1 for 10%)

  for (let year = 0; year < numberOfYears; year++) {
    if (year === 0) {
      let value = -1 * (borrowedAmount + draw + annualizedRateOfReturn * (borrowedAmount + draw))
      lossValues[year] = value
      previousValue = value
    } else {
      if (draw > previousValue) {
        lossValues[year] = annualizedRateOfReturn * (previousValue - 0) + (previousValue - 0)
        previousValue = lossValues[year]
      } else {
        lossValues[year] = 0
        previousValue = 0
      }
    }
    // calculation before the draw
    // let valueForYear = previousValue * annualizedRateOfReturn + previousValue

    // lossValues[year] = -1 * valueForYear
    // previousValue = valueForYear // Update previousValue for the next year's calculation
  }
  return lossValues
}
// using this we can calculte the top labels value for the SBLOC chart
export const calculateFutureValueForSBLOCTopLabel = (
  startingAmount: any,
  gainArrayOnStartingAmount: any,
  gainArrayOnReInvestedAmount: any,
  lossArrayOnBorrowedAmount: any,
  numberOfYears: any
) => {
  let futureValuesForLabels = []

  for (let year = 0; year < numberOfYears; year++) {
    let valueForYear =
      startingAmount[year] +
      gainArrayOnStartingAmount[year] +
      gainArrayOnReInvestedAmount[year] -
      lossArrayOnBorrowedAmount[year].BalanceEOY

    futureValuesForLabels[year] = valueForYear
  }
  return futureValuesForLabels
}

// using this we can calculte the interest for the periods in sbloc
export const calculateSBLOCInterest = (cummulativeInterest: any) => {
  let interestValuesArray = []
  let previousValue = 0

  for (let i = 0; i < cummulativeInterest.length; i++) {
    let valueForYear = cummulativeInterest[i] - previousValue

    interestValuesArray[i] = valueForYear
    previousValue = previousValue + valueForYear // Update previousValue for the next year's calculation
  }
  return interestValuesArray
}
//  using this we can find the cummulative interest on borrowed amount in SBLOC
export const calculateSBLOCSummulativeBorrowedAmount = (
  startingAmount: number,
  compoundingGrowth: number,
  contributionFrequency: number,
  numberOfYears: number,
  contributions: number
) => {
  let cumulativeBorrowedAmountArray = []
  for (let i = 0; i < numberOfYears; i++) {
    let currentValue =
      calculateFutureValue(
        startingAmount,
        compoundingGrowth,
        contributionFrequency,
        i + 1,
        contributions
      ) - startingAmount
    cumulativeBorrowedAmountArray.push(currentValue)
  }
  return cumulativeBorrowedAmountArray
}
// using this we can calculte the gain for each indvidual year in SBLOC
export const calculateSBLOCGainForCurrentPeriod = (
  startingAmount: number,
  compoundingGrowth: number,
  contributionFrequency: number,
  numberOfYears: number,
  contributions: number
) => {
  let gainsForPeriodsArray = []
  let previousValue = 0

  for (let i = 1; i <= numberOfYears; i++) {
    let valueForYear =
      calculateFutureValue(
        startingAmount,
        compoundingGrowth,
        contributionFrequency,
        i,
        contributions
      ) -
      startingAmount -
      previousValue
    gainsForPeriodsArray.push(valueForYear)
    previousValue = previousValue + valueForYear
  }
  return gainsForPeriodsArray
}

export const calculateSBLOCFutureValueforBar = (
  startingAmount: any,
  rateOfReturn: any,
  year: any,
  previousValue: number,
  calculatingForReInvestedAsset: boolean = false
) => {
  let futureValue
  rateOfReturn = rateOfReturn / 100
  //rateOfReturn is rateOfReturn/100
  if (calculatingForReInvestedAsset) {
    let valueForYear =
      year === 0 && calculatingForReInvestedAsset
        ? startingAmount * rateOfReturn + startingAmount
        : rateOfReturn * previousValue + previousValue
    futureValue = valueForYear
    return futureValue
  } else {
    let valueForYear = startingAmount * rateOfReturn + previousValue * (rateOfReturn + 1)
    return valueForYear
  }
}

export const calculateSBLOCLossValueForBar = (
  borrowedAmount: any,
  annualizedRateOfReturn: any,
  previousValue: number
) => {
  annualizedRateOfReturn = annualizedRateOfReturn / 100
  // Note: annualizedRateOfReturn is expected to be a decimal (e.g., 0.1 for 10%)

  let valueForYear = previousValue * annualizedRateOfReturn + previousValue

  return valueForYear
}
export const calculateSBLOCLossValueForBarAfterDraw = (
  borrowedAmount: any,
  draw: number,
  annualizedRateOfReturn: any,
  previousValue: number,
  index: number
) => {
  if (index === 0) {
    return -1 * (borrowedAmount + draw + annualizedRateOfReturn * (borrowedAmount + draw))
  } else {
    if (draw > previousValue) {
      return annualizedRateOfReturn * (previousValue - draw) + (previousValue - draw)
    } else {
      return 0
    }
  }
}
export const calculateFutureValueForSBLOCTopLabelForEachBar = (
  startingAmount: any,
  gainAmount: any,
  reInvestedGainAmount: any,
  lossByBorrowwedAmount: any
) => {
  let valueForYear = startingAmount + gainAmount + reInvestedGainAmount - lossByBorrowwedAmount
  return valueForYear
}

export const calculateSBLOCSummulativeBorrowedAmountForEachBar = (
  startingAmount: number,
  compoundingGrowth: number,
  contributionFrequency: number,
  numberOfYears: number,
  contributions: number
) => {
  let currentValue =
    calculateFutureValue(
      startingAmount,
      compoundingGrowth,
      contributionFrequency,
      numberOfYears,
      contributions
    ) - startingAmount

  return currentValue
}
export const calculateSBLOCInterestForBar = (cummulativeInterest: any, previousValue: number) => {
  let valueForYear = cummulativeInterest - previousValue
  return {interest: valueForYear, previousValue: valueForYear + previousValue}
}
export const calculateSBLOCGainForCurrentPeriodForBar = (
  startingAmount: number,
  compoundingGrowth: number,
  contributionFrequency: number,
  numberOfYears: number,
  contributions: number,
  previousValue: number
) => {
  let valueForYear =
    calculateFutureValue(
      startingAmount,
      compoundingGrowth,
      contributionFrequency,
      numberOfYears,
      contributions
    ) -
    startingAmount -
    previousValue
  previousValue = previousValue + valueForYear

  return {gain: valueForYear, previousValue: previousValue}
}

export const calculateSBLOCCummultaiveReturns = (
  initialDeposit: any,
  annualizedRateOfReturn: any,
  numberOfYears: any
  // draw: number
) => {
  let cummulativeReturnValues: any = []
  let previousValue = 0
  annualizedRateOfReturn = annualizedRateOfReturn / 100
  // Note: annualizedRateOfReturn is expected to be a decimal (e.g., 0.1 for 10%)

  for (let year = 0; year < numberOfYears; year++) {
    if (year === 0) {
      let value = initialDeposit * (1 + annualizedRateOfReturn)
      cummulativeReturnValues[year] = value
      previousValue = value
    } else {
      cummulativeReturnValues[year] = (0 + previousValue) * (1 + annualizedRateOfReturn)
      previousValue = cummulativeReturnValues[year]
    }
  }
  return cummulativeReturnValues
}

// calculate deposit this year
export const calculateDepositsThisYear = (
  currentDeposit: number,
  previousDeposit: number,
  index: number
) => {
  return index === 0 ? currentDeposit : currentDeposit - previousDeposit
}

export const calculateSBLOCCummultaiveReturnsForEachBar = (
  initialDeposit: any,
  annualizedRateOfReturn: any,
  numberOfYear: any,
  previousValue: any
) => {
  let cummulativeReturnValues = 0
  annualizedRateOfReturn = annualizedRateOfReturn / 100
  if (numberOfYear === 0) {
    let value = initialDeposit * (1 + annualizedRateOfReturn)
    cummulativeReturnValues = value
  } else {
    cummulativeReturnValues = (initialDeposit + previousValue) * (1 + annualizedRateOfReturn)
  }
  // }
  return cummulativeReturnValues
}

export const calculateDepositForSBLOC = (initialDeposit: any, previous: any, i: any) => {
  if (i === 0) {
    return initialDeposit
  } else {
    return previous + initialDeposit
  }
}
export const calculateDepositForSBLOCForChart = (initialDeposit: any, numberOfYear: any) => {
  let deposits: any = []
  for (let year = 0; year < numberOfYear; year++) {
    deposits[year] = initialDeposit
  }
  return deposits
}

export const subtractArrays = (array1: number[], array2: number[]): number[] => {
  if (array1.length !== array2.length) {
    throw new Error('Arrays must be of the same length')
  }

  const result: number[] = []

  for (let i = 0; i < array1.length; i++) {
    result.push(array1[i] - array2[i])
  }

  return result
}
