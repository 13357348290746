import {useEffect, useRef} from 'react'
import {useSearchParams} from 'react-router-dom'
import {Button} from 'react-bootstrap'
import moment from 'moment'
import {toPng} from 'html-to-image'
import {toast} from 'react-toastify'
import MyPerformanceComponent from '../../modules/homeComponents/myPerformance/MyPerformanceComponent'
import {downloadCharts} from '../../modules/homeComponents/myPerformance/helpers/MyPerformance.helpers'
import {
  myPerformanceAssets,
  defaultAsset,
} from '../../modules/homeComponents/projections/components/ProjectionsConfig'
import {TYPES_OF_ROR} from '../../../constants/AppConstants'
import {useMyPerformanceDataState} from '../../modules/homeComponents/myPerformance/components/MyPerformanceUtils'
import {usePastPerformanceContext} from '../../context/PastPerformanceContext'
import {uploadImageToServer} from '../../modules/homeComponents/myPerformance/core/_request'

const MyPerformancePage = () => {
  const isFirstRender = useRef(true)

  const {
    chartData,
    QSPAssets,
    setQSPAssets,
    setQSPPastAssets,
    QSPDataLoading,
    isSendingEmail,
    setIsSendingEmail,
    handleSendMyPerformanceEmail,
    setLTDCustomInvestmentsArray,
  } = useMyPerformanceDataState()

  // for showing errors
  const notifyError = (msg: string) => toast.error(msg)

  // destructuring receiver form context
  const {setMyPerformanceDataArray} = usePastPerformanceContext()

  // fetcing search params
  const [searchParams] = useSearchParams()

  // helper function to safely parse a value as a number or return a default value
  const parseNumber = (value: string | null, defaultValue: number): number => {
    return value !== null ? parseFloat(value) : defaultValue
  }
  // helper function to return string if exists else return a default value=false
  const parseString = (value: string | null, defaultValue: string): string => {
    return value !== null ? value : defaultValue
  }
  // helper function to safely parse a date or return a default value

  const parseDate = (value: string | null, defaultValue: string): string => {
    let formattedDate
    if (value !== null) {
      const dateString = value
      formattedDate = moment.utc(dateString).format('YYYY-MM-DD')
    } else {
      const dateString = defaultValue
      formattedDate = moment.utc(dateString).format('YYYY-MM-DD')
    }
    // format the date to the desired format
    return formattedDate
  }
  // helper function to return boolean if exists else return a default value
  const handleBoolean = (value: string | null, defaultValue: string): boolean => {
    if (value !== null) {
      // Convert the string to a boolean using a strict comparison
      return value === 'true'
    }
    return false
  }
  // helper function to safely parse an array of numbers or return a default value
  const parseNumberArray = (value: string | null, defaultValue: number[]): number[] => {
    if (value !== null) {
      // Split the string into an array and parse each element
      return value.split(',').map((str) => parseFloat(str))
    }
    return defaultValue
  }

  // extracting query parameters from URL
  const investment_name = parseString(searchParams.get('investment_name'), 'INVESTMENT_NAME')
  const customer_name = parseString(searchParams.get('customer_name'), 'CUSTOMER_NAME')
  const starting_amount = parseNumber(searchParams.get('starting_amount'), 0)
  const contributions = parseNumber(searchParams.get('contributions'), 0)
  const contributions_frequency = parseNumber(searchParams.get('contributions_frequency'), 0)
  const rate_of_return = parseNumber(searchParams.get('rate_of_return'), 0)
  const years_to_grow = parseNumber(searchParams.get('years_to_grow'), 40)
  const ARQ_Flip_Gain = parseNumber(searchParams.get('ARQ_Flip_Gain'), 0)
  const ARQ_rental_income = parseNumber(searchParams.get('ARQ_rental_income'), 0)
  const ARQ_rental_frequency = parseNumber(searchParams.get('ARQ_rental_frequency'), 0)
  const ARQ_appreciation = parseNumber(searchParams.get('ARQ_appreciation'), 0)
  const ARQ_property_worth = parseNumber(searchParams.get('ARQ_property_worth'), 0)
  const management_fee = parseNumber(searchParams.get('management_fee'), 0)
  const is_management_fee_applied = handleBoolean(
    searchParams.get('is_management_fee_applied'),
    'false'
  )
  const actual_rental_income_last_quarter = parseNumber(
    searchParams.get('actual_rental_income_last_quarter'),
    0
  )
  const actual_rental_income_q1 = parseNumber(searchParams.get('actual_rental_income_q1'), 0)
  const actual_rental_income_q2 = parseNumber(searchParams.get('actual_rental_income_q2'), 0)
  const actual_rental_income_YTD = parseNumber(searchParams.get('actual_rental_income_YTD'), 0)
  const actual_rental_income_LTD = parseNumber(searchParams.get('actual_rental_income_LTD'), 0)
  const customer_start_date = parseDate(
    searchParams.get('customer_start_date'),
    'Tue, 17 Oct 2023 19:00:00 GMT'
  )
  const quarter_start_date = parseDate(
    searchParams.get('quarter_start_date'),
    'Tue, 17 Oct 2023 19:00:00 GMT'
  )
  const quarter_end_date = parseDate(
    searchParams.get('quarter_end_date'),
    'Tue, 17 Oct 2023 19:00:00 GMT'
  )
  const quarter_payout_date = parseDate(
    searchParams.get('quarter_payout_date'),
    'Tue, 17 Oct 2023 19:00:00 GMT'
  )
  const expected_ROR = parseNumber(searchParams.get('expected_ROR'), 0)
  const original_ROR = parseNumber(searchParams.get('original_ROR'), 0)

  const phase1_rent = parseNumber(searchParams.get('phase1_rent'), 0)
  const customer_actual = handleBoolean(searchParams.get('customer_actual'), 'false')
  const rollover = handleBoolean(searchParams.get('rollover'), 'false')
  const new_starting_amount = parseNumber(searchParams.get('new_starting_amount'), 0)
  const ARQ_Property_End_Value = parseNumber(searchParams.get('ARQ_Property_End_Value'), 0)
  const download_image = handleBoolean(searchParams.get('download_image'), 'false')
  const send_email = handleBoolean(searchParams.get('send_email'), 'false')
  const actual_equity = parseNumber(searchParams.get('actual_equity'), 0)

  const chart1 = parseNumberArray(searchParams.get('chart1'), [3, 5, 6, 7])
  const chart2 = parseNumberArray(searchParams.get('chart2'), [3, 5, 6, 7])
  const chart3 = parseNumberArray(searchParams.get('chart3'), [3, 5, 6, 7])
  const chart4 = parseNumberArray(searchParams.get('chart4'), [0, 1, 2, 3, 4])
  const custom_investment1_name = parseString(searchParams.get('custom_investment1_name'), '')
  const custom_investment1_value = parseNumber(searchParams.get('custom_investment1_value'), 0)
  const custom_investment2_name = parseString(searchParams.get('custom_investment2_name'), '')
  const custom_investment2_value = parseNumber(searchParams.get('custom_investment2_value'), 0)
  const custom_investment3_name = parseString(searchParams.get('custom_investment3_name'), '')
  const custom_investment3_value = parseNumber(searchParams.get('custom_investment3_value'), 0)
  const custom_investment4_name = parseString(searchParams.get('custom_investment4_name'), '')
  const custom_investment4_value = parseNumber(searchParams.get('custom_investment4_value'), 0)
  const actual_annualized_ytd_ror = parseNumber(searchParams.get('actual_annualized_ytd_ror'), 0)
  // creating custom obj for custom_investment
  const createNewAsset = (customAssetName: string, customAssetValue: number) => {
    const updatedAssets = myPerformanceAssets
    if (customAssetName && customAssetValue) {
      if (customAssetName.length < 3 || customAssetName.length > 25)
        return notifyError(
          `Custom investment name(${customAssetName}) must be between 3 to 25 characters`
        )
      else if (customAssetValue < -50 || customAssetValue > 100)
        return notifyError(
          `Custom investment value(${customAssetValue}) must be between -50 to 100`
        )

      // creating custom obj for custom_investment
      const newAsset = {
        id: updatedAssets.length + 1,
        label: customAssetName,
        dropdownLabel: `(${customAssetValue}%) ${customAssetName}`,
        customLabel: `${customAssetValue}%`,
        value: customAssetValue,
        color: '#B28F22', //color of label
        type: TYPES_OF_ROR.TYPE_SIMPLE,
        assetChartType: 'projections',
        annualizedROR: 0,
      }
      // add the new asset to the assets array
      updatedAssets.push(newAsset)
      setQSPAssets(updatedAssets)
      setQSPPastAssets(updatedAssets)
    }
  }

  // use the useEffect hook to update state after component has mounted
  useEffect(() => {
    // calling function for creating custom obj for custom_investment
    createNewAsset(custom_investment1_name, custom_investment1_value)
    createNewAsset(custom_investment2_name, custom_investment2_value)
    createNewAsset(custom_investment3_name, custom_investment3_value)
    createNewAsset(custom_investment4_name, custom_investment4_value)

    // set setMyPerformanceDataArray state
    setMyPerformanceDataArray({
      investment_name,
      customer_name,
      starting_amount,
      contributions,
      contributions_frequency,
      selected_rate_of_return_obj:
        QSPAssets.find((asset) => asset.id === rate_of_return) || myPerformanceAssets[defaultAsset],
      years_to_grow,
      ARQ_Flip_Gain,
      ARQ_rental_income,
      ARQ_rental_frequency,
      ARQ_appreciation,
      ARQ_property_worth,
      management_fee,
      is_management_fee_applied,
      actual_rental_income_last_quarter,
      actual_rental_income_q1,
      actual_rental_income_q2,
      actual_rental_income_YTD,
      actual_rental_income_LTD,
      customer_start_date,
      quarter_start_date,
      quarter_end_date,
      quarter_payout_date,
      expected_ROR,
      original_ROR,
      phase1_rent,
      customer_actual,
      rollover,
      new_starting_amount,
      ARQ_Property_End_Value,
      download_image,
      send_email,
      actual_equity,
      chart1,
      chart2,
      chart3,
      chart4,
      custom_investment1_name,
      custom_investment1_value,
      custom_investment2_name,
      custom_investment2_value,
      custom_investment3_name,
      custom_investment3_value,
      custom_investment4_name,
      custom_investment4_value,
      actual_annualized_ytd_ror,
    })

    // adding custom investments into an array
    const customInvestments = [
      {
        id: 9,
        custom_investment_name: custom_investment1_name,
        custom_investment_value: custom_investment1_value,
      },
      {
        id: 10,
        custom_investment_name: custom_investment2_name,
        custom_investment_value: custom_investment2_value,
      },
      {
        id: 11,
        custom_investment_name: custom_investment3_name,
        custom_investment_value: custom_investment3_value,
      },
      {
        id: 12,
        custom_investment_name: custom_investment4_name,
        custom_investment_value: custom_investment4_value,
      },
    ]

    // set LTD custom investments
    // filter custom investments included in LTD chart
    setLTDCustomInvestmentsArray(
      customInvestments.filter(
        (investment) => investment.custom_investment_name !== null && chart3.includes(investment.id)
      )
    )
  }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }

    const timer = setTimeout(() => {
      if (download_image) downloadCharts()
    }, 1000) // Delay for 1s
    return () => {
      clearTimeout(timer)
    } // Clear the timeout if the component unmounts or the dependency changes
  }, [chartData])

  // upload image to server
  const uploadMyPerformanceImage = () => {
    setIsSendingEmail(true)
    // Setting toastify success/error
    const notifyError = (msg: string) => toast.error(msg)
    const notifySuccess = (msg: string) => toast.success(msg)

    const chartContainer = document.getElementById('charts-container')

    if (chartContainer) {
      // Set the desired width
      const desiredWidth = 700

      // Calculate the scale based on the desired width
      const scale = desiredWidth / chartContainer.offsetWidth

      toPng(chartContainer, {
        cacheBust: false,
        width: desiredWidth, // Set the width directly to the desired width
        height: chartContainer.offsetHeight * scale, // Adjust height to maintain aspect ratio
        backgroundColor: 'white', // Set the background color to white
        style: {
          transform: `scale(${scale})`,
          transformOrigin: 'top left',
        },
      })
        .then((dataUrl) => {
          // Convert dataURL to Blob object
          return fetch(dataUrl).then((res) => res.blob())
        })
        .then((blob) => {
          // Create FormData object
          const formData = new FormData()
          // Append Blob as 'image' to the form data
          formData.append('image', blob, 'my-arq-performance-monthyear.png')

          // Use axios to send the FormData to the server
          return uploadImageToServer(formData)
        })
        .then((response) => {
          // Handle server response
          // notifySuccess('Image uploaded successfully')
          handleSendMyPerformanceEmail(response.data.uploadedImageName)
        })
        .catch((error) => {
          // Handle any errors here
          notifyError('An error occurred while uploading the image.')
          setIsSendingEmail(false)
        })
    } else {
      notifyError('Chart container not found.')
      setIsSendingEmail(false)
    }
  }

  return (
    <>
      {!QSPDataLoading ? (
        <>
          <section className='container container-for-qsp'>
            <Button className='custom-secondary-button my-5 mx-5' onClick={downloadCharts}>
              DOWNLOAD
            </Button>
            {send_email ? (
              <Button
                className='custom-secondary-button my-5 mx-5'
                onClick={uploadMyPerformanceImage}
                disabled={isSendingEmail}
              >
                {!isSendingEmail && <span className='indicator-label'>GENERATE EMAIL</span>}
                {isSendingEmail && (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    Sending...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </Button>
            ) : null}
            <div id='charts-container' className='container-for-qsp'>
              <MyPerformanceComponent />
            </div>
          </section>
        </>
      ) : null}
    </>
  )
}

export {MyPerformancePage}
