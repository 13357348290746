import ProjectionsChart from '../projections/components/ProjectionsChart'
import {useMyPerformanceDataState} from './components/MyPerformanceUtils'
import {MY_PERFORMANCE_SECTION} from '../../../../constants/AppConstants'
import YTDProjectionsChart from './components/YTDProjectionsChart'
import moment from 'moment'
import {usePastPerformanceContext} from '../../../context/PastPerformanceContext'
import {formatDateHelper} from '../../helper/calculator/MyPerformanceCalculator'
const MyPerformanceComponent = () => {
  // extracting chart data from useMyPerformanceDataState
  const {chartData, chartDataforLastQuarterChart, chartDataForYearToDateChart} =
    useMyPerformanceDataState()

  const {
    myPerformanceDataArray,
    setIsToggledtoShowAfterExitQuarters,
    setIncludeAppreciationToggleForLTD,
    setIncludeAppreciationToggleForProjections,
    setOppurtunityCostToggleForLTD,
    setOppurtunityCostToggleForProjections,
  } = usePastPerformanceContext()

  // get the current year
  const currentYear = moment().year()
  // calculate January 1st of the current year
  const januaryFirst = moment([currentYear, 0, 1])

  // helper function for rendering chart title
  const renderTitles = (index: number) => {
    if (index === 0)
      return (
        <>
          {' '}
          <p className='sub-heading-small-black mb-0'>{MY_PERFORMANCE_SECTION.LAST_QUARTER}</p>
          <p className='gray-text'>{`From ${formatDateHelper(
            myPerformanceDataArray.quarter_start_date
          )} to ${formatDateHelper(myPerformanceDataArray.quarter_end_date)}`}</p>
          <p className='sub-heading'>{`You got ${chartDataforLastQuarterChart[1].ROR.toFixed(
            2
          )}% (annualized) returns`}</p>
        </>
      )
    if (index === 1)
      return (
        <>
          <p className='sub-heading-small-black mb-0'>{`Year ${
            chartDataForYearToDateChart[0].startDate.split('-')[0]
          }`}</p>
          <p className='gray-text'>
            {`From ${formatDateHelper(
              chartDataForYearToDateChart[0].startDate
            )} to ${formatDateHelper(
              chartDataForYearToDateChart[chartDataForYearToDateChart.length - 1].endDate
            )}`}
          </p>
          <p className='sub-heading'>{`You got ${chartDataForYearToDateChart[
            chartDataForYearToDateChart.length - 1
          ].annualROR?.toFixed(2)}% (annualized) returns`}</p>
        </>
      )
    if (index === 2)
      return (
        <>
          <p className='sub-heading-small-black mb-0'>{MY_PERFORMANCE_SECTION.LIFE_TIME_TO_DATE}</p>
          <p className='gray-text'>{`From ${formatDateHelper(
            myPerformanceDataArray.customer_start_date
          )} to ${formatDateHelper(myPerformanceDataArray.quarter_end_date)}`}</p>
        </>
      )
    if (index === 3)
      return (
        <>
          <h1>{MY_PERFORMANCE_SECTION.Projections_TITLE}</h1>
          <p className='sub-heading-small-black'>{MY_PERFORMANCE_SECTION.NEXT_2_YEARS}</p>
        </>
      )
  }

  // handle toggle helper functions
  // ? include equity toggle for YTD chart
  const handleEquityToggle = () => {
    setIsToggledtoShowAfterExitQuarters((prev) => !prev)
  }
  // include appreciation toggle for LTD chart
  const handleAppreciationToggleForLTD = () => {
    setIncludeAppreciationToggleForLTD((prev) => !prev)
  }
  // include oppurtunity cost toggle for LTD chart
  const handleOppurtunityCostToggleForLTD = () => {
    setOppurtunityCostToggleForLTD((prev) => !prev)
  }
  // include appreciation toggle for projections chart
  const handleAppreciationToggleForProjections = () => {
    setIncludeAppreciationToggleForProjections((prev) => !prev)
  }
  // include oppurtunity cost toggle for projections chart
  const handleOppurtunityCostToggleForProjections = () => {
    setOppurtunityCostToggleForProjections((prev) => !prev)
  }

  return (
    <>
      <section className='px-7 px-md-10 px-lg-15 py-15'>
        <h1 className='text-center'>{MY_PERFORMANCE_SECTION.TITLE}</h1>
        {chartData.map((_, index) => (
          <div className='py-5 pb-15' key={index}>
            <div className='text-center mt-20'>{renderTitles(index)}</div>

            {index === 1 ? (
              <>
                <YTDProjectionsChart
                  data={chartDataForYearToDateChart}
                  handleCustomBar={null}
                  handleReply={null}
                  handleBarRateOfreturn={null}
                />
                <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center'>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    role='switch'
                    id='flexSwitchCheckDefault'
                    onClick={handleEquityToggle}
                  />
                  <label
                    className='sliding-toggle-label form-check-label ms-2'
                    htmlFor='flexSwitchCheckDefault'
                  >
                    Including equity
                  </label>
                </div>
              </>
            ) : (
              <>
                <ProjectionsChart
                  data={index === 0 ? chartDataforLastQuarterChart : chartData[index]}
                  handleCustomBar={() => console.log('handle custom bar')}
                  handleReply={() => console.log('handle reply')}
                  handleBarRateOfreturn={() => console.log('handle bar ROR')}
                />
                {/* appreciation toggle */}
                {index === 2 || index === 3 ? (
                  <div className='d-flex flex-column flex-lg-row align-items-start justify-content-lg-end'>
                    {/* appreciation toggle */}
                    <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center me-5'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckDefault'
                        defaultChecked={true}
                        onClick={
                          index === 2
                            ? handleAppreciationToggleForLTD
                            : handleAppreciationToggleForProjections
                        }
                      />
                      <label
                        className='sliding-toggle-label form-check-label ms-2'
                        htmlFor='flexSwitchCheckDefault'
                      >
                        Include property appreciation
                      </label>
                    </div>
                    {/* oppurtunity cost toggle */}
                    <div className='form-check form-switch mt-3 d-flex justify-content-end align-items-center'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckDefault'
                        defaultChecked={true}
                        onClick={
                          index === 2
                            ? handleOppurtunityCostToggleForLTD
                            : handleOppurtunityCostToggleForProjections
                        }
                      />
                      <label
                        className='sliding-toggle-label form-check-label ms-2'
                        htmlFor='flexSwitchCheckDefault'
                      >
                        Show opportunity cost of phase 1 profit
                      </label>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        ))}
      </section>
    </>
  )
}

export default MyPerformanceComponent
