import {useState, useEffect} from 'react'
import {DataRow} from './core/_model'
import {fetchSuggestedFeatures} from './core/_request'
import DataTableComponent from './components/DataTableComponent'
import Loader from '../../../components/loader/Loader'
import MessageComponent from '../../../components/message/MessageComponent'
import {useAuth} from '../../../modules/auth'

interface Props {}

const SuggestedFeatureList: React.FC<Props> = (props) => {
  const {currentUser} = useAuth()
  const [loading, setloading] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [suggestedFeatures, setSuggestedFeatures] = useState<DataRow[]>([])

  // fetching waitlist from db
  const handleFetch = async () => {
    setloading(true)
    try {
      const res = await fetchSuggestedFeatures()
      setSuggestedFeatures(res.data)
      setloading(false)
    } catch (error: Error | unknown) {
      setIsError(true)
      setloading(false)
    }
  }

  useEffect(() => {
    handleFetch()
  }, [])

  const handleShowKeywords = (loading: boolean) => {
    switch (loading) {
      case true:
        return <Loader />
      case false:
        return (
          <>
            {isError ? (
              <MessageComponent message='An error occurred. Please try again later' />
            ) : (
              <>
                <DataTableComponent suggestedFeaturesList={suggestedFeatures} />
              </>
            )}
          </>
        )
      default:
        return null
    }
  }

  return <>{handleShowKeywords(loading)}</>
}

export default SuggestedFeatureList
