import {usePastPerformanceState} from './components/PastPerformanceUtils'

const PastPerformance: React.FC = () => {
  const {pastChartData} = usePastPerformanceState()
  return (
    <>
      <p></p>
    </>
  )
}

export default PastPerformance
