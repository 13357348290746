import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import React, {useState, ChangeEvent} from 'react'
import {toast} from 'react-toastify'
import {contributionAndDividendsFrequency} from '../../projections/components/ProjectionsConfig'
import useScreenWidth from '../../../hooks/useScreenWidth'

interface CustomBarModalProps {
  show: boolean
  onHide: () => void
  inititaldataobj: {
    starting_amount: number
    ROR: number
    borrowed_amount: number
    APR: number
    reInvested_asset: number
    reInvested_ror: number
    cash_flow_that_year: number
    cash_flow_frequency: number
    year: string
  }
  title: string
  yearstogrow: number
  onSubmit: (values: any) => void
}
// form schema
const cardSchema = Yup.object().shape({
  startingAmount: Yup.number(),
  ROR: Yup.number(),
  borrowedAmount: Yup.number(),
  APR: Yup.number(),
  reInvestedAmount: Yup.number(),
  reInvestedRor: Yup.number(),
  year: Yup.string(),
  subsequentYears: Yup.boolean(),
})
const getInitialValues = (inititaldataobj: CustomBarModalProps['inititaldataobj']) => ({
  startingAmount: inititaldataobj.starting_amount,
  ROR: inititaldataobj.ROR || '',
  borrowedAmount: inititaldataobj.borrowed_amount || '',
  APR: inititaldataobj.APR || '',
  reInvestedAmount: inititaldataobj.reInvested_asset || 0,
  reInvestedRor: inititaldataobj.reInvested_ror || '',
  cash_flow_that_year: inititaldataobj.cash_flow_that_year || 0,
  cash_flow_frequency: inititaldataobj.cash_flow_frequency || 1,
  year: inititaldataobj.year || '2024',
  subsequentYears: false,
})
const generateYearOptions = (yearsToGrow: number) => {
  const date = new Date()
  const currentYear = date.getFullYear()
  const categoryOptions = []

  for (let i = 1; i <= yearsToGrow; i++) {
    const year = currentYear + i
    categoryOptions.push({value: year, label: year.toString()})
  }

  return categoryOptions
}
const SBLOCInputModal: React.FC<CustomBarModalProps> = (props) => {
  const categoryOptions = generateYearOptions(props.yearstogrow)
  const initialValues = getInitialValues(props.inititaldataobj)
  const screenWidth = useScreenWidth()
  const formik = useFormik({
    initialValues,
    validationSchema: cardSchema,
    onSubmit: async (values) => {
      props.onSubmit(values)
      props.onHide()
    },
  })
  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('cash_flow_frequency', value)
  }
  return (
    <>
      <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter' className='heading sub-heading-x-small'>
            {props.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* custom bar form */}
          <form onSubmit={formik.handleSubmit} noValidate className='form' id='customBar-form'>
            <div className='row m-0'>
              {/* startingAmount  */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Starting Amount</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.startingAmount && formik.errors.startingAmount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('startingAmount')}
                  />
                </div>
                {formik.touched.startingAmount && formik.errors.startingAmount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.startingAmount}</div>
                  </div>
                )}
              </div>
              {/* ROR */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>ROR</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.ROR && formik.errors.ROR ? 'error-border' : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('ROR')}
                  />
                </div>
                {formik.touched.ROR && formik.errors.ROR && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.ROR}</div>
                  </div>
                )}
              </div>
              {/* borrowedAmount */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Borrowed Amount</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.borrowedAmount && formik.errors.borrowedAmount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('borrowedAmount')}
                  />
                </div>
                {formik.touched.borrowedAmount && formik.errors.borrowedAmount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.borrowedAmount}</div>
                  </div>
                )}
              </div>

              {/* APR */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>APR</p>
                </div>

                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.APR && formik.errors.APR ? 'error-border' : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('APR')}
                  />
                </div>
                {formik.touched.APR && formik.errors.APR && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.APR}</div>
                  </div>
                )}
              </div>
              {/* Re Invested Amount */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Re Invested Amount</p>
                </div>

                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.reInvestedAmount && formik.errors.reInvestedAmount
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('reInvestedAmount')}
                  />
                </div>
                {formik.touched.reInvestedAmount && formik.errors.reInvestedAmount && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'> * {formik.errors.reInvestedAmount}</div>
                  </div>
                )}
              </div>

              {/* Re Invested ROR  */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Re Invested ROR</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.reInvestedRor && formik.errors.reInvestedRor
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>%</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('reInvestedRor')}
                  />
                </div>
                {formik.touched.reInvestedRor && formik.errors.reInvestedRor && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>* {formik.errors.reInvestedRor}</div>
                  </div>
                )}
              </div>
              {/* cash flow */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Cash Flow</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.cash_flow_that_year && formik.errors.cash_flow_that_year
                      ? 'error-border'
                      : ''
                  }`}
                >
                  <p className='m-0 currency-symbol'>$</p>
                  <input
                    id='bar-value'
                    type='number'
                    className='form-control form-control-lg form-control-solid'
                    placeholder=' e.g 1000'
                    {...formik.getFieldProps('cash_flow_that_year')}
                  />
                </div>
                {formik.touched.cash_flow_that_year && formik.errors.cash_flow_that_year && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>* {formik.errors.cash_flow_that_year}</div>
                  </div>
                )}
              </div>

              {/* frequency  */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='col-5 col-md-6 fv-row projections-select mb-0 mb-lg-0 pe-0 pe-md-2'>
                  <p className='ps-1 m-0 fields-label time-frame'>Time Frame</p>
                  <select
                    id='compounding-frequency'
                    className={`form-select dropdown ${screenWidth < 768 ? 'ms-n2' : ''}`}
                    {...formik.getFieldProps('cash_flow_frequency')}
                    onChange={handleCompoundingFreqChange}
                  >
                    {contributionAndDividendsFrequency.map((option) => {
                      return (
                        <option className='px-2' key={option.label} value={option.value}>
                          {option.label}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </div>

              {/* year */}
              <div className='col-12 col-md-6 fv-row mb-3 mb-lg-0 ps-0'>
                <div className='d-flex align-items-baseline'>
                  <p className='ps-1 m-0 fields-label'>Year</p>
                </div>
                <div
                  className={`input-container d-flex justify-content-center align-items-center ${
                    formik.touched.year && formik.errors.year ? 'error-border' : ''
                  }`}
                >
                  <select
                    id='year-select'
                    className='form-control form-control-lg form-control-solid form-select dropdown'
                    {...formik.getFieldProps('year')}
                  >
                    {categoryOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {formik.touched.year && formik.errors.year && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>* {formik.errors.year}</div>
                  </div>
                )}
              </div>
              {/* checkbox */}

              <div className='col-12 col-md-6 fv-row mb-lg-0 mb-3 ps-0 align-content-end'>
                <div className='d-flex align-items-center '>
                  <input
                    id='year-checkbox'
                    type='checkbox'
                    className='form-check-input'
                    {...formik.getFieldProps('subsequentYears')}
                  />
                  <p className='ps-1 my-1 fields-label'>Fill All Next Years</p>
                </div>
              </div>
            </div>
            <Modal.Footer>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-primary'
                // disabled={!formik.getFieldProps('APR').value}
              >
                <span className='indicator-progress' style={{display: 'block'}}>
                  Add
                </span>
              </button>
            </Modal.Footer>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default SBLOCInputModal
