import {BarChart, Bar, XAxis, ResponsiveContainer, Tooltip, Text, LabelList} from 'recharts'
import {toAbbreviatedNumber} from '../../../helper/charts.helper'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {projectionsColors} from '../../PastPerformance/components/PastPerformanceConfig'
import {getCategoryForText} from '../../projections/components/ProjectionsConfig'
import MyPerformanceTooltip from './MyPerformanceTooltip'
import {useEffect, useState} from 'react'

const YTDProjectionsChart: React.FC<any> = (props) => {
  const [isLoading, setIsLoading] = useState(true)

  const {data} = props
  // calculating screen width hook
  const screenWidth = useScreenWidth()

  // Preprocess data to scale bar heights
  const preprocessedData = data.map((item: any) => {
    const totalValue = item.futureValue
    const maxInitialDepositHeight = totalValue * 0.25
    const remainingHeight = totalValue - maxInitialDepositHeight

    const scaledInitialDeposit = Math.min(item.initialDeposit, maxInitialDepositHeight)
    const scaleMultiplier = remainingHeight / (totalValue - item.initialDeposit)

    return {
      ...item,
      scaledInitialDeposit,
      scaledARQ_Flip_Gain: item.ARQ_Flip_Gain * scaleMultiplier,
      scaledARQ_rental_income: item.ARQ_rental_income * scaleMultiplier,
      scaledARQ_appreciation: item.ARQ_appreciation * scaleMultiplier,
    }
  })

  // calculating chart height
  let chartPadding
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 20, right: data.length < 3 ? 20 : 20, left: data.length < 3 ? 20 : 0}
      : {
          top: 50,
          bottom: 50,
          right: data.length < 3 ? 70 : 40,
          left: data.length < 3 ? 80 : 10,
        }

  _chartHeight = screenWidth > 767 ? 340 : screenWidth > 567 ? 280 : 200
  const labelPadding = screenWidth < 776 ? 8 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width} = props
    let futureValue = data[index].futureValue
    let gain = futureValue - data[index].initialDeposit
    return (
      <Text
        x={x + width / 2}
        y={screenWidth > 767 ? y - 23 : y - 15}
        textAnchor='middle'
        className='custom-label'
        fill='#9b258f'
      >
        {`+$${toAbbreviatedNumber(parseFloat(gain.toFixed(2)))}`}
      </Text>
    )
  }

  //custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, payload, index} = props
    let quarterNo = data[index]?.label
    let annualROR = data[index]?.annualROR.toFixed(2)
    let totalROR = data[index]?.totalROR.toFixed(2)

    return (
      <>
        <Text
          x={x}
          y={y + labelPadding}
          textAnchor='middle'
          verticalAnchor='start'
          fill={getCategoryForText(payload.value)}
        >
          {quarterNo}
        </Text>
        <Text
          x={x}
          y={y + labelPadding + 20}
          width={100}
          textAnchor='middle'
          verticalAnchor='start'
          className='custom-label'
          fill={getCategoryForText(payload.value)}
        >
          {`${annualROR}%/yr`}
        </Text>
        <Text
          x={x}
          y={y + labelPadding + 40}
          width={100}
          textAnchor='middle'
          verticalAnchor='start'
          className='custom-label font-size-12'
          fill={getCategoryForText(payload.value)}
          // Adjust the font size as needed
        >
          {`(${totalROR}%/quarter)`}
        </Text>
        {/* <Text
          x={x}
          y={y + labelPadding + 60}
          width={100}
          textAnchor='middle'
          verticalAnchor='start'
          className='custom-label font-size-12'
          fill={getCategoryForText(payload.value)}
          // Adjust the font size as needed
        >
          {`Cash On Cash`}
        </Text> */}
      </>
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  return (
    <>
      {!isLoading ? (
        <ResponsiveContainer
          width='100%'
          height={_chartHeight}
          id='chart-container'
          className='box-shadow'
        >
          <BarChart
            barSize={screenWidth < 546 ? 50 : 100}
            margin={chartPadding}
            data={preprocessedData}
            style={{backgroundColor: 'white'}}
            barCategoryGap={10}
            barGap={5}
          >
            <XAxis
              dataKey='label'
              axisLine={false}
              tickLine={false}
              interval={0}
              width={100}
              minTickGap={5}
              tick={<CustomizedAxisTick />}
            />
            <Tooltip content={<MyPerformanceTooltip />} />
            <Bar
              dataKey='scaledInitialDeposit'
              stackId='assets'
              fill={projectionsColors.initialDeposit}
            />
            <Bar
              dataKey='scaledARQ_Flip_Gain'
              stackId='assets'
              fill={projectionsColors.gainOnReInvestment}
            />
            <Bar dataKey='scaledARQ_rental_income' stackId='assets' fill={projectionsColors.gain} />
            <Bar dataKey='scaledARQ_appreciation' stackId='assets' fill={projectionsColors.gain}>
              <LabelList content={<CustomizedBarTopLabel />} position='top' />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      ) : null}
    </>
  )
}
export default YTDProjectionsChart
