import React, {createContext, useState, useContext, Dispatch, SetStateAction, useMemo} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import {MyPerformanceData} from '../modules/helper/calculator/_model'
import {
  assets,
  defaultAsset,
} from '../modules/homeComponents/projections/components/ProjectionsConfig'

// past data values
type pastDateValues = {
  endDate: string
  oldDate: string
  oldValue: number
  currentValue: number
}

// calculated past investments
type CalculatedPastInvestments = {
  investment_name: string
  totalROI: number
  annualizedROR: number
  pastData: pastDateValues
}

// past performance context props
type PastPerformanceContextProps = {
  PPYearsAgo: number
  setPPYearsAgo: Dispatch<SetStateAction<number>>
  isPastDataCalculated: boolean
  setIsPastDataCalculated: Dispatch<SetStateAction<boolean>>
  pastInvestments: any
  setPastInvestments: Dispatch<SetStateAction<any>>
  calculatedPastInvestments: CalculatedPastInvestments[]
  setCalculatedPastInvestments: Dispatch<SetStateAction<CalculatedPastInvestments[]>>
  myPerformanceDataArray: MyPerformanceData
  setMyPerformanceDataArray: Dispatch<SetStateAction<MyPerformanceData>>
  // toggle for YTD chart
  isToggledtoShowAfterExitQuarters: boolean
  setIsToggledtoShowAfterExitQuarters: Dispatch<SetStateAction<boolean>>
  // toggles for LTD chart
  includeAppreciationToggleForLTD: boolean
  setIncludeAppreciationToggleForLTD: Dispatch<SetStateAction<boolean>>
  oppurtunityCostToggleForLTD: boolean
  setOppurtunityCostToggleForLTD: Dispatch<SetStateAction<boolean>>
  // toggles for projections chart
  includeAppreciationToggleForProjections: boolean
  setIncludeAppreciationToggleForProjections: Dispatch<SetStateAction<boolean>>
  oppurtunityCostToggleForProjections: boolean
  setOppurtunityCostToggleForProjections: Dispatch<SetStateAction<boolean>>
}

// default values
const initPastPerformanceContextPropsState = {
  PPYearsAgo: 20,
  setPPYearsAgo: () => {},
  isPastDataCalculated: false,
  setIsPastDataCalculated: () => {},
  pastInvestments: [],
  setPastInvestments: () => {},
  calculatedPastInvestments: [] as CalculatedPastInvestments[],
  setCalculatedPastInvestments: () => {},
  // my performance data array
  myPerformanceDataArray: {
    investment_name: 'INVESTMENT_NAME',
    customer_name: 'CUSTOMER_NAME',
    starting_amount: 100,
    contributions: 0,
    contributions_frequency: 12,
    selected_rate_of_return_obj: assets[defaultAsset],
    years_to_grow: 40,
    ARQ_Flip_Gain: 100,
    ARQ_rental_income: 100,
    ARQ_rental_frequency: 4,
    ARQ_appreciation: 0,
    ARQ_property_worth: 100,
    management_fee: 0,
    is_management_fee_applied: false,
    actual_rental_income_last_quarter: 100,
    actual_rental_income_q1: 100,
    actual_rental_income_q2: 100,
    actual_rental_income_YTD: 100,
    actual_rental_income_LTD: 100,
    customer_start_date: 'Tue, 17 Oct 2023 19:00:00 GMT',
    quarter_start_date: 'Mon, 16 Oct 2023 19:00:00 GMT',
    quarter_end_date: 'Mon, 16 Oct 2023 19:00:00 GMT',
    quarter_payout_date: 'Mon, 16 Oct 2023 19:00:00 GMT',
    expected_ROR: 5,
    original_ROR: 4,
    phase1_rent: 100,
    customer_actual: false,
    rollover: false,
    new_starting_amount: 10000,
    ARQ_Property_End_Value: 100,
    download_image: false,
    send_email: false,
    actual_equity: 0,
    chart1: [3, 5, 6, 7],
    chart2: [3, 5, 6, 7],
    chart3: [3, 5, 6, 7],
    chart4: [0, 1, 2, 3, 4],
    custom_investment1_name: '',
    custom_investment1_value: 0,
    custom_investment2_name: '',
    custom_investment2_value: 0,
    custom_investment3_name: '',
    custom_investment3_value: 0,
    custom_investment4_name: '',
    custom_investment4_value: 0,
    actual_annualized_ytd_ror: 100,
  },
  setMyPerformanceDataArray: () => {},
  isToggledtoShowAfterExitQuarters: false,
  setIsToggledtoShowAfterExitQuarters: () => {},
  includeAppreciationToggleForLTD: true,
  setIncludeAppreciationToggleForLTD: () => {},
  oppurtunityCostToggleForLTD: true,
  setOppurtunityCostToggleForLTD: () => {},
  includeAppreciationToggleForProjections: true,
  setIncludeAppreciationToggleForProjections: () => {},
  oppurtunityCostToggleForProjections: true,
  setOppurtunityCostToggleForProjections: () => {},
}

const PastPerformanceContext = createContext<PastPerformanceContextProps>(
  initPastPerformanceContextPropsState
)

const PastPerformanceProvider: React.FC<WithChildren> = React.memo(({children}) => {
  const [isPastDataCalculated, setIsPastDataCalculated] = useState<boolean>(false)
  const [PPYearsAgo, setPPYearsAgo] = useState(20)
  const [pastInvestments, setPastInvestments] = useState(
    initPastPerformanceContextPropsState.pastInvestments
  )
  // calculated past investments data
  const [calculatedPastInvestments, setCalculatedPastInvestments] = useState(
    initPastPerformanceContextPropsState.calculatedPastInvestments
  )
  // my performance data array
  const [myPerformanceDataArray, setMyPerformanceDataArray] = useState(
    initPastPerformanceContextPropsState.myPerformanceDataArray
  )
  // include equity toggle
  const [isToggledtoShowAfterExitQuarters, setIsToggledtoShowAfterExitQuarters] = useState(false)
  // include appreciation toggle for LTD chart
  const [includeAppreciationToggleForLTD, setIncludeAppreciationToggleForLTD] = useState(true)
  // include oppurtunity cost toggle for projections chart
  const [oppurtunityCostToggleForLTD, setOppurtunityCostToggleForLTD] = useState(true)
  // include appreciation toggle for projections chart
  const [includeAppreciationToggleForProjections, setIncludeAppreciationToggleForProjections] =
    useState(true)
  // include oppurtunity cost toggle for projections chart
  const [oppurtunityCostToggleForProjections, setOppurtunityCostToggleForProjections] =
    useState(true)

  // This useMemo ensures that the context value only changes when any of the context properties change
  const contextValue = useMemo(
    () => ({
      PPYearsAgo,
      setPPYearsAgo,
      isPastDataCalculated,
      setIsPastDataCalculated,
      pastInvestments,
      setPastInvestments,
      calculatedPastInvestments,
      setCalculatedPastInvestments,
      myPerformanceDataArray,
      setMyPerformanceDataArray,
      isToggledtoShowAfterExitQuarters,
      setIsToggledtoShowAfterExitQuarters,
      includeAppreciationToggleForLTD,
      setIncludeAppreciationToggleForLTD,
      oppurtunityCostToggleForLTD,
      setOppurtunityCostToggleForLTD,
      includeAppreciationToggleForProjections,
      setIncludeAppreciationToggleForProjections,
      oppurtunityCostToggleForProjections,
      setOppurtunityCostToggleForProjections,
    }),
    [
      PPYearsAgo,
      pastInvestments,
      isPastDataCalculated,
      calculatedPastInvestments,
      myPerformanceDataArray,
      isToggledtoShowAfterExitQuarters,
      includeAppreciationToggleForLTD,
      oppurtunityCostToggleForLTD,
      includeAppreciationToggleForProjections,
      oppurtunityCostToggleForProjections,
    ]
  )
  return (
    <PastPerformanceContext.Provider value={contextValue}>
      {children}
    </PastPerformanceContext.Provider>
  )
})

const usePastPerformanceContext = () => {
  const context = useContext(PastPerformanceContext)
  if (!context) {
    throw new Error('usePastPerformanceContext must be used within a PastPerformanceProvider')
  }
  return context
}

export {PastPerformanceProvider, usePastPerformanceContext}
