import React, {useEffect, useRef, useState} from 'react'
import DataTable, {TableColumn} from 'react-data-table-component'
import clipboardCopy from 'clipboard-copy'
import {Button, Modal} from 'react-bootstrap'
import {useLocation} from 'react-router-dom'
import useScreenWidth from '../../../hooks/useScreenWidth'
import {toUSDWithoutRoundOff} from '../../../helper/charts.helper'
import {SBLOC_TABLE, sectionIds} from '../../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../../context/ReceiverContext'
import EditableCell from './EditableCell'
import {projectionsColors, StressTestConfigs} from '../../projections/components/ProjectionsConfig'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {FaEdit} from 'react-icons/fa'
import FeedbackInputField from '../../feedbackSection/components/FeedbackInputField'
interface DropdownProps {
  keyId: string
}
const SBLOCTable: React.FC<{
  tableData: any[]
  onEditYear: (data: any) => void
  yearToBeEdit: number[]
  editableIndex: (index: any, isDragging: boolean) => void
  firstRowInputs: (newValue: any, keyId: any) => void
}> = ({tableData, onEditYear, yearToBeEdit, editableIndex, firstRowInputs}) => {
  const [isLoading, setIsLoading] = useState(false)
  // const [highlightedRowId, setHighlightedRowId] = useState(yearToBeEdit)
  const customName1 = localStorage.getItem('CustomName1')
  const customName2 = localStorage.getItem('CustomName2')
  const isFirstRender = useRef(true)
  if (customName1) {
    StressTestConfigs[StressTestConfigs.length - 2].label = customName1
  }
  if (customName2) {
    StressTestConfigs[StressTestConfigs.length - 1].label = customName2
  }
  const location = useLocation()
  const isSblocPage = location.pathname.slice(1) === 'sblocPage'
  const screenWidth = useScreenWidth()
  const {showInWebViewer, sectionIdToShowInWebViewer} = useReceiverFormContext()
  const [copyMessage, setCopyMessage] = useState<string | null>(null)
  const isMobileScreen = screenWidth < 576
  const isWaystoneInURL =
    location.search.includes('waystone') || location.pathname.includes('waystone')
  const [selectedRows, setSelectedRows] = useState(new Set<number>())
  const [isDragging, setIsDragging] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [showAllColumns, setShowAllColumns] = useState(false)
  let dragged = isDragging
  const [default_ROR, setDefault_ROR] = useState<string[]>([])
  const [default_APR, setDefault_APR] = useState<string[]>([])
  const [default_ReInvest_ROR, setDefault_ReInvest_ROR] = useState<string[]>([])
  useEffect(() => {
    const fetchInitialRor = async () => {
      try {
        let default_ROR = []
        let default_APR = []
        let default_ReInvest_ROR = []
        for (let i = 0; i < tableData.length; i++) {
          default_ROR.push(tableData[i].ROR)
          default_APR.push(tableData[i].APR)
          default_ReInvest_ROR.push(tableData[i].reInvested_ror)
        }
        setDefault_ROR(default_ROR)
        setDefault_APR(default_APR)
        setDefault_ReInvest_ROR(default_ReInvest_ROR)
      } catch (error) {
        console.error('Error fetching the CSV:', error)
      }
    }
    if (isFirstRender.current) {
      fetchInitialRor()
      isFirstRender.current = false
    }
    // onEditYear(tableData)
  }, [])

  const handleMouseDown = () => {
    setIsDragging(true)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseUp = () => {
    setIsDragging(false) // Stop dragging
    dragged = false
    document.removeEventListener('mouseup', handleMouseUp)
  }

  const toggleRowSelection = (index: number, isShift: boolean) => {
    const newSelection = new Set(selectedRows)
    if (newSelection.has(index) && activeIndex !== index) {
      newSelection.delete(index)
      editableIndex(index, false)
      setActiveIndex(index)
    } else {
      newSelection.add(index)
      setActiveIndex(index)
      editableIndex(index, dragged)
    }
    if (isShift) {
      handleRowClicked(index, isShift)
    }
    setSelectedRows(newSelection)
  }
  const getMatchingIndexes = () => {
    const matchingIndexes: any = []

    // Loop through each year in yearToBeEdit
    yearToBeEdit.forEach((year) => {
      // Find each index in tableData where the year matches
      tableData.forEach((data, index) => {
        if (data.year === year) {
          matchingIndexes.push(index) // Store the index if the year matches
        }
      })
    })

    return matchingIndexes
  }
  const handleCellChange = (rowIndex: number, keyId: string, newValue: string) => {
    const newData = [...tableData] // Clone the current table data
    const numericValue = parseFloat(newValue.replace(/[^0-9.-]+/g, '')) // Convert the new value to a numeric type, filtering out non-numeric characters

    // If the editableIndex array has more than one index, update all specified rows
    if (rowIndex === 0) {
      firstRowInputs(newValue, keyId)
    }
    if (newData[rowIndex][keyId] !== numericValue) {
      if (yearToBeEdit.length > 1) {
        const matchingIndexes = getMatchingIndexes()
        matchingIndexes.forEach((dataTableRowIndex: number) => {
          newData[dataTableRowIndex][keyId] = isNaN(numericValue) ? 0 : numericValue
        })
      } else {
        newData[rowIndex][keyId] = isNaN(numericValue) ? 0 : numericValue
      }
      onEditYear(newData) // Call the function to handle the updated data
    }
  }
  function getDefaultArrayToUndo(keyToUpdate: string) {
    if (keyToUpdate === 'RoR') {
      return default_ROR
    } else if (keyToUpdate === 'APR') {
      return default_APR
    } else if (keyToUpdate === 'reInvested_ror') {
      return default_ReInvest_ROR
    } else {
      return default_ROR
    }
  }
  const handleStressTestChangeValue = (label: any, keyToUpdate: string, value: string) => {
    const newData = [...tableData]
    const applyCustomArray = (localStorageKey: string) => {
      const customArrayString = localStorage.getItem(localStorageKey)
      if (customArrayString) {
        const parsedArray = cleanAndParseArray(customArrayString)
        for (let i = 0; i < tableData.length; i++) {
          newData[i][keyToUpdate] = parsedArray[i] || 0 // Set to 0 if the parsed value is undefined
        }
      } else {
        setShowModal(true)
      }
    }
    if (value === 'Given') {
      for (let i = 0; i < tableData.length; i++) {
        newData[i][keyToUpdate] = parseFloat(getDefaultArrayToUndo(keyToUpdate)[i])
      }
    } else if (value === 'Steadily Increase') {
      for (let i = 1; i < tableData.length; i++) {
        newData[i][keyToUpdate] = newData[i - 1][keyToUpdate] + 0.1
      }
    } else if (value === 'Custom 1') {
      applyCustomArray('stressTestCustomRorInputArray1')
    } else if (value === 'Custom 2') {
      applyCustomArray('stressTestCustomRorInputArray2')
    } else {
      for (let i = 0; i < tableData.length; i++) {
        newData[i][keyToUpdate] = parseFloat(value[i].replace('%', ''))
      }
    }
    onEditYear(newData)
  }

  function cleanAndParseArray(customArray: any) {
    if (!customArray) {
      return []
    }

    try {
      const parsedArray = JSON.parse(customArray)
      return parsedArray.map((item: string) => {
        // Remove commas and percentage signs, then convert to number
        const cleanedItem = item.replace(/[,%]/g, '')
        return parseFloat(cleanedItem)
      })
    } catch (error) {
      console.error('Error parsing the array:', error)
      return []
    }
  }
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const [currentKeyToUpdate, setCurrentKeyToUpdate] = useState('')
  const [selectedValue, setSelectedValue] = useState('')
  const [editableData, setEditableData] = useState(tableData)
  const dropdownRef = useRef<HTMLDivElement>(null)
  const editableRefs = useRef<(HTMLDivElement | null)[]>([])

  useEffect(() => {
    const storedRefs = localStorage.getItem('stressTestCustomRorInputArray1')
    if (storedRefs) {
      editableRefs.current = JSON.parse(storedRefs)
    } else {
      editableRefs.current = []
    }
  }, [])

  const saveChangesInCustomStressRor = (currentKeyToUpdate: string) => {
    // Get updated values from the editable divs
    const updatedData = editableRefs.current.map((ref) => ref?.innerText || '')
    if (selectedValue === 'Custom 1') {
      localStorage.setItem('stressTestCustomRorInputArray1', JSON.stringify(updatedData))
    } else {
      localStorage.setItem('stressTestCustomRorInputArray2', JSON.stringify(updatedData))
    }

    const newTableData = updatedData.map((value, index) => ({
      ...editableData[index],
      year: editableData[index].year,
      [currentKeyToUpdate]:
        parseFloat(value.replace('%', '')) || editableData[index][currentKeyToUpdate],
    }))
    setEditableData(newTableData)
    onEditYear(newTableData)
    setShowModal(false)
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault()
    const clipboardData = event.clipboardData.getData('Text')
    const rows = clipboardData.split('\n').map((row) => row.split('\t'))
    const updatedData = [...editableData]
    rows.forEach((row, rowIndex) => {
      if (rowIndex < editableRefs.current.length) {
        const value = row[0]
        const ref = editableRefs.current[rowIndex]
        if (ref) {
          ref.innerText = value
          updatedData[rowIndex].ROR =
            parseFloat(value.replace('%', '')) || updatedData[rowIndex].ROR
        }
      }
    })

    setEditableData(updatedData)
  }
  const handleFocus = (event: React.FocusEvent<HTMLDivElement>) => {
    const value = event.currentTarget.innerText
    event.currentTarget.innerText = value.replace('%', '')
  }

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>, index: number) => {
    const value = event.currentTarget.innerText
    event.currentTarget.innerText = `${value}%`
    const updatedData = [...editableData]
    updatedData[index].ROR = parseFloat(value) || editableData[index].ROR
    setEditableData(updatedData)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const charCode = event.which ? event.which : event.keyCode
    const charStr = String.fromCharCode(charCode)
    if (!charStr.match(/^[0-9.]$/) && charCode !== 8 && charCode !== 46) {
      event.preventDefault()
    }
  }

  const handleInput = (event: React.FormEvent<HTMLDivElement>) => {
    const value = event.currentTarget.innerText
    if (!value.match(/^\d*\.?\d*$/)) {
      event.currentTarget.innerText = value.slice(0, -1)
    }
  }

  const DropDown: React.FC<DropdownProps> = ({keyId}) => {
    const [showOptions, setShowOptions] = useState(false)
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowOptions(false)
      }
    }
    const handlePencilClick = (optionLabel: string) => {
      setSelectedValue(optionLabel)
      setModalContent(optionLabel)
      setShowModal(true)
    }

    const handleSelectChange = (label: string, keyToUpdate: string, value: string) => {
      setSelectedValue(label)
      handleStressTestChangeValue(label, keyToUpdate, value)
      setShowOptions(false)
    }

    const toggleOptions = (e: React.MouseEvent | React.TouchEvent) => {
      e.stopPropagation() // Ensure the event does not bubble up
      setShowOptions(!showOptions)
      setCurrentKeyToUpdate(keyId)
    }
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])
    const title = `${keyId === 'reInvested_ror' ? 'ReInvested ROR' : keyId} (${
      selectedValue || 'Given'
    })`

    return (
      <div
        className={`form-select dropdown my-3 ${screenWidth < 768 ? 'ms-n2' : ''}`}
        style={{cursor: 'pointer', position: 'static', color: 'black', border: 'solid black 1px'}}
        // onClick={toggleOptions}
        onMouseDown={toggleOptions}
        onTouchEnd={toggleOptions}
        title={title}
      >
        <div
          className='selected-value'
          style={{
            width: '100%',
            backgroundColor: 'transparent',
            color: 'black',
            // padding: '0.375rem 1.75rem 0.375rem 0.75rem',
            borderRadius: '0.25rem',
            whiteSpace: 'nowrap', // Ensure the text stays on one line
            overflow: 'hidden', // Hide overflow text
            textOverflow: 'ellipsis', // Add ellipsis if text is too long
            fontSize: '12px',
          }}
        >
          {title}
        </div>
        {showOptions && (
          <div
            className='custom-options'
            style={{
              position: 'absolute',
              width: '250%',
              backgroundColor: '#fff',
              zIndex: '2000',
              border: '1px solid #ced4da',
              borderRadius: '0.25rem',
              marginTop: '5px',
              marginRight: '20px',
              overflow: 'auto',
              maxHeight: '200px',
            }}
          >
            {StressTestConfigs.map((option) => (
              <div
                key={option.label}
                // onClick={(e) => {
                //   e.stopPropagation()
                //   handleSelectChange(option.value)
                // }}
                onMouseDown={(e) => {
                  e.stopPropagation()
                  handleSelectChange(
                    option.label,
                    keyId,
                    option.toParse ? JSON.parse(option.value) : option.value
                  )
                }}
                onTouchEnd={(e) => {
                  e.stopPropagation()
                  handleSelectChange(
                    option.label,
                    keyId,
                    option.toParse ? JSON.parse(option.value) : option.value
                  )
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '0.375rem 0.75rem',
                  cursor: 'pointer',
                  backgroundColor: selectedValue === option.label ? '#e9ecef' : '#fff',
                  color: 'black',
                  // width: '70%',
                }}
              >
                {option.label}
                {option.editable && (
                  <span
                    onMouseDown={(e) => {
                      e.stopPropagation()
                      handlePencilClick(option.value)
                    }}
                    onTouchEnd={(e) => {
                      e.stopPropagation()
                      handlePencilClick(option.value)
                    }}
                    style={{cursor: 'pointer', marginLeft: '5px', color: 'black'}}
                  >
                    ✏️
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
  const conditionalRowStyles = [
    {
      when: (row: any) => yearToBeEdit.includes(row.year),
      style: {
        backgroundColor: 'rgba(0, 255, 0, 0.2)', // Light green background using RGBA for transparency
        color: 'black',
        border: '1px solid green', // Adding a green border
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: 'rgba(0, 255, 0, 0.4)', // Darker green on hover
        },
      },
    },
  ]

  //  Assuming 'selected' is a state variable that stores the last selected index
  let lastItemToFind = yearToBeEdit[yearToBeEdit.length - 1]
  const [selected, setSelected] = useState<number>(0)
  useEffect(() => {
    setSelected(
      yearToBeEdit.length > 1
        ? tableData.findIndex((item) => item.year === lastItemToFind)
        : tableData.findIndex((item) => item.year === yearToBeEdit[0])
    )
  }, [yearToBeEdit])
  const handleRowClicked = (row: any, event: any) => {
    const currentIndex = typeof row === 'object' ? tableData.findIndex((item) => item === row) : row
    editableIndex(currentIndex, isDragging)
    if (typeof event === 'boolean' ? event : event.shiftKey && selected !== null) {
      // Calculate the range of indexes
      const start = Math.min(selected, currentIndex)
      const end = Math.max(selected, currentIndex)
      const rangeOfIndexes = []

      for (let i = start; i <= end; i++) {
        rangeOfIndexes.push(i)
      }
      setSelected(
        rangeOfIndexes.length > 1 ? rangeOfIndexes[rangeOfIndexes.length - 1] : rangeOfIndexes[0]
      )
      editableIndex([...rangeOfIndexes], isDragging)
    } else {
      // Update the selected index if Shift is not pressed
      setSelected(currentIndex)
    }
  }

  // additional columns
  const yearColumn: TableColumn<any>[] = [
    {
      name: SBLOC_TABLE.TIME_YEAR,
      id: 'sbloc-year',
      selector: (row) => row.year,
      cell: (row, index) => (
        <EditableCell
          value={row.year}
          rowIndex={index}
          keyId='year'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
    },
  ]
  const additionalColumns: TableColumn<any>[] = [
    ...(!isWaystoneInURL ? yearColumn : []),
    {
      name: SBLOC_TABLE.INITIAL_DEPOSIT,
      id: 'sbloc-initial-deposit',
      // width: '10%',
      selector: (row) => row.starting_amount,
      cell: (row, index) => (
        <EditableCell
          value={row.starting_amount !== -1 ? toUSDWithoutRoundOff(row.starting_amount) : '-'}
          rowIndex={index}
          keyId='starting_amount'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
          selectedRows={selectedRows.has(index)}
        />
      ),

      omit: isMobileScreen,
    },
    {
      name: <DropDown keyId='ROR' />,
      id: 'sbloc-ror',
      selector: (row) => row.ROR !== null && row.ROR + '%',
      cell: (row, index) => (
        <EditableCell
          value={`${row.ROR !== null && row.ROR + '%'}`}
          rowIndex={index}
          keyId='ROR'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
      // omit: isMobileScreen,
    },
    {
      name: SBLOC_TABLE.CUMMULATIVE_RETURNS,
      id: 'sbloc-cummulativeReturns',
      // width: '10%',
      selector: (row) =>
        row.cummulativeReturns !== null && toUSDWithoutRoundOff(row.cummulativeReturns),
      cell: (row, index) => (
        <EditableCell
          value={toUSDWithoutRoundOff(row.cummulativeReturns)}
          rowIndex={index}
          keyId='total-gains'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
      // omit: isMobileScreen,
    },
    {
      name: SBLOC_TABLE.BORROWED_AMOUNT,
      id: 'sbloc-total-borrowed-amount',
      // width: '10%',
      selector: (row) =>
        row.borrowed_amount !== -1 ? toUSDWithoutRoundOff(row.borrowed_amount) : '-',
      cell: (row, index) => (
        <EditableCell
          value={row.borrowed_amount !== -1 ? toUSDWithoutRoundOff(row.borrowed_amount) : '-'}
          rowIndex={index}
          keyId='borrowed_amount'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
      // omit: isMobileScreen,
    },
    {
      name: <DropDown keyId='APR' />,
      id: 'sbloc-APR',
      selector: (row) => row.APR !== null && row.APR + '%',
      cell: (row, index) => (
        <EditableCell
          value={`${row.APR !== null && row.APR + '%'}`}
          rowIndex={index}
          keyId='APR'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
      // omit: isMobileScreen,
    },
    {
      name: SBLOC_TABLE.CUMMULATIVE_DEBT,
      id: 'sbloc-estimated-total',
      // width: '10%',
      selector: (row) =>
        row.isLoss
          ? '-' + toUSDWithoutRoundOff(row.BalanceEOY)
          : toUSDWithoutRoundOff(row.BalanceEOY),
      cell: (row, index) => (
        <EditableCell
          value={
            row.isLoss
              ? '-' + toUSDWithoutRoundOff(row.BalanceEOY)
              : toUSDWithoutRoundOff(row.BalanceEOY)
          }
          rowIndex={index}
          keyId='estimated-total'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
    },
    {
      name: SBLOC_TABLE.REINVESTMENT,
      id: 'sbloc-cash-on-cash',
      // width: '10%',
      selector: !isWaystoneInURL
        ? (row) => row.reInvestedAmount !== null && row.reInvestedAmount
        : () => '',
      // selector: (row) => row.APR !== null && row.APR + '%',
      cell: (row, index) => (
        <EditableCell
          value={toUSDWithoutRoundOff(
            !isWaystoneInURL
              ? row.reInvestedAmount !== null && row.reInvestedAmount
              : row.reInvestedAmount
          )}
          rowIndex={index}
          keyId='reInvestedAmount'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
    },
    {
      name: <DropDown keyId='reInvested_ror' />,
      id: 'sbloc-roi',
      selector: (row) => row.reInvested_ror !== null && row.reInvested_ror + '%',
      cell: (row, index) => (
        <EditableCell
          value={`${row.reInvested_ror !== null && row.reInvested_ror + '%'}`}
          rowIndex={index}
          keyId='reInvested_ror'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
    },
    {
      name: SBLOC_TABLE.CUMMULATIVE_REINVESTMENT,
      id: 'sbloc-cummulativeInterest',
      selector: (row) =>
        row.cummulativeReInvestment !== null && toUSDWithoutRoundOff(row.cummulativeReInvestment),
      cell: (row, index) => (
        <EditableCell
          value={toUSDWithoutRoundOff(row.cummulativeReInvestment)}
          rowIndex={index}
          keyId='cummulativeInterest'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
    },
    {
      name: SBLOC_TABLE.CASH_FLOW,
      id: 'sbloc-cash-flow',
      selector: !isWaystoneInURL
        ? (row) => row.cash_flow_that_year !== null && row.cash_flow_that_year
        : () => '',
      cell: (row, index) => (
        <EditableCell
          value={toUSDWithoutRoundOff(
            !isWaystoneInURL
              ? row.cash_flow_that_year !== null && row.cash_flow_that_year
              : row.cash_flow_that_year
          )}
          rowIndex={index}
          keyId='cash_that_year'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
    },
    {
      name: SBLOC_TABLE.TOTAL_CASH,
      id: 'sbloc-total-cash',
      selector: !isWaystoneInURL ? (row) => row.total_cash !== null && row.total_cash : () => '',
      cell: (row, index) => (
        <EditableCell
          value={toUSDWithoutRoundOff(
            !isWaystoneInURL ? row.total_cash !== null && row.total_cash : row.total_cash
          )}
          rowIndex={index}
          keyId='total_cash'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
    },
  ]
  const columns: TableColumn<any>[] = [
    ...(isWaystoneInURL ? yearColumn : []),
    // default columns for waystone
    {
      name: 'Draw',
      id: 'sbloc-draw',
      selector: (row) => `${row.draw}`,
      cell: (row, index) => (
        <EditableCell
          value={row.draw !== -1 ? toUSDWithoutRoundOff(row.draw) : '-'}
          rowIndex={index}
          keyId='draw'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
          isNegativeAllowed={true}
        />
      ),

      // omit: isMobileScreen,
      style: {
        background: projectionsColors.loss,
        // color: projectionsColors.white,
      },
    },
    {
      name: 'Prior Balance',
      id: 'sbloc-prior-balance',
      selector: (row) => `${row.prior_balance}`,
      cell: (row, index) => (
        <EditableCell
          value={row.prior_balance !== -1 ? toUSDWithoutRoundOff(row.prior_balance) : '-'}
          rowIndex={index}
          keyId='prior_balance'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
      style: {
        background: projectionsColors.loss,
        // color: projectionsColors.white,
      },
    },
    {
      name: <DropDown keyId='APR' />,
      id: 'sbloc-int-rate',
      selector: (row) => `${row.APR !== null && row.APR + '%'}`,
      cell: (row, index) => (
        <EditableCell
          value={`${row.APR !== null && row.APR + '%'}`}
          rowIndex={index}
          keyId='APR'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={true}
        />
      ),
      // omit: isMobileScreen,
      style: {
        background: projectionsColors.loss,
        // color: projectionsColors.white,
      },
    },
    {
      name: 'Balance EOY',
      id: 'sbloc-balance-eoy',
      selector: (row) => `${row.BalanceEOY}`,
      cell: (row, index) => (
        <EditableCell
          value={row.BalanceEOY !== -1 ? toUSDWithoutRoundOff(row.BalanceEOY) : '-'}
          // value={`${(row.prior_balance * (1 + row.APR) + row.draw * (1 + row.APR)).toFixed(2)}`}
          rowIndex={index}
          keyId='year'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
      style: {
        background: projectionsColors.gain,
        color: projectionsColors.white,
      },
    },
    {
      name: 'Util',
      id: 'sbloc-util',
      selector: (row) => `${row.return_this_year.toFixed(2)}%`,
      cell: (row, index) => (
        <EditableCell
          value={`${
            (
              (row.BalanceEOY /
                (row.starting_amount + row.gainOnStartingAmount + row.total_reInvested_gains)) *
              100
            ).toFixed(2) + '%'
          }`}
          rowIndex={index}
          keyId='return_this_year'
          onChange={handleCellChange}
          dragging={isDragging}
          onToggleSelect={toggleRowSelection}
          isEditable={false}
        />
      ),
      style: {
        background: projectionsColors.gain,
        color: projectionsColors.white,
      },
    },
    ...(showAllColumns ? additionalColumns : []),
  ]
  const copyTableData = () => {
    // Generate header row from column names
    const tableText = [
      (isWaystoneInURL ? columns : additionalColumns).map((column) => column.name).join('\t'),
    ]

    // Generate each row from table data
    tableData.forEach((row) => {
      const rowData = (isWaystoneInURL ? columns : additionalColumns).map((column) => {
        // Use the selector to get the value from the row, or default to empty string
        let value = column.selector ? column.selector(row) : ''
        // Sanitize the value to replace tabs and new lines to prevent format disruptions
        value = value?.toString().replace(/\t/g, ' ').replace(/\n/g, ' ')
        // Ensure undefined values are converted to an empty string
        return value !== undefined ? value : ''
      })
      // Join all columns' values with tabs to form a complete row
      tableText.push(rowData.join('\t'))
    })
    // Copy the formatted table data to the clipboard
    if (isSblocPage) {
      window.parent.postMessage({type: 'TABLE_DATA', data: tableText}, '*')
      setCopyMessage('Table data copied to clipboard.')
      setTimeout(() => setCopyMessage(''), 3000)
    } else {
      navigator.clipboard
        .writeText(tableText.join('\n'))
        .then(() => {
          // Success: Display a success message and clear it after 3 seconds
          setCopyMessage('Table data copied to clipboard.')
          setTimeout(() => setCopyMessage(''), 3000)
        })
        .catch((error) => {
          // Error: Display a failure message and clear it after 0.5 seconds
          setCopyMessage('Copy failed. Please try again.')
          setTimeout(() => setCopyMessage(''), 500)
        })
    }
  }
  const elements = document.querySelectorAll('div[id^="cell-sbloc-"]')

  elements.forEach((element: any) => {
    element.style.padding = '0'
  })

  const getCustomNameFromLocalSTorage = () => {
    return selectedValue === 'Custom 1'
      ? localStorage.getItem('CustomName1')
      : selectedValue === 'Custom 2'
      ? localStorage.getItem('CustomName2')
      : selectedValue
  }
  const [editableTitle, setEditableTitle] = useState(getCustomNameFromLocalSTorage())
  useEffect(() => {
    setEditableTitle(getCustomNameFromLocalSTorage())
  }, [selectedValue])
  const [isEditing, setIsEditing] = useState(false)

  const handleCustomBarName = () => {
    localStorage.setItem(
      selectedValue === 'Custom 1' ? 'CustomName1' : 'CustomName2',
      editableTitle || ''
    )
    setIsEditing(false)
  }

  // Function to handle modal close
  const handleClose = () => {
    setIsEditing(false)
    setShowModal(false)
  }

  // Use effect to focus the div when it becomes editable

  return (
    <>
      {!isLoading ? (
        <div
          id={sectionIds[2]}
          className={
            sectionIdToShowInWebViewer !== sectionIds[2] && showInWebViewer ? 'd-none' : ''
          }
        >
          <div
            className='mt-3 user-select-none'
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
          >
            <div
              key={showAllColumns ? 'with-all-columns' : 'default-columns'}
              className='sbloc-data-table-custom'
            >
              <DataTable
                columns={isWaystoneInURL ? columns : additionalColumns}
                data={tableData}
                conditionalRowStyles={conditionalRowStyles}
                onRowClicked={handleRowClicked}
              />
            </div>
          </div>
          <div className='d-flex justify-content-end'>
            <div
              className='col-5 col-md-2 fv-row stress-test-dropdown mb-0 mb-lg-0 pe-0 pe-md-2 mt-5 '
              ref={dropdownRef}
            >
              <Modal show={showModal} onHide={handleClose} centered>
                <Modal.Header closeButton>
                  <Modal.Title>
                    {isEditing ? (
                      <>
                        <FeedbackInputField
                          onChange={(e) => setEditableTitle(e)}
                          onSubmit={() => handleCustomBarName()}
                          placeHolder='Enter Custom Name'
                        />
                      </>
                    ) : (
                      <span>
                        {editableTitle || selectedValue}{' '}
                        <FaEdit onClick={() => setIsEditing(true)} style={{cursor: 'pointer'}} />
                      </span>
                    )}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {editableData.map((data, index) => (
                    <div className='d-flex justify-content-center' key={index}>
                      <p
                        className='mx-5'
                        style={{
                          fontSize: '0.875rem', // Adjust the font size
                          color: 'grey', // Set the color to grey
                        }}
                      >
                        {data.year}
                      </p>

                      <div
                        contentEditable={true}
                        ref={(el) => (editableRefs.current[index] = el)}
                        onPaste={(event) => handlePaste(event)}
                        onFocus={handleFocus}
                        onBlur={(event) => handleBlur(event, index)}
                        onKeyPress={handleKeyPress}
                        onInput={handleInput}
                        style={{
                          width: '40%',
                          border: '1px solid #ced4da',
                          borderRadius: '0.25rem',
                          padding: '0.375rem',
                          marginBottom: '0.5rem',
                          justifyContent: 'center',
                        }}
                      >
                        {data.ROR.toFixed(2)}%
                      </div>
                    </div>
                  ))}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className='custom-secondary-button'
                    onClick={() => saveChangesInCustomStressRor(currentKeyToUpdate)}
                  >
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
            {copyMessage ? (
              <div>{copyMessage}</div>
            ) : (
              <div
                className={`${
                  screenWidth < 768 ? 'd-flex justify-content-center' : ''
                } mt-3 d-flex justify-content-center align-items-center`}
              >
                {isWaystoneInURL ? (
                  <div className='col-12 col-md-12 col-lg-6 d-flex justify-content-center w-100'>
                    <div className='form-check form-switch d-flex align-items-center'>
                      <input
                        className='form-check-input'
                        type='checkbox'
                        role='switch'
                        id='flexSwitchCheckDefault3'
                        onClick={() => setShowAllColumns(!showAllColumns)}
                      />
                      <label
                        className='sliding-toggle-label form-check-label ms-2'
                        htmlFor='flexSwitchCheckDefault3'
                      >
                        Show All Columns
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            <div className='ms-3 mt-5'>
              <div className='clipboard-btn p-2' onClick={copyTableData}>
                <KTSVG path='/media/icons/duotune/general/gen054.svg' className='svg-icon-2x' />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </>
  )
}

export default SBLOCTable
