import {memo, useEffect, useRef, useState} from 'react'
import useScreenWidth from '../../../hooks/useScreenWidth'
import SBLOCTable from './SBLOCTable'
import SBLOCTooltip from './SBLOCTooltip'
import {
  BarChart,
  Bar,
  XAxis,
  ResponsiveContainer,
  Tooltip,
  LabelList,
  Text,
  YAxis,
  LineChart,
  CartesianGrid,
  Legend,
  Line,
  ReferenceArea,
  Rectangle,
} from 'recharts'
import {toAbbreviatedNumber} from '../../../helper/charts.helper'
import {projectionsColors} from '../../PastPerformance/components/PastPerformanceConfig'
import {SBLOCChartProps} from '../../../helper/SBLOC/_model'
import SBLOCInputMOdal from './SBLOCInputModal'
import {Button} from 'react-bootstrap'
import {downloadChart} from '../../../helper/charts.helper'
import {handleSBLOCActions} from '../../../advisor/core/_request'
import {useSBLOCContext} from '../../../../context/SBLOCContext'

const SBLOCChart: React.FC<SBLOCChartProps> = (props) => {
  const {parentUserId} = useSBLOCContext()

  let {data: initialData} = props
  const [data, setData] = useState(initialData)
  const [isLineGraph, setIsLineGraph] = useState(false)
  const [isLevargeView, setIsLevargeView] = useState(false)
  const [editInputs, setEditInputs] = useState(false)
  const [showTable, setShowTable] = useState(false)
  const [isToolTips, setIsToolTips] = useState(false)
  const [barsToAnimate, setBarsToAnimate] = useState(true)
  const [initialDataObjForInputModal, setInitialDataObjForInputModal] = useState<any>(data[0])
  const [yearToBeEdit, setYearToBeEdit] = useState<number[]>([])
  const [referenceBarLabel, setReferenceBarLabel] = useState<number[]>([])
  const [isLoading, setisLoading] = useState<boolean>(false)
  const screenWidth = useScreenWidth()
  const isLeverageViewFirstRendered = useRef(true)
  let totalDraw = 0
  totalDraw = !isLevargeView
    ? data.reduce((sum, item) => sum + item.draw, 0)
    : initialData.reduce((sum, item, index) => sum + item.draw, 0)
  let cashForLastBarOfLevergaeView =
    initialData[initialData.length - 1].total_cash +
    initialData[initialData.length - 1].reInvestGainThisYear
  // getting values from sbloc input modal
  const handleModalValues = (values: any) => {
    props.editYear(values, data)
  }

  const [renderedData, setRenderedData] = useState<any[]>([])
  // to make the leverage View Data
  useEffect(() => {
    let leverageViewData = [initialData[initialData.length - 1]]
    if (isLevargeView) {
      let dataArray: any = []
      // dataArray.push(data[data.length-1])
      dataArray.push({
        duplicate_fv: data[data.length - 1]?.futureValue,
        topLabelValue: 22,
      })
      for (let i = 0; i < 3; i++) {
        if (i === 0) {
          const {lossByBorrowwedAmount, gainOnReInvestedAmount, ...newData} = leverageViewData[0]
          let totalAmount =
            leverageViewData[0].deposit +
            leverageViewData[0].cummulativeReturnsWithoutStartingAmount
          newData.topLabelValue = totalAmount
          newData.futureValue = totalAmount
          newData.axisLabel = 'None'
          newData.totalCash = 0
          newData.lossByBorrowedAmountAfterAddingDraw = 0
          dataArray.push(newData)
        } else if (i === 1) {
          const {gainOnReInvestedAmount, ...newData} = leverageViewData[0]
          let totalAmount =
            leverageViewData[0].deposit +
            leverageViewData[0].cummulativeReturnsWithoutStartingAmount -
            leverageViewData[0].BalanceEOY

          newData.topLabelValue = totalAmount
          newData.futureValue = totalAmount
          newData.axisLabel = 'Debt Only'
          newData.totalCash = leverageViewData[0].borrowed_amount
          // newData.lossByBorrowwedAmount = leverageViewData[0].lossByBorrowwedAmount * -1
          dataArray.push(newData)
        } else {
          leverageViewData[0].axisLabel = 'Strategic'
          leverageViewData[0].totalCash = leverageViewData[0].total_cash
          dataArray.push(leverageViewData[0])
        }
      }
      const renderBarsWithDelay = async () => {
        for (let i = 0; i < [...dataArray].length; i++) {
          await new Promise((resolve) => setTimeout(resolve, 300)) // 0.5 second delay
          setRenderedData((prevData) => [...prevData, [...dataArray][i]])
        }
      }
      if (isLeverageViewFirstRendered.current) {
        renderBarsWithDelay()
        isLeverageViewFirstRendered.current = false
      }
      setData(dataArray)
    } else {
      setData(initialData)
      isLeverageViewFirstRendered.current = true
    }
  }, [isLevargeView, initialData])

  useEffect(() => {
    setBarsToAnimate(true)
    setReferenceBarLabel([])
    setYearToBeEdit([])
  }, [data])
  // calculating chart height
  let chartPadding
  let _chartHeight
  // calculating chart padding
  chartPadding =
    screenWidth < 546
      ? {top: 50, bottom: 40, right: 30, left: -30}
      : {
          top: props.isWayStoneStyled ? 20 : 80,
          bottom: props.isWayStoneStyled ? 50 : 60,
          right: 40,
          left: -30,
        }

  _chartHeight = screenWidth > 767 ? 410 : screenWidth > 567 ? 250 : 200
  const labelPadding = screenWidth < 776 ? 8 : 0
  const labelTextAngle = screenWidth < 776 ? -30 : 0

  // customized bar top label
  const CustomizedBarTopLabel = (props: any) => {
    const {x, y, index, width, payload} = props
    // console.log('data: ', data)
    const futureValue =
      isLevargeView && index === 0 ? '' : data[index]?.topLabelValue?.toFixed(2) || ''
    const gain = '$' + toAbbreviatedNumber(parseFloat(futureValue))
    const onClickLabel = () => {
      handleBarOnClick(data[index], index) // Call the handler with the correct data
    }
    return (
      <Text
        x={!isLineGraph ? x + width / 2 : x}
        y={!isLineGraph ? (screenWidth > 767 ? y - 35 : y - 15) : y - 10}
        textAnchor='middle'
        className='custom-label'
        fill='#9b258f'
        angle={data.length <= 10 ? 0 : 270}
        onClick={onClickLabel}
      >
        {isLevargeView && index === 0 ? 'Wealth' : gain}
      </Text>
    )
  }

  // custom bottom labels of the chart
  const CustomizedAxisTick = (props: any) => {
    const {x, y, index} = props
    let date = new Date()
    return (
      <>
        {!isLevargeView && (
          <Text
            x={x}
            y={y + labelPadding}
            width={90}
            textAnchor='middle'
            verticalAnchor='start'
            angle={labelTextAngle}
            className='custom-tick-label'
          >
            {data[index]?.year}
          </Text>
        )}

        {isLevargeView && (
          <>
            <Text
              x={x}
              y={y + labelPadding + (screenWidth > 748 ? 20 : 15)}
              width={90}
              textAnchor='middle'
              verticalAnchor='start'
              // angle={labelTextAngle}
              style={{color: 'black'}}
            >
              {data[index]?.axisLabel || ''}
            </Text>
            <Text
              x={x}
              y={y + labelPadding + (screenWidth > 748 ? 45 : 35)}
              width={90}
              textAnchor='middle'
              verticalAnchor='start'
              // angle={labelTextAngle}
              style={{color: 'red'}}
              fill='red'
            >
              {isLevargeView && index === 0
                ? 'Cash'
                : isLevargeView && index === 1
                ? '$0'
                : isLevargeView && index === 2
                ? `$${toAbbreviatedNumber(totalDraw)}`
                : `$${toAbbreviatedNumber(cashForLastBarOfLevergaeView)}`}
            </Text>
          </>
        )}
      </>
    )
  }
  useEffect(() => {
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    })
  }, [data])
  const hightLightEditableBars = (payload: any, index: number) => {
    // Check if the year is already included in the yearToBeEdit array
    if (yearToBeEdit.includes(payload.year) || referenceBarLabel.includes(payload.year)) {
      // If it is included, remove it
      const newYearToBeEdit = yearToBeEdit.filter((year) => year !== payload.year)
      const newReferenceLabels = referenceBarLabel.filter((year) => year !== payload.year)
      setYearToBeEdit(newYearToBeEdit)
      setReferenceBarLabel(newReferenceLabels)
    } else {
      // If it's not included, add it
      setYearToBeEdit([...yearToBeEdit, payload.year])
      setReferenceBarLabel([...referenceBarLabel, data[index].year])
    }
  }

  const handleBarOnClick = (payload: any, index: number) => {
    setBarsToAnimate(false)
    if (!referenceBarLabel.includes(data[index].year) && !showTable) {
      setReferenceBarLabel([data[index].year])
      setYearToBeEdit([data[index].year])
    }
    if (showTable) {
      hightLightEditableBars(payload, index)
    } else {
      setEditInputs(true)
      setInitialDataObjForInputModal(data[index])
    }
  }
  const handleEditAbleIndexFromTable = (selectedIndexes: any, isDragging: boolean) => {
    setBarsToAnimate(false)
    if (selectedIndexes.length > 1) {
      let years: any = []
      selectedIndexes.forEach((item: any) => {
        years.push(data[item].year)
      })
      setYearToBeEdit([...years])
      setReferenceBarLabel(years)
    } else {
      if (yearToBeEdit.includes(data[selectedIndexes].year) && !isDragging) {
        // If it is included, remove it
        const newYearToBeEdit = yearToBeEdit.filter((year) => year !== data[selectedIndexes].year)
        const newReferenceLabels = referenceBarLabel.filter(
          (year) => year !== data[selectedIndexes].year
        )
        setYearToBeEdit(newYearToBeEdit)
        setReferenceBarLabel(newReferenceLabels)
      } else {
        // If it's not included, add it
        setYearToBeEdit([...yearToBeEdit, data[selectedIndexes].year])
        setReferenceBarLabel([...referenceBarLabel, data[selectedIndexes].year])
      }
    }
  }
  const handleIconClick = () => {
    const getLinkButton = document.getElementById('getLinkButton')
    if (getLinkButton) {
      getLinkButton.click()
    }
  }

  // Define the type for the keys you expect in the radiusConfig
  type DataKey = 'deposit' | 'lossByBorrowedAmountAfterAddingDraw' | 'cummulativeReInvestment'
  // Define the type for the radius array
  type RadiusArray = number[]
  function determineRadius(dataKey: DataKey, value: number): RadiusArray {
    const radiusConfig: Record<DataKey, RadiusArray> = {
      deposit: value > 0 ? [0, 0, 5, 5] : [0, 0, 0, 0],
      lossByBorrowedAmountAfterAddingDraw: value < 0 ? [0, 0, 5, 5] : [0, 0, 0, 0],
      cummulativeReInvestment: value > 0 ? [5, 5, 0, 0] : [0, 0, 0, 0],
    }
    return radiusConfig[dataKey] || [0, 0, 0, 0]
  }
  const CustomBar = (props: any) => {
    const {fill, x, y, width, height, payload} = props
    return (
      // eslint-disable-next-line react/jsx-no-undef
      <>
        <Rectangle
          {...props}
          fill={fill}
          stroke='#000' // Border color
          strokeWidth={1} // Border width
          radius={determineRadius(
            props?.tooltipPayload[0]?.dataKey,
            payload?.[props?.tooltipPayload[0]?.dataKey]
          )}
        />
      </>
    )
  }
  const iconStyle = {
    fontSize: '1.5rem', // Make the icons bigger
    color: '#7c1e72', // Dark purple color
    cursor: 'pointer',
    marginBottom: '1rem', // Add spacing between icons
  }

  const handleLeverageView = () => {
    let i = 0
    if (!isLevargeView) {
      setIsLevargeView(true)
      return
    } else if (isLevargeView) {
      const popBarsWithDelay = async () => {
        // Loop over the array while there are elements
        while (i < 3) {
          await new Promise((resolve) => setTimeout(resolve, 300)) // 300 ms delay
          setRenderedData((prevData) => {
            // Create a copy of the array to safely modify it
            const newData = [...prevData]
            newData.pop() // Remove the last element
            return newData // Return the modified copy
          })
          i++
        }
      }
      popBarsWithDelay()

      setTimeout(() => {
        if (isLevargeView) setIsLevargeView(!isLevargeView)
        setRenderedData([])
        setData([])
      }, 1200)
    }
  }

  return (
    <>
      <div
        className={`${
          props.isWayStoneStyled ? '' : 'pt-20 pt-8 pt-7 '
        }px-md-10 px-lg-20 user-select-none`}
      >
        {!props.isWayStoneStyled && (
          <div
            style={{
              textAlign: 'center',
              width: '100%',
              backgroundColor: props.isWayStoneStyled ? '#d0cfcd' : 'white',
            }}
          >
            {isLevargeView ? (
              <h4 style={{color: 'black'}} className='mb-0 pt-10'>
                SBLOC Leverage...
              </h4>
            ) : (
              <>
                <div className='p-3 px-5 d-none d-md-block'>
                  {/* download chart image button */}
                  <Button
                    className='mx-5 custom-primary-button'
                    onClick={() => setEditInputs(true)}
                  >
                    Edit Year
                  </Button>
                  {/* download chart image button */}
                  <Button className='custom-primary-button' onClick={() => props.undo()}>
                    Undo
                  </Button>
                  <Button
                    className='mx-5 custom-primary-button'
                    onClick={() => downloadChart('sbloc-chart-container', 'Vivid Projection.png')}
                  >
                    Download
                  </Button>
                </div>
                <div className='p-3 px-5 d-none d-md-block'></div>
              </>
            )}
          </div>
        )}

        {!isLoading && (
          <div className='d-flex align-items-center'>
            <ResponsiveContainer width='100%' height={400} id='sbloc-chart-container'>
              {!isLineGraph ? (
                <BarChart
                  margin={chartPadding}
                  data={isLevargeView ? renderedData : data}
                  style={{backgroundColor: props.isWayStoneStyled ? '#d0cfcd' : 'white'}}
                  barCategoryGap={isLevargeView ? '10%' : '20%'} // Adjust these values as needed
                  barGap={0} // Adjust this value to bring bars closer together
                >
                  <XAxis
                    dataKey='year'
                    axisLine={false}
                    tickLine={false}
                    interval={0}
                    width={100}
                    minTickGap={5}
                    tick={<CustomizedAxisTick />}
                  />
                  {referenceBarLabel.map((line, index) => (
                    <ReferenceArea
                      x1={line} // Starting year (ensure it matches an actual data point)
                      x2={line} // Ending year (ensure it matches an actual data point)
                      stroke='green'
                      fill='green'
                      fillOpacity={0.3}
                      yAxisId='left'
                    />
                  ))}

                  <YAxis
                    yAxisId='left'
                    allowDataOverflow={true}
                    tick={false}
                    axisLine={false}
                    tickLine={false}
                    domain={[
                      (dataMin: number) => 0 - Math.abs(dataMin),
                      (dataMax: number) => dataMax * 1.5, // Increase dataMax by 10%
                    ]}
                  />
                  {/* tooltip */}
                  {isToolTips && (
                    <Tooltip contentStyle={{backgroundColor: 'white'}} content={<SBLOCTooltip />} />
                  )}
                  {/* bars */}
                  {/* maroon bar */}
                  <Bar
                    dataKey='duplicate_fv'
                    stackId='assets'
                    barSize={70}
                    yAxisId='left'
                    fill={'transparent'}
                    onClick={handleBarOnClick}
                    isAnimationActive={barsToAnimate}
                  />
                  <Bar
                    dataKey='deposit'
                    stackId='assets'
                    barSize={70}
                    yAxisId='left'
                    fill={projectionsColors.initialDeposit}
                    onClick={handleBarOnClick}
                    isAnimationActive={barsToAnimate}
                    shape={<CustomBar />} // Custom shape
                  />
                  <Bar
                    //light pink
                    barSize={70}
                    dataKey='cummulativeReturnsWithoutStartingAmount'
                    stackId='assets'
                    fill={projectionsColors.gain}
                    yAxisId='left'
                    onClick={handleBarOnClick}
                    isAnimationActive={barsToAnimate}
                    shape={<CustomBar />} // Custom shape
                  ></Bar>
                  {/* purple */}
                  <Bar
                    dataKey='cummulativeReInvestment'
                    stackId='assets'
                    barSize={70}
                    yAxisId='left'
                    fill={projectionsColors.gainOnReInvestment}
                    onClick={handleBarOnClick}
                    isAnimationActive={barsToAnimate}
                    shape={<CustomBar />} // Custom shape
                  >
                    {data.length <= 20 ? (
                      <LabelList
                        content={<CustomizedBarTopLabel />}
                        position='top'
                        key={new Date().getTime()}
                      />
                    ) : null}
                  </Bar>

                  {/* red */}
                  <Bar
                    barSize={70}
                    dataKey={'lossByBorrowedAmountAfterAddingDraw'}
                    stackId='assets'
                    fill={projectionsColors.borrow}
                    yAxisId='left'
                    onClick={handleBarOnClick}
                    isAnimationActive={barsToAnimate}
                    shape={<CustomBar />} // Custom shape
                  ></Bar>
                </BarChart>
              ) : (
                <LineChart
                  width={800}
                  height={250}
                  data={data}
                  margin={{top: 20, right: 30, left: 10, bottom: 5}}
                  style={{backgroundColor: 'white'}}
                >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='year' />
                  <YAxis tickFormatter={toAbbreviatedNumber} />{' '}
                  {/* Hiding YAxis as we're displaying data on the lines */}
                  {/* <Tooltip /> */}
                  {isToolTips && (
                    <Tooltip contentStyle={{backgroundColor: 'white'}} content={<SBLOCTooltip />} />
                  )}
                  {/* <Legend /> */}
                  {/* Line for future value */}
                  <Line
                    type='monotone'
                    dataKey='growthForLineGraph'
                    stroke={projectionsColors.gainOnReInvestment}
                    strokeWidth={4}
                    name='Gains'
                  />
                  <Line
                    type='monotone'
                    dataKey='BalanceEOY'
                    stroke={projectionsColors.borrow}
                    strokeWidth={4}
                    name='Debt'
                  />
                  <Line
                    type='monotone'
                    dataKey='futureValue'
                    stroke='black'
                    strokeWidth={1} // Adjust line thickness here
                    name='Net'
                  >
                    <LabelList content={<CustomizedBarTopLabel />} position='top' />
                  </Line>
                  <Legend />
                </LineChart>
              )}
            </ResponsiveContainer>
            {props.isWayStoneStyled && (
              <div className='d-flex flex-column align-items-center ps-6'>
                <i
                  className='fas fa-undo'
                  style={iconStyle}
                  onClick={() => {
                    props.undo()
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-download'
                  style={iconStyle}
                  onClick={() => {
                    downloadChart('sbloc-chart-container', 'Vivid Projection.png')
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-cog'
                  style={iconStyle}
                  onClick={() => {
                    setEditInputs(true)
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
                <i
                  className='fas fa-share'
                  style={iconStyle}
                  onClick={() => {
                    handleIconClick()
                    handleSBLOCActions(parentUserId)
                  }}
                ></i>
              </div>
            )}
          </div>
        )}
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault1'
                  onClick={() => {
                    setShowTable(!showTable)
                    !showTable && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault1'
                >
                  Show Table
                </label>
              </div>
            </div>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault2'
                  onClick={() => {
                    setIsLineGraph(!isLineGraph)
                    !isLineGraph && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault2'
                >
                  Line Graph
                </label>
              </div>
            </div>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault3'
                  onClick={() => {
                    handleLeverageView()
                    !isLevargeView && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault3'
                >
                  Leverage View
                </label>
              </div>
            </div>
            <div className='col-6 col-lg-3 mt-3 d-flex justify-content-center'>
              <div className='form-check form-switch d-flex align-items-center'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  role='switch'
                  id='flexSwitchCheckDefault3'
                  onClick={() => {
                    setIsToolTips(!isToolTips)
                    !isToolTips && handleSBLOCActions(parentUserId)
                  }}
                />
                <label
                  className='sliding-toggle-label form-check-label ms-2'
                  htmlFor='flexSwitchCheckDefault3'
                >
                  Rollovers
                </label>
              </div>
            </div>
          </div>
        </div>
        {showTable ? (
          <div className={`${isToolTips ? 'mt-100' : ''}`}>
            <SBLOCTable
              tableData={props.tableData}
              onEditYear={props.editYearForTable}
              yearToBeEdit={yearToBeEdit}
              editableIndex={handleEditAbleIndexFromTable}
              firstRowInputs={props.handleChangeFirstRowInputs}
            />
          </div>
        ) : null}
        {editInputs ? (
          <SBLOCInputMOdal
            show={editInputs}
            onHide={() => setEditInputs(false)}
            title={'Edit Inputs'}
            inititaldataobj={initialDataObjForInputModal}
            yearstogrow={initialDataObjForInputModal.years_to_grow}
            onSubmit={handleModalValues}
          />
        ) : null}
      </div>
    </>
  )
}

export default memo(SBLOCChart)
