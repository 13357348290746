import {useState, useRef, useEffect} from 'react'
import {toast} from 'react-toastify'
import {useFormik} from 'formik'
import {useParams, useLocation} from 'react-router-dom'
import * as Yup from 'yup'
import {
  assets,
  defaultAsset,
  typesOfRoR,
} from '../../homeComponents/projections/components/ProjectionsConfig'
import {AdvisorsFromModel, WorthData} from './_model'
import {calculateAdvisorFutureValue} from '../../helper/calculator/InvestmentCalculator'
import {
  fetchAdvisorFormData,
  submitAdvisorForm,
  updateAdvisorForm,
  uploadImageToServer,
} from '../core/_request'
import {useAdvisorFormContext} from '../../../context/AdvisorContext'
import {TYPES_OF_ROR} from '../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import {CompoundingGrowth} from '../../helper/calculator/_model'

export function useAdvisorsState() {
  // extracting useparams
  const {receiver_url} = useParams()
  const location = useLocation()
  // destructuring useReceiverFormContext hook
  const {setReceiverFormDataObj, receiverFormDataObj, setIsReceiverDataLoading} =
    useReceiverFormContext()
  // destructuring useAdvisorFormContext hook
  const {setIsFormUploading, setAdvisorURL, advisorURL, setNotes, setIsAdvisorLogoLoading} =
    useAdvisorFormContext()

  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  // advisor form schema
  const AdvisorsFormSchema = Yup.object().shape({
    starting_amount: Yup.number()
      .min(0, 'Please enter an initial amount greater than or equal to $0')
      .max(10000000, 'Please enter an initial amount less than or equal to $10,000,000')
      .typeError('Please enter an initial amount greater than or equal to $0')
      .required('Please enter the starting amount'),
    contributions: Yup.number()
      .min(0, 'Please enter contributions greater than or equal to $0')
      .max(500000, 'Please enter contributions less than or equal to $500,000')
      .typeError('Please enter contributions greater than or equal to $0')
      .required('Please enter the contributions'),
    contributions_frequency: Yup.number().required('Please select the contribution frequency'),
    rate_of_return: Yup.number().required('Please select the rate of return'),
    isCustomField: Yup.boolean(),
    custom_investment_values: Yup.array().of(
      Yup.object().shape({
        id: Yup.number(),
        type_rate_of_return: Yup.string(),
        investment_name: Yup.string().when('type_rate_of_return', {
          is: (type: string) => type,
          then: Yup.string().max(25).required('required field'),
        }),
        investment_value: Yup.number().when('type_rate_of_return', {
          is: (type: string) => type === 'Simple RoR',
          then: Yup.number()
            .notOneOf([0], 'Value cannot be 0')
            .min(-50)
            .max(100)
            .required('required field'),
        }),
      })
    ),
    years_to_grow: Yup.number()
      .min(1, 'Please enter a number of years greater than or equal to 1')
      .max(100, 'Please enter a number of years less than or equal to 100')
      .required('Please enter the number of years to grow'),
    isSharing: Yup.boolean(),
    greetings: Yup.string().when('isSharing', {
      is: (share: boolean) => share,
      then: Yup.string().min(4).max(255).required(),
    }),
    logo_url: Yup.string().when('isSharing', {
      is: (share: boolean) => share,
      then: Yup.string().required(),
    }),
    custom_email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is required'),
    ARQ_Flip_Gain: Yup.number()
      .min(0, 'Please enter a flip gain greater than or equal to $0')
      .max(10000000, 'Please enter gain profit amount less than or equal to $10,000,000')
      .typeError('Please enter an initial amount greater than or equal to $0'),
    ARQ_rental_income: Yup.number()
      .min(0, 'Please enter rental income greater than or equal to $0')
      .max(10000000, 'Please enter rental income less than or equal to $10,000,000')
      .typeError('Please enter an initial amount greater than or equal to $0'),
    ARQ_rental_contribution: Yup.number().required('Please select the contribution frequency'),
    ARQ_appreciation: Yup.number()
      .min(0, 'Please enter an initial amount greater than or equal to $0')
      .max(10000000, 'Please enter appreciation less than or equal to $10,000,000')
      .typeError('Please enter an initial amount greater than or equal to $0'),
    ARQ_property_worth: Yup.number()
      .min(0, "Please enter a property's worth greater than or equal to $0")
      .max(10000000, "Please enter a property's worth greater than or equal to $10,000,000")
      .typeError("Please enter a property's worth greater than or equal to $0"),
    notes: Yup.string(),
    management_fee: Yup.number()
      .min(0, "Please enter management fee's value greater than or equal to 0%")
      .max(100, "Please enter management fee's value greater than or equal to 100%"),
  })

  // Define the initial form values
  const initialValues: AdvisorsFromModel = {
    starting_amount: receiverFormDataObj.starting_amount,
    contributions: receiverFormDataObj.contributions,
    contributions_frequency: receiverFormDataObj.contributions_frequency,
    rate_of_return: receiverFormDataObj.selected_rate_of_return_obj.id,
    custom_investment_values: [],
    years_to_grow: receiverFormDataObj.years_to_grow,
    greetings: '',
    logo_url: '',
    custom_email: 'aniq@arqventures.com',
    isSharing: false,
    ARQ_Flip_Gain: 200000,
    ARQ_rental_income: 20000,
    ARQ_rental_contribution: 4,
    ARQ_appreciation: 0,
    ARQ_property_worth: 1000000,
    notes: '',
    management_fee: 20,
    selected_bars: [],
  }

  // useState hook for managing assets state
  const [hasCalledAddLegacyAssets, setHasCalledAddLegacyAssets] = useState(false)
  const [assetsState, setAssetsState] = useState(assets.filter((asset) => asset.label !== 'Custom')) // removing custom obj from assets hook as it is not required here
  const [selectedRate, setSelectedRate] = useState<CompoundingGrowth>(
    receiverFormDataObj.selected_rate_of_return_obj
  )
  const [worthResult, setWorthResult] = useState<WorthData>()
  // clipboard state
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    setSelectedRate(receiverFormDataObj.selected_rate_of_return_obj)
  }, [receiverFormDataObj])

  // setting isFirstRender to true to avoid form submission on first render
  const isFirstRender = useRef(true)

  const getResult = (values: AdvisorsFromModel) => {
    const _worthResult = calculateAdvisorFutureValue(
      values.starting_amount,
      selectedRate,
      values.contributions,
      values.contributions_frequency,
      values.years_to_grow,
      values.ARQ_Flip_Gain,
      values.ARQ_rental_income,
      values.ARQ_rental_contribution,
      values.ARQ_appreciation,
      values.ARQ_property_worth,
      values.management_fee
    )
    setWorthResult(_worthResult)
  }
  // calculating future value initially to show on arq/advisor form page
  useEffect(() => {
    getResult(initialValues)
  }, [])

  // handle form submit
  const handleSubmit = async (values: AdvisorsFromModel) => {
    let res
    // calculating future value

    getResult(values)
    // calling submitAdvisorForm API only if greetings field and isSharing is true
    // (RE: isSharing it is a boolean state for managing Generate button -> it will be true only if user clicks on Generate button)
    if (values?.greetings && values.isSharing) {
      setIsFormUploading(true)
      try {
        // checking if advisor URL and receiver_url are false than calling submitAdvisorForm API
        if (!advisorURL && !receiver_url) {
          // handle submit advisor form
          res = await submitAdvisorForm(
            values.starting_amount,
            values.contributions,
            values.contributions_frequency,
            values.rate_of_return,
            values.custom_investment_values,
            values.years_to_grow,
            values.greetings,
            values.logo_url,
            values.custom_email,
            values.ARQ_Flip_Gain,
            values.ARQ_rental_income,
            values.ARQ_rental_contribution,
            values.ARQ_appreciation,
            values.ARQ_property_worth,
            values.notes,
            values.management_fee,
            location.pathname.includes('arq/create')
              ? 'arq'
              : location.pathname.includes('advisor-demo1/create')
              ? 'advisor-demo1'
              : location.pathname.includes('advisor-demo2/create')
              ? 'advisor-demo2'
              : location.pathname.includes('waystone/create')
              ? 'waystone'
              : null,
            // values.selectedBars
            receiverFormDataObj.selected_bars
          )
        } else {
          // checking if advisor URL and receiver_url are true than calling updateAdvisorForm API
          // shaping URL removing the string before arq to get the slug for API calling

          // const relativeUrl = advisorURL?.substring(advisorURL?.indexOf('arq/') + 4)

          const relativeUrl = location.pathname.includes('arq/create')
            ? advisorURL?.substring(advisorURL?.indexOf('arq/') + 4)
            : location.pathname.includes('advisor-demo1/create')
            ? advisorURL?.substring(advisorURL?.indexOf('advisor-demo1/') + 14)
            : location.pathname.includes('advisor-demo2/create')
            ? advisorURL?.substring(advisorURL?.indexOf('advisor-demo2/') + 14)
            : location.pathname.includes('waystone/create')
            ? advisorURL?.substring(advisorURL?.indexOf('waystone/') + 9)
            : null

          const uniqueURL = receiver_url ? receiver_url : relativeUrl

          // handle update advisor form
          res = await updateAdvisorForm(
            values.starting_amount,
            values.contributions,
            values.contributions_frequency,
            values.rate_of_return,
            values.custom_investment_values,
            values.years_to_grow,
            values.greetings,
            values.logo_url,
            values.custom_email,
            values.ARQ_Flip_Gain,
            values.ARQ_rental_income,
            values.ARQ_rental_contribution,
            values.ARQ_appreciation,
            values.ARQ_property_worth,
            uniqueURL,
            values.notes,
            values.management_fee,
            location.pathname.includes('arq/create')
              ? 'arq'
              : location.pathname.includes('advisor-demo1/create')
              ? 'advisor-demo1'
              : location.pathname.includes('advisor-demo2/create')
              ? 'advisor-demo2'
              : location.pathname.includes('waystone/create')
              ? 'waystone'
              : null,
            receiverFormDataObj.selected_bars
          )
        }
        // setting setAdvisorURL state to the response.data.URL
        setAdvisorURL(res?.data?.URL)
        setNotes(res?.data?.advisor_notes)
        setIsFormUploading(false)
        formik.setFieldValue('isSharing', false)
      } catch (error: any) {
        if (error?.response?.data) {
          notifyError(error?.response?.data?.message)
        } else {
          notifyError('Error occurred during the request.')
        }
        setIsFormUploading(false)
        formik.setFieldValue('isSharing', false)
      }
      formik.setFieldValue('isSharing', false)
    }
  }
  // handle image upload
  const HandleFetchOrUploadImage = async (file: any) => {
    // appending image to FormData
    const formDataImage = new FormData()

    // if image selected
    if (file) {
      formDataImage.append('image', file)

      setIsAdvisorLogoLoading(true)
      // uploading image to server
      try {
        const res = await uploadImageToServer(formDataImage)
        formik.setFieldValue('logo_url', res?.data[0])
        setIsAdvisorLogoLoading(false)
        return res?.data[0]
      } catch (error: any) {
        if (error?.response?.data) {
          notifyError(error?.response?.data?.message)
        } else {
          notifyError('Error occurred during the request.')
        }
        setIsAdvisorLogoLoading(false)
      }
    }
  }

  const formik = useFormik<AdvisorsFromModel>({
    initialValues,
    enableReinitialize: true,
    validationSchema: AdvisorsFormSchema,
    onSubmit: handleSubmit,
  })
  useEffect(() => {
    if (!hasCalledAddLegacyAssets && formik.values.custom_investment_values.length === 1) {
      addLegacyAssets()
      // Set the state variable to true to prevent further calls
      setHasCalledAddLegacyAssets(true)
    }

    if (formik.values.custom_investment_values.length === 0) {
      const updatedAssets = assetsState.filter(
        (asset) =>
          asset.label !== 'ARQ Fix & Flip (Legacy)' && asset.label !== 'ARQ Rental (Legacy)'
      )

      // Set the assets state with the updated array
      setAssetsState(updatedAssets)
      setHasCalledAddLegacyAssets(false)
    }
  }, [formik.values.custom_investment_values])

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault()
    }
  }

  // handle change RoR/compounding growth
  const handleCompoundingFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('contributions_frequency', value)
  }

  // handle change handleARQRentalFreqChange
  const handleARQRentalFreqChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('ARQ_rental_contribution', value)
  }

  // handle asset change -> RoR dropdown
  const handleAssetChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value // Use the selected id
    const selectedOptionData = assetsState.find((option) => option.id.toString() === selectedId)

    if (selectedOptionData) {
      setSelectedRate(selectedOptionData)
      formik.setFieldValue('rate_of_return', selectedId)
    }
  }

  // handle type change of custom ARQ field
  const handleTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    formik.setFieldValue('type_rate_of_return', value)
  }

  const addLegacyAssets = () => {
    // Creating a new object for arq_fix_n_flip_legacy

    let tempItem: CompoundingGrowth | undefined = assetsState.pop()
    var arq_fix_n_flip_legacy = {
      id: assetsState.length + 1, // Incrementing the ID
      label: 'ARQ Fix & Flip (Legacy)',
      dropdownLabel: `ARQ Fix & Flip (Legacy)`,
      customLabel: `$2%`,
      color: '#FF5733', // Different color for the second asset
      value: 2, // Different value for the second asset
      type: TYPES_OF_ROR.TYPE_ARQ_FLIP,
    }

    // Creating a new object for arq_rental_legacy
    var arq_rental_legacy = {
      id: assetsState.length + 2, // Incrementing the ID
      label: 'ARQ Rental (Legacy)', // Different label for the third asset
      dropdownLabel: `ARQ Rental (Legacy)`, // Different dropdown label for the third asset
      customLabel: `$3%`, // Different custom label for the third asset
      color: '#FFFF00', // Different color for the third asset
      value: 3, // Different value for the third asset
      type: TYPES_OF_ROR.TYPE_ARQ_RENTAL,
    }

    // Push the popped element (tempItem) back into the assetsState array
    assetsState.push(
      arq_fix_n_flip_legacy,
      arq_rental_legacy,
      tempItem
        ? {...tempItem, id: assetsState.length + 3}
        : {
            id: assetsState.length + 1, // Incrementing the ID
            label: 'Custom',
            dropdownLabel: `ARQ Fix & Flip (Legacy)`,
            customLabel: `$2%`,
            color: '#FF5733', // Different color for the second asset
            value: 2, // Different value for the second asset
            type: TYPES_OF_ROR.TYPE_ARQ_FLIP,
          }
    )
    // Now, assetsState contains tempItem at the end
    setAssetsState([...assetsState])
  }

  const handleCustomField = () => {
    // creating a new obj for assetsState state
    const newAsset = {
      //will map id accrording to this
      //  id: assetsState[assetsState.length-1].id+1,
      id: assetsState.length + 1,
      label: 'Custom name',
      dropdownLabel: `(1%) Custom name`,
      customLabel: `$1%`,
      color: '#9A258E',
      value: 1,
      type: TYPES_OF_ROR.TYPE_SIMPLE,
    }
    // adding the newly created custom investemnt to assetsState to show it in dropdown
    setAssetsState([...assetsState, newAsset])
  }

  // updating assetsState
  const updateAssetById = (customId: number, index: number) => {
    const customLabel = formik.getFieldProps(
      `custom_investment_values[${index}].investment_name`
    ).value
    const customValue = formik.getFieldProps(
      `custom_investment_values[${index}].investment_value`
    ).value
    const customRoR = formik.getFieldProps(
      `custom_investment_values[${index}].type_rate_of_return`
    ).value
    const newAsset = {
      id: customId,
      label: customLabel,
      // if customRoR === Simple RoR then set dropdownLabel to (customValue + customLabel)
      // else set dropdownLabel to customLabel only
      dropdownLabel:
        customRoR === TYPES_OF_ROR.TYPE_SIMPLE
          ? `(${customValue}%) ${customLabel}`
          : `${customLabel}`,
      customLabel: `${customValue}%`,
      color: '#9A258E',
      value: customValue,
      type: customRoR,
    }
    setAssetsState((prevAssets) => {
      //adding 9 because the indexs of the custom investments are between 0 and 2 , and the indices of these investment bars are 10,11,12 in assets.
      return prevAssets.map((asset, assetIndex) => {
        if (assetIndex === index + 9) {
          return {...asset, ...newAsset}
        }
        return asset
      })
    })
  }

  // adding custom investment to formik.setFieldValue('custom_investment_values') array -> which is a FieldArray
  const handleAddCustomInvestment = () => {
    let length = assetsState[assetsState.length - 1].id + 1
    const newCustomInvestment = {
      //adding 3 at 7 becuase we add two more assets named : fix and flip & rental legacy
      id: assetsState.length === 7 ? assetsState.length + 3 : length,
      type_rate_of_return: typesOfRoR[0].type,
      investment_name: 'Custom name',
      investment_value: 1,
    }
    const updatedCustomInvestments = [
      ...formik.values.custom_investment_values,
      newCustomInvestment,
    ]
    formik.setFieldValue('custom_investment_values', updatedCustomInvestments)
  }

  const handleRemoveCustomInvestment = (index: number, label: string, customId: number) => {
    // Remove the asset from assetsState based on the customId
    setAssetsState((prevAssets) => prevAssets.filter((asset) => asset.id !== customId))

    // Remove the corresponding value from custom_investment_values
    formik.setFieldValue(
      'custom_investment_values',
      formik.values.custom_investment_values.filter((_, i) => i !== index)
    )
  }

  // handle image change
  const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // extracting image from array
    const file = e.target.files?.[0]
    let fileName = await HandleFetchOrUploadImage(file)
    return fileName.slice(16)
  }

  // handle clipboard
  const handleCopy = () => {
    setCopied(true)
    setTimeout(() => setCopied(false), 2000) // Reset copied state after 2 seconds
  }

  // if url contains receiver_url as a param in it than call fetch API
  const handleFetchAdvisorData = async () => {
    // setting isReceiverDataLoading state to true
    setIsReceiverDataLoading(true)
    try {
      // checking if receiver_url state is true than call fetchAdvisorFormData API
      if (receiver_url) {
        const res = await fetchAdvisorFormData(receiver_url)

        // setting global setReceiverFormDataObj with API response
        setReceiverFormDataObj((prevFormData) => ({
          ...prevFormData,
          starting_amount: res.data.advisorFormData.starting_amount,
          contributions: res.data.advisorFormData.contributions,
          contributions_frequency: res.data.advisorFormData.contributions_frequency,
          years_to_grow: res.data.advisorFormData.years_to_grow,
          selected_rate_of_return_obj:
            assets.find(
              (asset) => asset.value === parseFloat(res.data.advisorFormData.rate_of_return)
            ) || assets[defaultAsset],
          seleted_bars: res.data.advisorFormData.selectedBars,
        }))
        // setting isReceiverDataLoading state to false
        setIsReceiverDataLoading(false)
      }
    } catch (error: any) {
      if (error?.response?.data) {
        notifyError(error?.response?.data?.message)
      } else {
        notifyError('Error occurred during the request.')
      }
      setIsReceiverDataLoading(false)
    }
  }

  return {
    worthResult,
    selectedRate,
    isFirstRender,
    handleSubmit,
    handleTypeChange,
    handleKeyPress,
    handleARQRentalFreqChange,
    handleCompoundingFreqChange,
    handleAssetChange,
    handleAddCustomInvestment,
    handleRemoveCustomInvestment,
    formik,
    handleCustomField,
    assetsState,
    setAssetsState,
    updateAssetById,
    HandleFetchOrUploadImage,
    handleFetchAdvisorData,
    handleImageChange,
    handleCopy,
    copied,
  }
}
