import {toPng} from 'html-to-image'
import {toast} from 'react-toastify'

export const roundToLowest = (value: number) => {
  return Math.floor(value)
}

export const toAbbreviatedNumber = (value: number): string => {
  if (value < 1e3) return value.toString()
  if (value >= 1e3 && value < 1e6) return (value / 1e3).toFixed(1) + 'K'
  if (value >= 1e6 && value < 1e9) return (value / 1e6).toFixed(1) + 'M'
  if (value >= 1e9 && value < 1e12) return (value / 1e9).toFixed(1) + 'B'
  if (value >= 1e12) return (value / 1e12).toFixed(1) + 'T'
  return '' // Fallback to an empty string if an unexpected case occurs
}

export const toUSD = (value: number) => {
  const formattedValue = Number(roundToLowest(value)).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return formattedValue
}

export const toUSDWithoutRoundOff = (value: number) => {
  const formattedValue = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })
  return formattedValue.format(value)
}

export const downloadChart = (chartId: string, imageName: string) => {
  // setting toastify success/error
  const notifyError = (msg: string) => toast.error(msg)

  const chartContainer = document.getElementById(chartId)

  if (chartContainer) {
    toPng(chartContainer, {cacheBust: false})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = imageName
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        notifyError('An error occurred.')
      })
  }
}

// ***TSBG chart helper functions***
// formatting future value
export const FVFormatter = (value: any) => {
  const suffixes = ['', 'K', 'M', 'B', 'T']

  // Find the appropriate suffix based on the length of the value
  const suffixIndex = Math.floor(String(value).length / 3)

  // Calculate the value with the appropriate suffix, rounded up to the nearest integer
  const formattedValue = Math.ceil(value / Math.pow(1000, suffixIndex)) + suffixes[suffixIndex]

  return formattedValue
}
