import {TSBG, sectionIds} from '../../../../constants/AppConstants'
import {useReceiverFormContext} from '../../../context/ReceiverContext'
import FeedbackSection from '../feedbackSection/FeedbackSection'
import TimeSeriesChart from './components/TimeSeriesChart'

const TimeSeriesSection = () => {
  const {receiverFormDataObj} = useReceiverFormContext()
  return (
    <div id={sectionIds[18]}>
      <h1 className='text-center my-2 mb-6'>{TSBG.title}</h1>
      <p className='text-center pt-0 pb-2'>
        For how{' '}
        <span className='pink-text fw-600'>
          {receiverFormDataObj.selected_rate_of_return_obj.dropdownLabel}
        </span>{' '}
        could grow:
      </p>
      <TimeSeriesChart />

      {/* feedback section */}
      <section className='mt-7'>
        <FeedbackSection sectionName='TSBG section' />
      </section>
    </div>
  )
}

export default TimeSeriesSection
